import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik'

import { fetchFromObject } from '../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 170,
    }
}));

const CapKGField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const nomeCampo = "capacidadekg";
    const label = "Capacidade (KG)";

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)
    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                type='number'
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default CapKGField;
