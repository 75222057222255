import React, { Component } from 'react';
import { Paper, Divider, IconButton, withStyles } from '@material-ui/core';
import { arrayMove } from 'react-sortable-hoc';
import AddIcon from '@material-ui/icons/Add'

import SortableList from '../../../../../components/UI/SortableList/SortableList';
import { listaUF, getArrayWithUniqueItems } from '../../../../../shared/utilities';
import MunicipioField from './Fields/MunicipioField';
import MunicipioUfSelectField from './Fields/MunicipioUfSelectField';
import CarregamentoPosterior from './Fields/CarregamentoPosteriorField';


const styles = theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    ufContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        height: 165,
        position: 'relative',
        overflow: 'auto',
        padding: 0,
    },
    panel: {
        minWidth: 405,
    }
})

class municipiosCarregamentoPanel extends Component {
    state = {
        selected: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
        municipio: { value: null, label: null }
    };

    onSortEnd = ({ oldIndex, newIndex }, items, formik) => {
        formik.setFieldValue(
            "municipiosCarregamento",
            arrayMove(items, oldIndex, newIndex));
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    onDeleteHandler = (index, items, formik) => {
        items.splice(index, 1);
        formik.setFieldValue(
            "municipiosCarregamento",
            items);
        formik.setFieldTouched("municipiosCarregamento", true, true);
        if (items.length === 0) {
            formik.setFieldValue(
                "ufInicioSelected", null);
        }
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    handleUfBlur = (formik, nomeCampo) => {
        this.setState({ municipio: { value: null, label: null } });
        formik.setFieldTouched(nomeCampo, true, true);
    }

    handleMunicipioChange = (event) => {
        this.setState({ municipio: event });
    }
    
    handleAdd = (items, formik) => {
        if (this.state.municipio.value) {
            formik.setFieldTouched("municipiosCarregamento", true, true);
            formik.setFieldValue(
                "municipiosCarregamento",
                getArrayWithUniqueItems([...items, this.state.municipio]));

            formik.setFieldValue(
                "ufInicioSelected", this.state.selected.label);
            //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
            formik.validateForm().then(() => formik.validateForm())
        }
    }

    render() {
        const { classes, formik, disabled, showExtraDocumentsPanels } = this.props;

        const items = formik.values.municipiosCarregamento;
    
        return (
            <Paper className={classes.panel} elevation={1}>
                <div className={classes.ufContainer}>
                    <div className={classes.addUfContainer}>
                        <MunicipioUfSelectField
                            disabled={disabled}
                            formik={formik} 
                            handleBlur={this.handleUfBlur}/>
                        <MunicipioField
                            disabled={disabled}
                            handleChange={this.handleMunicipioChange}
                            selected={this.state.municipio}
                            formik={formik}
                            ufId={formik.values.ufInicio.value}
                            required
                            label="Município de Carregamento"
                            propName="municipiosCarregamento"
                        />
                        {!formik.isSubmitting && (
                            <IconButton
                                disabled={formik.isSubmitting || disabled}
                                className={classes.buttonAdd}
                                aria-label="Adicionar"
                                onClick={() => this.handleAdd(items, formik)}>
                            <AddIcon />
                            </IconButton>
                        )}
                    </div>
                    <React.Fragment>
                        <Divider />
                        <CarregamentoPosterior formik={formik} disabled={formik.isSubmitting || disabled || showExtraDocumentsPanels }/>
                    </React.Fragment>
                </div>
                <Divider />
                <SortableList
                    disabled={formik.isSubmitting || disabled}
                    lockToContainerEdges
                    lockAxis='y'
                    listContainerClasses={classes.list}
                    items={items}
                    onSortEnd={(data) => this.onSortEnd(data, items, formik)}
                    onDeleteHandler={(index) => this.onDeleteHandler(index, items, formik)}
                    useDragHandle={true}
                />
            </Paper>

        );
    }
}

export default withStyles(styles)(municipiosCarregamentoPanel);