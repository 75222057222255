import React, { Component } from 'react';
import { Paper, Divider, IconButton, withStyles } from '@material-ui/core';
import { arrayMove } from 'react-sortable-hoc';
import AddIcon from '@material-ui/icons/Add'

import SortableList from '../../../../../components/UI/SortableList/SortableList';
import UfSelectField from './Fields/UfSelectField';
import { listaUF, getArrayWithUniqueItems } from '../../../../../shared/utilities';

const styles = theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    list: {
        height: 427,
        // position: 'relative',
        overflow: 'auto',
        padding: 0,
    },
    select: {
        marginBottom: 28
    },
    panel: {
        minWidth: 250,
        height: '100%'
    },
})

class ufPercursoPanel extends Component {
    state = {
        selected: { value: 28, label: listaUF.find(e => e.id === 28).sigla }
    };

    onSortEnd = ({ oldIndex, newIndex }, items, formik) => {
        formik.setFieldValue(
            "ufsPercurso",
            arrayMove(items, oldIndex, newIndex));
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    onDeleteHandler = (index, items, formik) => {
        items.splice(index, 1);
        formik.setFieldValue(
            "ufsPercurso",
            items);
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    handleChange = (event) => {
        this.setState({ selected: event })
    }

    handleAdd = (items, formik) => {
        formik.setFieldValue(
            "ufsPercurso",
            getArrayWithUniqueItems([...items, this.state.selected]));
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    }

    handleBlur = (formik) => {
        formik.setFieldTouched("ufsPercurso", true, true);
    };

    render() {
        const { classes, formik, disabled } = this.props;

        const items = formik.values.ufsPercurso;

        return (
            <Paper className={classes.panel} elevation={1}>
                <div className={classes.addUfContainer}>
                    <UfSelectField
                        disabled={formik.isSubmitting || disabled}
                        handleChange={this.handleChange}
                        selected={this.state.selected}
                        formik={formik}
                        label="UFs do Percurso" />
                    {!formik.isSubmitting &&
                        (<IconButton
                            disabled={formik.isSubmitting || disabled}
                            className={classes.buttonAdd}
                            aria-label="Adicionar"
                            onClick={() => this.handleAdd(items, formik)}>
                            <AddIcon />
                        </IconButton>)
                    }
                </div>

                <Divider />
                <SortableList
                    disabled={formik.isSubmitting || disabled}
                    lockToContainerEdges
                    lockAxis='y'
                    listContainerClasses={classes.list}
                    items={items}
                    onSortEnd={(data) => this.onSortEnd(data, items, formik)}
                    onDeleteHandler={(index) => this.onDeleteHandler(index, items, formik)}
                    useDragHandle={true}
                />
            </Paper>

        );
    }
}

export default withStyles(styles)(ufPercursoPanel);