import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import { Add, Check, Close } from '@material-ui/icons';
import { Typography, Tooltip, Fab} from '@material-ui/core';
import { Formik, Form } from 'formik';

import InformacaoBancoField from '../../CreateUpdate/Form/Panels/PagamentoFrete/Fields/InformacaoBancoField';
import InformacaoPagamentoField from '../../CreateUpdate/Form/Panels/PagamentoFrete/Fields/InformacaoPagamentoField';
import InformacaoResponsavelField from '../../CreateUpdate/Form/Panels/PagamentoFrete/Fields/InformacoesResponsavelField';
import ComponenteField from '../../CreateUpdate/Form/Panels/PagamentoFrete/Fields/ComponenteField';
import InformacaoPrazoField from '../../CreateUpdate/Form/Panels/PagamentoFrete/Fields/InformacaoPrazoField';
import { tiposDocumentoContratantes, tipoPagamento } from '../../../../shared/mdfe';
import PagamentoSchemaDados from '../../CreateUpdate/Form/Panels/PagamentoFrete/Schema/PagamentoSchemaDados';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'    
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    margin: '0px'
  },
  panelExpansion: {
    padding: '0px',
    margin: '0px'
  },
  buttonConfirm: {
    margin: theme.spacing(1),
    width: '40px',
    height: '40px'
  }
}));

const PagamentoInfEventoForm = (props) =>  {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { handleSubmit, disabled, arrayHelpers } = props;

  const initialValues = {
        tpdocumento: tiposDocumentoContratantes[0],
        xnome: '',
        cpfCnpjId: '',
        vcontrato: '',
        indpag: tipoPagamento[0],
        codbanco: '',
        codagencia: '',
        comp: [],
        infPrazo:[]
   };
   
   const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
   };

    const handleExpand = (formik) => {
        formik.setFieldTouched('infPags', true, true);
        setExpanded(false);
    };

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values, formikBag) => { 
            formikBag.resetForm();
            handleExpand(formikBag);
            return handleSubmit(values, arrayHelpers );
        }}
        validationSchema={ PagamentoSchemaDados }>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const infParcelas = formikProps.values.indpag.value === 1;
            return <ExpansionPanel
                    className={classes.panelExpansion}
                    expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                    >
                <ExpansionPanelSummary
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    expandIcon={ <Add />}
                >
                    <Typography  variant="subtitle" color="inherit">Informações do Frete</Typography>
                </ExpansionPanelSummary>
                <Form className={classes.form}>
                    <ExpansionPanelDetails className={classes.content}>
                        <InformacaoResponsavelField formik={formikProps} disabled={disabled}/>
                        <div className={classes.group}>
                            <InformacaoPagamentoField formik={formikProps} disabled={disabled}/>
                            <InformacaoBancoField formik={formikProps} disabled={disabled}/>
                        </div>
                    
                        <div className={classes.group}>
                            <ComponenteField formik={formikProps} disabled={disabled} />
                            {
                            infParcelas ? 
                                <div className={classes.group}>
                                    <InformacaoPrazoField formik={formikProps} disabled={disabled} />
                                </div>
                            : null
                            }
                        </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions>
                        <Tooltip title="Cancelar" aria-label="Cancelar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                color="primary"
                                onClick={ () => handleExpand(formikProps) }
                                type="reset"
                                aria-label="Cancelar">
                                <Close />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Adicionar" aria-label="Adicionar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleSubmit}
                                color="primary"
                                aria-label="Adicionar">
                                <Check />
                            </Fab>
                        </Tooltip>
                    </ExpansionPanelActions>
                </Form>
            </ExpansionPanel>
            }}
    </Formik>  
  );
};

export default  PagamentoInfEventoForm;