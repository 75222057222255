import React from 'react';
import ActionIconPlaceholder from '../../components/UI/ActionIconPlaceholder/ActionIconPlaceholder';
import { Tooltip, CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExportIcon from '../../content/icons/ExportIcon';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

export const BotaoConsultar = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Consultar" aria-label="Consultar">
        <IconButton className={classes.actionButton} onClick={onClick}>
            <SearchIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoEmitir = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer} >
    <ActionIconPlaceholder />
    <Tooltip title="Emitir" aria-label="Emitir">
        <IconButton className={classes.actionButton} onClick={onClick}>
            <ArrowUpIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoCancelar = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Cancelar" aria-label="Cancelar">
        <IconButton className={classes.actionButton} onClick={onClick}>
            <CancelIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoEncerrar = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer} onClick={onClick}>
    <ActionIconPlaceholder />
    <Tooltip title="Encerrar" aria-label="Encerrar">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}>
            <CheckBoxIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoImprimir = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Imprimir" aria-label="Imprimir">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}>
            <PrintIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoEditar = ({ classes, onClick }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Editar" aria-label="Editar">
        <IconButton
            className={classes.actionButton}
            onClick={onClick}
            children={<EditIcon fontSize="small" />}
        />
    </Tooltip>
</div>)

export const BotaoExcluir = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Exluir" aria-label="Exluir">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}
            children={<DeleteIcon fontSize="small" />}
        />
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoIncluirCondutor = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Incluir Condutor" aria-label="Incluir Condutor">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}
            children={<PersonAddIcon fontSize="small" />}
        />
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoCompartilhar = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Compartilhar" aria-label="Compartilhar">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}
            children={<ExportIcon fontSize="small" />}
        />
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoIncluirDfe = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Incluir DF-e" aria-label="Incluir DF-e">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}
            children={<PostAddIcon fontSize="small" />}
        />
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)

export const BotaoIncluirPagamento = ({ classes, onClick, loading }) => (<div className={classes.actionButtonContainer}>
    <ActionIconPlaceholder />
    <Tooltip title="Incluir Pagamento do Frete" aria-label="Incluir Pagamento">
        <IconButton
            onClick={onClick}
            className={classes.actionButton}
            children={<MonetizationOnIcon fontSize="small" />}
        />
    </Tooltip>
    {loading && <CircularProgress size={37} className={classes.actionButtonProgress} />}
</div>)