import React from "react";
import { Formik, Form } from "formik"
import PropTypes from 'prop-types';
import { IconButton, Typography, Paper, Fab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../../../yup';
import * as cores from '../../../../shared/cores'
import UFField from "./Fields/UFField";
import TipoCarroceriaField from "./Fields/TipoCarroceriaField";
import CapM3Field from "./Fields/CapM3Field";
import CapKGField from "./Fields/CapKGField";
import TaraField from "./Fields/TaraField";
import RenavamField from "./Fields/RenavamField";
import PlacaField from "./Fields/PlacaField";
import ProprietarioField from "./Fields/ProprietarioField";
import TipoProprietarioField from "./Fields/DadosProprietario/TipoProprietarioField";
import RntrcField from "./Fields/DadosProprietario/RntrcField";
import UfProprietarioField from "./Fields/DadosProprietario/UfProprietarioField";
import TipoDocumentoField from "./Fields/DadosProprietario/TipoDocumentoField";
import DocumentoField from "./Fields/DadosProprietario/DocumentoField";
import NomeField from "./Fields/DadosProprietario/NomeField";
import IEField from "./Fields/DadosProprietario/IEField";
import AtivoCheck from "./Fields/AtivoField";

const useStyles = makeStyles(theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        margin: 8,
    },
    buttonConfirmContainer: {
        display: 'flex',
        margin: '23px 28px',
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    buttonClose: {
        margin: 4,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const ReboquesSchema = Yup.object().shape({
    placa: Yup.string()
        .min(7)
        .max(7)
        .required(),
    renavam: Yup.string()
        .min(9)
        .max(11)
        .notRequired(),
    tara: Yup.number()
        .min(1)
        .max(999999)
        .integer()
        .required(),
    capacidadekg: Yup.number()
        .min(1)
        .max(999999)
        .integer()
        .required(),
    capacidadecubica: Yup.number()
        .min(1)
        .max(999)
        .integer()
        .notRequired(),
    tipoCarroceria: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required(),
    uf: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required(),
    naoProprietario: Yup.bool(),
    tipoProprietario: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required(),
    rntrc: Yup
        .string()
        .min(8)
        .max(8)
        .when('naoProprietario', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
    ufProprietario: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).when('naoProprietario', {
        is: true,
        then: Yup.object().required(),
        otherwise: Yup.object().notRequired(),
    }),
    tipoDocumento: Yup.object().shape({
        value: Yup.bool(),
        label: Yup.string()
    }).required(),
    documento: Yup.string()
        .when('naoProprietario', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        })
        .CpfCnpj(),
    nome: Yup.string()
        .min(2)
        .max(60)
        .when('naoProprietario', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
    ie: Yup.string()
        .max(14)
        .when('naoProprietario', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
});

const ReboquesForm = props => {
    const classes = useStyles();

    const { initialValues, onSubmit, handleClose } = props;

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={ReboquesSchema}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return onSubmit(values, addHandlers);
            }}>
            {formikProps => {
                const form = (
                    <React.Fragment>
                        <div className={classes.title}>
                            <Typography color="inherit" variant="h6">
                                Cadastro de Reboque
                                </Typography>
                            <IconButton
                                className={classes.buttonClose}
                                aria-label="Cancelar"
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Form className={classes.form}>
                            <div className={classes.fieldsContainer}>
                                <PlacaField formik={formikProps} />
                                <RenavamField formik={formikProps} />
                                <TaraField formik={formikProps} />
                                <CapKGField formik={formikProps} />
                                <CapM3Field formik={formikProps} />
                                <TipoCarroceriaField formik={formikProps} />
                                <UFField formik={formikProps} />
                                <AtivoCheck formik={formikProps} />
                            </div>
                            <Paper elevation={1}>
                                <ProprietarioField formik={formikProps} />
                                <div className={classes.fieldsContainer}>
                                    {/* Caso o proprietário não seja o emitente: */}
                                    <TipoProprietarioField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <RntrcField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <UfProprietarioField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <TipoDocumentoField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <DocumentoField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <NomeField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                    <IEField formik={formikProps} disabled={!formikProps.values.naoProprietario} />
                                </div>
                            </Paper>
                            <div className={classes.buttonConfirmContainer}>
                                <Fab
                                    type="submit"
                                    color="primary"
                                    aria-label="Adicionar">
                                    <CheckIcon />
                                </Fab>
                            </div>
                        </Form>
                    </React.Fragment>
                );
                return props.children({
                    form,
                    isDirty: formikProps.dirty,
                    isSubmitting: formikProps.isSubmitting,
                    setFieldValue: formikProps.setFieldValue,
                    setFieldError: formikProps.setFieldError,
                    setFieldTouched: formikProps.setFieldTouched,
                    handleSubmit: formikProps.handleSubmit,
                    handleCancel: formikProps.resetForm,
                });

            }
            }
        </Formik>
    );
}

ReboquesForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default ReboquesForm;