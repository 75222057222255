import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Fab, Tooltip, Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormHelperText, CircularProgress  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, FieldArray } from 'formik';
import * as actions from '../../../store/actions';
import Yup from '../../../yup';
import apolloClientProvider from '../../../apolloClientProvider';
import DeleteIcon from '@material-ui/icons/Delete';

import InfViagemField from '../CreateUpdate/Form/Panels/PagamentoFrete/Fields/InfViagemField';
import { converteMoedaParaFloat, removeDots, somenteNumeros } from '../../../shared/utilities';
import PagamentoInfEventoForm from './PagamentoInfEventoDialog/PagamentoInfEventoForm';


const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    dialogTitle: {
        width: '100%',
        paddingBottom: '0px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1)
    },
    dialogGroup: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',    
    },
    form: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    table: {
        width: '100%',
        padding: '0px'
    },
    tableContentRow: {
        verticalAlign: 'center',
        padding: '0px'
    },
    removerCell: {
        verticalAlign: 'middle',
        padding: '0px'
    }, 
    hover: {
        cursor: 'pointer',

    },
    mensagem: {
        color: 'red',
        textAlign: 'center',
        marginTop: '0px',
        width: '100%'
    },
    actionButtonProgress: {
        position: 'absolute',
        top: 5,
        left: 5,
        zIndex: 1,
    },
    actionButtonContainer: {
        display: 'inline-block',
        position: 'relative',
    }
}));


const handleSubmit = (values, id, close) => {

    const infPags = values.infPags && values.infPags.map((pag) => {
        const comp = pag.comp.length !== 0 ? pag.comp.map(value => { 
            return {
                vcomp: converteMoedaParaFloat(removeDots(value.vcomp)),
                tpcomp: value.tpcomp.value,
                xcomp: value.xcomp !== "" ? value.xcomp : null
            }
        }) : [];

        const infPrazo = pag.infPrazo.length !== 0 ? pag.infPrazo.map((value, index) =>{
                    return {
                        nparcela: index+1,
                        dvenc: value.dvenc,
                        vparcela: converteMoedaParaFloat(removeDots(value.vparcela))
        }}): []; 

        return {
            tpdocumento: pag.tpdocumento.value,
            cpfCnpjId: somenteNumeros(pag.cpfCnpjId),
            indpag: pag.indpag.value,
            infPrazo: infPrazo,
            comp: comp,
            vcontrato: converteMoedaParaFloat(removeDots(pag.vcontrato)),
            xnome: pag.xnome !== "" ? pag.xnome : null,
            codagencia: pag.codagencia,
            codbanco: pag.codbanco
        };
    });

    const nroViagem = values.nroViagem;
    const qtdViagens = values.qtdViagens;

    return apolloClientProvider.client.mutate(
        {
            mutation: actions.INCLUIR_PAGAMENTO,
            variables: { id, nroViagem, qtdViagens, infPags },
            refetchQueries: [{ query: actions.GET_MDFES }],
        })
        .then(({ data }) => {
            close();
            actions.OPEN_SNACKBAR('Pagamento incluído!', false, true);
        })
        .catch((error) =>{
            close();
        });
}

const PagamentoInfEventoDialog = (props) => {
    const classes = useStyles();

    const { mdfe, handleClose, open, disabled } = props

    if (!mdfe) return null;
    
    const initialValues = {
        qtdViagens: '',
        nroViagem: '',
        infPags: []
    };

    const handleAddItems = (values, arrayHelpers) => {
        arrayHelpers.push(values);
    }

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values, formikBag) => {
            handleSubmit(values, mdfe.id, handleClose)
                .then(
                    result => {
                        formikBag.setSubmitting(false);
                        return result;
                    },
                    error => {
                        formikBag.setSubmitting(false);
                        formikBag.setErrors(error.validationErrors);
                        throw error;
                    }
                )
        }}
        validationSchema={ Yup.object().shape({
            qtdViagens: Yup.string()
                        .max(5)
                        .required(),
            nroViagem: Yup.string()
                        .max(5)
                        .required(),
            infPags: Yup.array()
                        .min(1)
                        .required()
        })}>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const table = formikProps.values.infPags;

             return (<Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth = {'md'}>
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}>Adicionar Pagamento do Frete</DialogTitle>
                <Form className={classes.form}>
                    <DialogContent>
                        <div className={classes.dialogGroup}>
                            <InfViagemField 
                                formik={formikProps}
                                disabled={formikProps.isSubmitting || disabled}
                                nomeCampo={`qtdViagens`}
                                label={`Quantidade de Viagens`}
                            />
                            <InfViagemField 
                                formik={formikProps}
                                disabled={formikProps.isSubmitting || disabled}
                                nomeCampo={`nroViagem`}
                                label={`Número da Viagem`}
                            />
                        </div> 
                        <FieldArray
                            name='infPags'
                            render={
                                arrayHelpers => (
                                    <React.Fragment>
                                        <Table className={classes.table} aria-labelledby="tableTitle">
                                            <TableHead>
                                                <TableRow> 
                                                    <TableCell>
                                                        Tipo do Pagamento
                                                                    </TableCell>
                                                    <TableCell>
                                                        Valor
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {table
                                                    .map((n, index) => (
                                                        <TableRow
                                                            hover
                                                            className={classes.tableContentRow}
                                                            tabIndex={-1}
                                                            key={index}>
                                                            <TableCell
                                                                className={classes.hover}
                                                                component="th"
                                                                scope="row">
                                                                { n.indpag.label }
                                                            </TableCell>
                                                            <TableCell
                                                                className={classes.hover}
                                                                component="th"
                                                                scope="row">
                                                                { n.vcontrato }
                                                            </TableCell>
                                                            <TableCell
                                                                className={classes.removerCell}
                                                                component="th"
                                                                scope="row">
                                                                <IconButton
                                                                    disabled={formikProps.isSubmitting || disabled}
                                                                    aria-label="Delete" onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                    )}
                                            </TableBody>
                                        </Table>
                                        <PagamentoInfEventoForm 
                                            disabled={disabled} 
                                            handleSubmit={handleAddItems} 
                                            arrayHelpers={arrayHelpers} 
                                            />
                                        {
                                            formikProps.errors.infPags && formikProps.touched.infPags   ? <FormHelperText
                                                                    className={classes.mensagem}
                                                                    error={Boolean(formikProps.errors.infPags)}>
                                                                        {formikProps.errors.infPags && (
                                                                            formikProps.errors.infPags
                                                                        )}
                                                                </FormHelperText> 
                                            : null
                                        }
                                    </React.Fragment>
                                )
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleClose}
                                color="primary"
                                aria-label="Fechar">
                                <CloseIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Confirmar" aria-label="Confirmar" placement="top">
                        <div className={classes.actionButtonContainer}>
                            <Fab
                                disabled={formikProps.isSubmitting}
                                className={classes.buttonConfirm}
                                onClick={handleSubmit}
                                color="primary"
                                aria-label="Confirmar">
                                <CheckIcon />
                            </Fab>
                            {formikProps.isSubmitting && <CircularProgress size={62} className={classes.actionButtonProgress} />}
                        </div>
                        </Tooltip>
                    </DialogActions>
                </Form>
            </Dialog>)
        }}
    </Formik>
    );

}

export default PagamentoInfEventoDialog;