import gql from 'graphql-tag';

export const CONSULTA_NAO_ENCERRADOS = gql`
{
    consultarNaoEncerrados {
        cstat
        cuf
        infmdfe {
            chave
            nprot
        }
        tpamb
        xmotivo
    }
}
`;