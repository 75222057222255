import React from 'react';
import { Paper, Divider, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'

import { tiposDocumentoEmpresa } from '../../../../../../shared/mdfe';
import { FieldArray } from 'formik';
import CiotsAddForm from './CiotsAddForm';
import * as cores from '../../../../../../shared/cores';

const useStyles = makeStyles(theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    list: {
        height: 200,
        position: 'relative',
        overflow: 'auto',
    },
    listDisabled: {
        height: 200,
        position: 'relative',
        overflow: 'auto',
        color: theme.palette.text.disabled
    },
    panel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    listItem: {
        color: 'inherit',
    },
    listItemDisabled: {
        color: theme.palette.text.disabled,
    },
    title: {
        color: cores.titulo,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
}))

const CiotsPanel = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const items = formik.values.ciots;

    let resetForm = null;
    const formInitialValues = {
        tipoDocumento: tiposDocumentoEmpresa[1],
        documento: '',
        ciot: ''
    }

    const handleFormSubmit = (values, arrayHelpers, formik) => {
        arrayHelpers.push(values);
        resetForm({
            values: {
                tipoDocumento: values.tipoDocumento,
                documento: formInitialValues.documento,
                ciot: formInitialValues.ciot
            }
        })
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    }

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
    };

    return (
        <Paper className={classes.panel} elevation={1}>
            <FieldArray
                name='ciots'
                render={
                    arrayHelpers => (
                        <React.Fragment>
                            <div className={classes.title}>
                                <Typography variant="h6" color="inherit">Ciots</Typography>
                            </div>
                            <div className={classes.addContainer}>
                                <CiotsAddForm
                                    initialValues={formInitialValues}
                                    handleSubmit={(values) => handleFormSubmit(values, arrayHelpers, formik)}
                                    disabled={formik.isSubmitting || disabled}>
                                    {({ form, handleCancel }) => (
                                        <React.Fragment>
                                            {resetForm = handleCancel}
                                            {form}
                                        </React.Fragment>
                                    )}
                                </CiotsAddForm>
                            </div>

                            <Divider />
                            <List disablePadding className={!disabled ? classes.list : classes.listDisabled}>
                                {items && items.map((item, index) => (
                                    <ListItem ContainerProps={{ className: !(formik.isSubmitting || disabled) ? classes.listItem : classes.listItemDisabled }} key={item.value + item.label}>
                                        <ListItemText primary={item.documento} />
                                        <ListItemText primary={item.ciot} />
                                        {!formik.isSubmitting && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    disabled={formik.isSubmitting || disabled}
                                                    aria-label="Delete"
                                                    onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                )
                                )}
                            </List>
                        </React.Fragment>
                    )
                }
            />
        </Paper>

    );
}

export default CiotsPanel;