import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import { listaModais } from '../../../../../shared/mdfe';
import SelectWrapped from '../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import { fetchFromObject } from '../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 120
    },
}))

/**
 * Função para setar o valor do modal no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
};

const ModalField = (props) => {
    const classes = useStyles();
    
    const { formik, disabled } = props;

    const nomeCampo = "modal"
    const label = "Modalidade"

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    const opcoes = listaModais;

    return (
        <FormControl required margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                value={fetchFromObject(formik.values, nomeCampo)}
                handleChange={(event) => handleChange(event, formik, nomeCampo)}
                handleBlur={() => handleBlur(formik, nomeCampo)}
                required
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    )
};

export default ModalField;


