import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
}));

const UsuarioConfirmacaoSenhaField = (props) => {
    const classes = useStyles();

    const { formik } = props;

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                type="password"
                required
                id="confirmacaoSenha"
                label="Confirme a senha"
                autoComplete="confirmacao-senha"
                value={formik.values.confirmacaoSenha}
                error={Boolean(
                    formik.touched.confirmacaoSenha && formik.errors.confirmacaoSenha
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={formik.errors.confirmacaoSenha &&
                    formik.touched.confirmacaoSenha && (
                        formik.errors.confirmacaoSenha
                    )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default UsuarioConfirmacaoSenhaField;
