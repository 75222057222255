import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form } from 'formik';

import DocumentoSchema from './Schema/DocumentoSchema';
import ChaveDocumentoField from './Fields/ChaveDocumentoField';
import MunicipioDocumentoField from './Fields/MunicipioDocumentoField';
import TipoDocumentoField from './Fields/TipoDocumentoField'; 
import CodigoBarrasField from './Fields/CodigoBarrasDocumento';


const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '5px',
        justifyContent: 'space-between',
        width: 500 
    }
}))

const DocumentosDialog = props => {
    const classes = useStyles();

    const { handleSubmit, handleClose, open, disabled, initialValues, menu } = props

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={DocumentoSchema}>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const showCodBarra = formikProps.values.tipo.value > 2 ? true : false;

            const form = <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">Adicionar/Alterar Documento</DialogTitle>
                <Form className={classes.form}>
                    <DialogContent className={classes.dialogContent}>
                            <TipoDocumentoField
                                disabled={disabled}
                                formik={formikProps}
                                nomeCampo={`tipo`}/>
                            <MunicipioDocumentoField
                                disabled={disabled}
                                formik={formikProps}
                                nomeCampo={`municipio`}
                                opcoes={menu} />
                            <ChaveDocumentoField
                                disabled={disabled}
                                formik={formikProps}
                                nomeCampo={`chave`} />

                            {
                                showCodBarra ? 
                                    <CodigoBarrasField
                                    disabled={disabled}
                                    formik={formikProps}
                                    nomeCampo={`segcodbarra`} />
                                : null
                            }
                            
                        
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleClose}
                                color="primary"
                                aria-label="Fechar">
                                <CloseIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Confirmar" aria-label="Confirmar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleSubmit}
                                color="primary"
                                aria-label="Confirmar">
                                <CheckIcon />
                            </Fab>
                        </Tooltip>
                    </DialogActions>
                </Form>
            </Dialog>
            return props.children({
                form,
                isDirty: formikProps.dirty,
                isSubmitting: formikProps.isSubmitting,
                setFieldValue: formikProps.setFieldValue,
                setFieldError: formikProps.setFieldError,
                setFieldTouched: formikProps.setFieldTouched,
                handleSubmit: formikProps.handleSubmit,
                handleCancel: formikProps.resetForm,
            });
        }}
    </Formik>

    )
}

export default DocumentosDialog