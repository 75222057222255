import gql from 'graphql-tag';

export const GET_CONDUTORES = gql`
query motoristas($busca: String, $offset: Int, $limit: Int, $inativo: Boolean){
    motoristas (busca: $busca, offset: $offset, limit: $limit, inativo: $inativo) {
        node {
            ... on MotoristaType {
                id
                nome
                cpfcnpj
                inativo
                podeexcluir
            }
        }
        totalcount
  }
}
`;

export const GET_CONDUTOR = gql`
query condutor($id: Int){
    motorista(motorista: { id: $id }){
        id
        nome
        cpfcnpj
        inativo
    }
}
`;

export const GET_CONDUTORES_DISPONIVEIS = gql`
query motoristasDisponiveis($id: Int!){
    motoristasDisponiveis( id: $id ){
        id
        nome
        cpfcnpj
    }
}
`;

export const SET_CONDUTOR = gql`
mutation adicionarOuAtualizarMotorista($motorista: MotoristaInput!){
    adicionarOuAtualizarMotorista(motorista: $motorista) {
        id
        nome
        cpfcnpj
    }
  }
`;

export const EXCLUIR_MOTORISTA = gql`
mutation excluirMotorista($id: Int!){
    excluirMotorista(id: $id)
}
`;