import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form } from 'formik';

import Yup from '../../yup';
import ArquivoField from './Fields/ArquivoField'
import SenhaField from './Fields/SenhaField'
import ConfirmacaoSenhaField from './Fields/ConfirmacaoSenhaField'
import { SUPPORTED_CERTIFICATE_FORMATS } from '../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: 8
    },
    dialog: {
        minWidth: 600,
        maxWidth: 800,
    }
}));

const EmitenteArquivoDialog = props => {
    const classes = useStyles();

    const { handleSubmit, handleClose, open } = props
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title"
                className={classes.dialog}>Alterar Certificado Digital</DialogTitle>
            <Formik
                initialValues={{
                    senha: '',
                    confirmacaoSenha: '',
                }}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={Yup.object().shape({
                    arquivo: Yup
                        .mixed()
                        .test(
                            "formatoArquivo",
                            "Formato não suportado",
                            value => value ? SUPPORTED_CERTIFICATE_FORMATS.includes(value.type) : true
                        ),
                    senha: Yup.string()
                        .when('arquivo', {
                            is: value => value,
                            then: Yup.string().required(),
                            otherwise: Yup.string().notRequired(),
                        }),
                    confirmacaoSenha: Yup.string()
                        .when('senha', {
                            is: value => value && (value.length !== 0),
                            then: Yup.string().required().oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
                            otherwise: Yup.string().notRequired().oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
                        }),
                })}>
                {formikProps => {
                    const {
                        handleSubmit,
                    } = formikProps;

                    return (
                        <Form className={classes.form}>
                            <DialogContent>
                                <DialogContentText>
                                    Você deseja alterar o Certificado Digital da empresa?
                                    <br /><br />
                                    Informe o novo Certificado:
                                </DialogContentText>
                                <ArquivoField formik={formikProps} />
                                <SenhaField formik={formikProps} />
                                <ConfirmacaoSenhaField formik={formikProps} />
                            </DialogContent>
                            <DialogActions>
                                <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                                    <Fab
                                        className={classes.buttonConfirm}
                                        onClick={handleClose}
                                        color="primary"
                                        aria-label="Fechar">
                                        <CloseIcon />
                                    </Fab>
                                </Tooltip>
                                <Tooltip title="Confirmar" aria-label="Confirmar" placement="top">
                                    <Fab
                                        className={classes.buttonConfirm}
                                        onClick={handleSubmit}
                                        color="primary"
                                        aria-label="Confirmar">
                                        <CheckIcon />
                                    </Fab>
                                </Tooltip>
                            </DialogActions>
                        </Form>)
                }}
            </Formik>
        </Dialog>
    )
}

export default EmitenteArquivoDialog