import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import * as actions from '../../../store/actions';
import axios from '../../../axios-api';
import AlterarSenhaForm from './AlterarSenhaForm';
import FullWidthSpinner from '../../UI/Spinner/FullWidthSpinner';

const useStyles = makeStyles((theme) => ({
    alterarSenhaComponent: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: '100vh',
    },
}));

const initialValues = {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
}

const AlterarSenha = () => {
    const [error, setError] = useState(null)
    const classes = useStyles()
    const history = useHistory()
    const { loading, graphQLError, data } = useQuery(actions.GET_AUTH);

    const handleSubmit = (values, addHandlers) => {
        const { auth } = data;

        const newData = {
            uid: auth.id,
            OldPassword: values.OldPassword,
            NewPassword: values.NewPassword,
            ConfirmPassword: values.ConfirmPassword,
        };
        addHandlers(
            axios.post('/auth/alterarsenha/', newData)
                .then(() => {
                    actions.OPEN_SNACKBAR('Senha alterada com sucesso!')
                    history.push('/');
                })
                .catch(error => {
                    setError(error.response.data.error_description)
                })
        );
    }

    const handleClose = () => {
        history.goBack();
    }

    if (loading) return <FullWidthSpinner />

    let showError = null;

    if (error || graphQLError)
        showError =
            <div className={classes.errorBox}>
                <Typography variant="subtitle1" color="secondary">
                    {error}
                </Typography>
            </div>

    return (
        <div className={classes.alterarSenhaComponent}>
            <AlterarSenhaForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                handleClose={handleClose}>
                {({ form, isDirty, isSubmitting }) => (
                    <React.Fragment>
                        {form}
                    </React.Fragment>
                )}
            </AlterarSenhaForm>
            {showError}
        </div >
    )
};

export default AlterarSenha;