import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import apolloClientProvider from '../../apolloClientProvider';
import * as actions from '../../store/actions'
import EmitenteForm from './EmitenteForm';
import { listaUF } from '../../shared/utilities';
import { versoesServico, tiposAmbiente, tiposTransportador, tiposEmitente } from '../../shared/mdfe';

const styles = {

}

const initialValues = {
    id: undefined,
    seriemdfe: 1,
    numeromdfe: 1,
    serial: '',
    estado: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    timeout: 20,
    tipoAmbiente: { value: undefined, label: undefined, enumString: undefined },//{ value: undefined, label: '', enumString: undefined },
    tipoTransportador: tiposTransportador[0],
    tipoEmitente: tiposEmitente[1],
    versaoServico: versoesServico[1],
    senha: undefined,
    confirmacaoSenha: '',
    rntrc: '',
}

class PureConfiguracaoEmitenteCreateUpdate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        apolloClientProvider.client.query({ query: actions.GET_CONFIGURACAO, fetchPolicy: "network-only" })
            .then(({ data: { configuracao } }) => {
                if (configuracao) {
                    const { configuracaomdfe, configuracaocertificado } = configuracao;

                    const estado = listaUF.find(e => e.sigla === configuracaomdfe.estado);
                    const estadoForm = {
                        value: estado.id,
                        label: estado.sigla
                    }

                    const resetData = {
                        id: configuracao.id,

                        configuracaocertificadoid: configuracaocertificado ? configuracaocertificado.id : initialValues.configuracaocertificadoid,
                        serial: configuracaocertificado ? configuracaocertificado.serial : initialValues.serial,
                        validade: configuracaocertificado ? configuracaocertificado.validade : initialValues.validade,
                        senha: initialValues.senha,
                        confirmacaoSenha: initialValues.confirmacaoSenha,

                        seriemdfe: configuracaomdfe.seriemdfe,
                        numeromdfe: configuracaomdfe.numeromdfe,
                        estado: estadoForm,
                        timeout: configuracaomdfe.timeout ? configuracaomdfe.timeout / 1000 : initialValues.timeout,
                        tipoAmbiente: tiposAmbiente.find(t => t.enumString === configuracaomdfe.tipoambiente),
                        tipoTransportador: configuracaomdfe.mdfetptransp ? tiposTransportador.find(t => t.enumString === configuracaomdfe.mdfetptransp) : initialValues.tipoTransportador,
                        tipoEmitente: tiposEmitente.find(t => t.enumString === configuracaomdfe.tipoemitente),
                        versaoServico: versoesServico.find(v => v.enumString === configuracaomdfe.versaoservico),
                        configuracaomdfeid: configuracaomdfe.id,
                        rntrc: configuracaomdfe.rntrc ? configuracaomdfe.rntrc : initialValues.rntrc,
                    }

                    this.resetForm({values: resetData});
                }
            });
    }

    handleSubmit = (values, addHandlers) => {

        const configuracaocertificado = values.arquivobase64
            ? {
                id: values.configuracaocertificadoid,
                configuracaoid: values.id,
                senha: values.senha,
                arraybytesarquivo: values.arquivobase64,

            }
            : undefined;

        const submitData = {
            id: values.id,
            configuracaocertificado,
            configuracaomdfe: {
                id: values.configuracaomdfeid,
                configuracaoid: values.id,
                numeromdfe: values.numeromdfe,
                seriemdfe: values.seriemdfe,
                rntrc: values.rntrc,
                versaoservico: values.versaoServico.enumString,
                tipoambiente: values.tipoAmbiente.enumString,
                mdfetptransp: values.tipoTransportador.enumString ? values.tipoTransportador.enumString : null,
                tipoemitente: values.tipoEmitente.enumString,
                estado: values.estado.label,
                timeout: values.timeout * 1000,
            },
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_CONFIGURACAO,
                    variables: { config: submitData },
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Configuração alterada!');
                    this.props.history.push('/mdfe');
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container} data-teste="component-emitente">
                <EmitenteForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </EmitenteForm>
                {this.state.errors}
            </div >
        );
    };
}

export default withStyles(styles)(withRouter(PureConfiguracaoEmitenteCreateUpdate));