import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel, Tooltip } from '@material-ui/core';

const CommonTableHead = props => {
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };

  const {
    order, orderBy, columnData,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {columnData.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? "right" : "left"}
            padding={column.padding}
            sortDirection={orderBy === column.id ? order : false}
          >
            <Tooltip
              title="Ordenar"
              placement={column.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CommonTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnData: PropTypes.array.isRequired
};

export default CommonTableHead;