import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';
import {getIn} from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        marginTop: '2px'
    },
    docs:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 400,
        marginLeft: '8px'
    }
}));

const emptyString = '';

const mask = createNumberMask({
    prefix: emptyString,
    includeThousandsSeparator: false,
    integerLimit: 8,
    allowLeadingZeroes: true
});

function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={true}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const LocalField = (props) => {
    const classes = useStyles();
    const { formik, disabled, campo, title } = props;

    let error = getIn(formik.errors, campo);
    const touched = getIn(formik.touched, campo);

    error = error && (error.value ? error.value : error)
    
    return (
        <React.Fragment>
            <div className={classes.docs}>
            <Typography variant="subtitle2" className={classes.title}>{title}</Typography>
                <TextField
                    className={classes.textField}
                    InputProps={{ inputComponent: MaskNumbersOnly }}
                    required
                    id={campo}
                    label={'CEP'}
                    value={fetchFromObject(formik.values, campo)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                        touched && error
                    )}
                    disabled={formik.isSubmitting || disabled}
                    margin="normal"
                    helperText={touched && error && (
                        error
                    )}
                    FormHelperTextProps={{ error: true }}
                />
            </div>
        </React.Fragment>
    )
}

export default LocalField;
