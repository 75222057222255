import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities'

const useStyles = makeStyles(theme => ({
    textField: {
        width: 450,
    }
}));

const emptyString = '';

const mask = createNumberMask({
    prefix: emptyString,
    includeThousandsSeparator: false,
    integerLimit: 44,
});

function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const ChaveField = (props) => {
    const classes = useStyles();
    const { formik,  disabled, nomeCampo } = props;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);
    const label = "Chave do Documento"

    return (
        <TextField
            InputProps={{ inputComponent: MaskNumbersOnly }}
            className={classes.textField}
            id={nomeCampo}
            label={label}
            required
            value={fetchFromObject(formik.values, nomeCampo)}
            error={Boolean(
                touched && error
            )}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting || disabled}
            margin="normal"
            helperText={touched && error && (
                error
            )}
            FormHelperTextProps={{ error: true }}
        />
    )
}

export default ChaveField;
