import gql from 'graphql-tag';

export const GET_CONFIGURACAO = gql`
{
    configuracao {
        id
        nome
        configuracaocertificado {
            id
            serial
            validade
        }
        configuracaomdfe {
            id
            rntrc
            estado
            numeromdfe
            seriemdfe
            timeout
            tipoambiente
            versaoservico
            mdfetptransp
            tipoemitente
        }
    }
}
`;

export const GET_INFO_CERTIFICADO = gql`
{
    configuracao {
        configuracaocertificado {
            id
            serial
            validade
        }
    }
}
`;

export const SET_CONFIGURACAO = gql`
mutation adicionarOuAtualizarConfiguracao($config: ConfiguracaoInput!){
    adicionarOuAtualizarConfiguracao(configuracao: $config) {
        id
    }
  }
`;