import React from 'react';
import { Paper, Divider, Typography, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info'

import TipoCargaField from './Fields/TipoCargaField';
import DescricaoField from './Fields/DescricaoField';
import CeanField from './Fields/CeanField';
import NcmField from './Fields/NcmField';
import LocalCheckField from './Fields/LocalCheckField';
import LocalField from './Fields/LocalField';
import { alertMessages } from '../../../../../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    select: {
        marginBottom: 28
    },
    panel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
    },
    docs: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    check: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    tooltip: {
        padding: theme.spacing(1)
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: '4px',
        marginRight: theme.spacing(1)
    },
    title: {
        padding: '4px',
        marginTop: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 400
    },
    endAdornmentButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'unset',
        },
        marginTop: '4px'
    }
}));


const ProdutoPredominantePanel = (props) =>{
    const classes = useStyles();

    const { formik, disabled } = props;

    const infLocal = formik.values.infLocal;
    const required = formik.values.tipoEmitente.value !== 2;

    return(
        <React.Fragment>
            <Paper className={classes.panel} elevation={1}>
                <div className={classes.titleContainer}>
                    <Typography variant="subtitle2" className={classes.title}>Produto Predominante da Carga</Typography>
                </div>
                <div className={classes.docs}>
                    <TipoCargaField 
                        formik={formik}
                        disabled={disabled}
                    />
                    <NcmField
                        formik={formik}
                        disabled={disabled}
                        multiline={false}
                        required={required}
                    />
                    <CeanField
                        formik={formik}
                        disabled={disabled}
                        multiline={false}
                        required={required}
                    />
                </div>
                <React.Fragment>
                    <DescricaoField
                        formik={formik}
                        disabled={disabled}
                        multiline={true}
                        required={required}
                    />
                    <div className={classes.check}>
                        <LocalCheckField 
                            formik={formik}
                            disabled={disabled}
                        />
                        <div className={classes.tooltip}>
                            <Tooltip title={alertMessages.INFO_PRODUTO_PREDOMINANTE} 
                            aria-label={alertMessages.INFO_PRODUTO_PREDOMINANTE}>
                                        <IconButton className={classes.endAdornmentButton}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>    
                            </Tooltip>
                        </div>
                    </div>
                    <Divider />
                    { infLocal ? 
                        <React.Fragment>
                            <div className={classes.docs}>
                                <LocalField
                                    formik={formik}
                                    disabled={disabled}
                                    campo={`cepCarregamento`}
                                    title={`Local de Carregamento`}/>
                                <LocalField
                                    formik={formik}
                                    disabled={disabled}
                                    campo={`cepDescarregamento`}
                                    title={`Local de Descarregamento`}/>
                            </div> 
                        </React.Fragment>: null}
                </React.Fragment>
            </Paper>
        </React.Fragment>
    );

}

export default ProdutoPredominantePanel;