import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

const mask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    integerLimit: 10,
    allowLeadingZeroes: true,
    allowNegative: false
});

function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={true}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const InfBancoField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, label } = props;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <React.Fragment>
                <TextField
                        className={classes.textField}
                        InputProps={{ inputComponent: MaskNumbersOnly }}
                        id={nomeCampo}
                        required
                        label={label}
                        value={fetchFromObject(formik.values, nomeCampo)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting || disabled}
                        FormHelperTextProps={{ error: true }}
                        error={Boolean(
                            touched && error
                        )}
                        helperText={touched && error && (
                            error
                        )}
                    />
        </React.Fragment>
    )
}

export default InfBancoField;
