import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    error: {
        margin: theme.spacing(2)
    },
}))

export const ErrorPage = (props) => {
    const classes = useStyles();

    return <Typography className={classes.error} variant="h2">
        Algo deu errado.
        </Typography>
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Você também pode registrar o erro em um serviço de relatórios de erro
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer UI alternativa
            return <ErrorPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary