import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { listaUF } from '../../../shared/utilities';
import * as actions from '../../../store/actions';
import Yup from '../../../yup';
import apolloClientProvider from '../../../apolloClientProvider';

import MunicipioField from './Fields/MunicipioField';
import ChaveDocumentoField from './Fields/ChaveDocumentoField';
import TipoDocumentoField from './Fields/TipoDocumentoField';

const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    actionButtonProgress: {
        position: 'absolute',
        top: 5,
        left: 5,
        zIndex: 1,
    },
    actionButtonContainer: {
        display: 'inline-block',
        position: 'relative',
    },
    dialogActions: {
        margin: 20
    }
}));

const handleSubmit = (values, id, close) => {
    const infDoc = [{
        cMunDescarga: values.municipio.value,
        xMunDescarga: values.municipio.label,
        chNFe: values.chaveDocumento,
        dfeDocumento: values.tipoDocumento.enumString
    }];
    return apolloClientProvider.client.mutate(
        {
            mutation: actions.INCLUIR_DFE,
            variables: { id, infDoc },
            refetchQueries: [{ query: actions.GET_MDFES }],
        })
        .then(({ data }) => {
            close();
            actions.OPEN_SNACKBAR('Documento incluído!', false, true);
        });
}

const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
};

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm());
};

const IncluirDfeDialog = (props) => {
    const classes = useStyles();

    const { mdfe, open, handleClose } = props;

    if (!mdfe) return null;

    const ufId = listaUF.find(e => e.sigla === mdfe.ufinicio).id;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Inclusão de Documento Posterior</DialogTitle>
            <Formik
                initialValues={{
                    municipio: {},
                    chaveDocumento: '',
                    tipoDocumento: {}
                }}
                onSubmit={(values, formikBag) => {
                    handleSubmit(values, mdfe.id, handleClose)
                        .then(
                            result => {
                                formikBag.setSubmitting(false);
                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        )
                }} validationSchema={Yup.object().shape({
                    municipio: Yup.object({
                        value: Yup.number().required(),
                        label: Yup.string()
                    }).required(),
                    chaveDocumento: Yup.string()
                        .min(44)
                        .max(44)
                        .required(),
                    tipoDocumento: Yup.object({
                        value: Yup.number(),
                        label: Yup.string()
                    }).required()
                })}>
                {formikProps => {
                    const { handleSubmit } = formikProps;

                    return <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <DialogContentText>
                                Deseja incluir um documento no MDF-e de chave '{mdfe.chave}'?
                                <br /><br />
                                Informe o documento:
                            </DialogContentText>
                            <TipoDocumentoField formik={formikProps} />
                            <MunicipioField formik={formikProps} ufId={ufId} />
                            <ChaveDocumentoField formik={formikProps} onchange={handleChange} onblur={handleBlur} />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                                <Fab
                                    className={classes.buttonConfirm}
                                    onClick={handleClose}
                                    color="primary"
                                    aria-label="Fechar">
                                    <CloseIcon />
                                </Fab>
                            </Tooltip>
                            <div className={classes.actionButtonContainer}>
                                <Tooltip title="Confirmar inclusão" aria-label="Confirmar inclusão" placement="top">
                                    <Fab
                                        disabled={formikProps.isSubmitting}
                                        className={classes.buttonConfirm}
                                        type="submit"
                                        color="primary"
                                        aria-label="Enviar">
                                        <CheckIcon />
                                    </Fab>
                                </Tooltip>
                                {formikProps.isSubmitting && <CircularProgress size={62} className={classes.actionButtonProgress} />}
                            </div>

                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    )
};

export default IncluirDfeDialog;

