import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik'
import { listaUF } from '../../../../../../shared/utilities';

import SelectWrapped from '../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    panel: {
        minWidth: 250
    }
}))

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
};

const UfPercursoField = props => {
    const classes = useStyles();
    const { handleChange, formik, selected, label, disabled } = props;

    const menuUFs = listaUF.map(({ id, sigla }) => (
        {
            value: id,
            label: sigla
        }
    ));

    const nomeCampo = "ufsPercurso"

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    const opcoes = menuUFs

    return (
        <React.Fragment>
            <FormControl margin="normal" className={classes.formControl}>
                <SelectWrapped
                    disabled={formik.isSubmitting || disabled}
                    id={nomeCampo}
                    label={label}
                    placeholder={null}
                    options={opcoes}
                    value={selected}
                    handleChange={(event) => handleChange(event, formik, nomeCampo)}
                    handleBlur={() => handleBlur(formik, nomeCampo)}
                    error={Boolean(touched && error)}
                />
                <FormHelperText
                    className={classes.mensagemErro}
                    error={Boolean(touched && error)}>
                    {error && touched && (
                        error
                    )}
                </FormHelperText>
            </FormControl>
        </React.Fragment>
    )

};

export default UfPercursoField;


