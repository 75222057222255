import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form } from 'formik';

import Yup from '../../../../../../yup';
import { tiposResponsavelSeguro } from '../../../../../../shared/mdfe';
import ResponsavelField from './Fields/ResponsavelField';
import DocumentoSeguradoraField from './Fields/DocumentoSeguradoraField';
import DocumentoResponsavelField from './Fields/DocumentoResponsavelField';
import NomeSeguradoraField from './Fields/NomeSeguradoraField';
import NumeroApoliceField from './Fields/NumeroApoliceField';
import NumerosAverbacoesField from './Fields/NumerosAverbacoesField';
import TipoDocumentoField from './Fields/TipoDocumentoField';


const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    dialog: {
        minWidth: 600,
        maxWidth: 800,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

const SegurosDialog = props => {
    const classes = useStyles();

    const { handleSubmit, handleClose, open, disabled, initialValues } = props

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={Yup.object().shape({
            id: Yup.number(),
            responsavel: Yup.object().shape({
                value: Yup.number(),
                label: Yup.string(),
            }).required(),
            cpfcnpjresponsavel: Yup.string()
                .when('responsavel.value', {
                    is: 2,
                    then: Yup.string().required(),
                    otherwise: Yup.string().notRequired(),
                })
                .CpfCnpj(),
            nomeseguradora: Yup.string().required().max(30),
            cnpjseguradora: Yup.string().required().CpfCnpj(),
            numeroapolice: Yup.string().required().max(20),
            numerosdasaverbacoes: Yup.string().required(),
            tipoDocumento: Yup.object().shape({
                value: Yup.bool(),
                label: Yup.string(),
            }),
        })}>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const form = <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialog}>Adicionar/Alterar Seguro</DialogTitle>
                <Form className={classes.form}>
                    <DialogContent className={classes.dialogContent}>
                        <ResponsavelField
                            disabled={disabled}
                            formik={formikProps}
                            opcoes={tiposResponsavelSeguro}
                            label={`Responsável pelo Seguro`}
                            nomeCampo={"responsavel"} />
                        <TipoDocumentoField
                            disabled={disabled}
                            formik={formikProps}
                            label={`Tipo de Documento do Responsável pelo Seguro`}
                            nomeCampo={`tipoDocumento`} />
                        <DocumentoResponsavelField
                            disabled={disabled}
                            formik={formikProps}
                            label={`Documento do Responsável pelo Seguro`}
                            tipoPessoa={formikProps.values.tipoDocumento.value}
                            nomeCampo={`cpfcnpjresponsavel`} />
                        <DocumentoSeguradoraField
                            disabled={disabled}
                            formik={formikProps}
                            label={`CNPJ da Seguradora`}
                            nomeCampo={`cnpjseguradora`} />
                        <NomeSeguradoraField
                            disabled={disabled}
                            formik={formikProps}
                            label={`Nome da Seguradora`}
                            nomeCampo={`nomeseguradora`} />
                        <NumeroApoliceField
                            disabled={disabled}
                            formik={formikProps}
                            label={`Número da Apólice`}
                            nomeCampo={`numeroapolice`} />
                        <NumerosAverbacoesField
                            disabled={disabled}
                            formik={formikProps}
                            label={`Números da Averbações`}
                            nomeCampo={`numerosdasaverbacoes`} />
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleClose}
                                color="primary"
                                aria-label="Fechar">
                                <CloseIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Confirmar" aria-label="Confirmar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleSubmit}
                                color="primary"
                                aria-label="Confirmar">
                                <CheckIcon />
                            </Fab>
                        </Tooltip>
                    </DialogActions>
                </Form>
            </Dialog>
            return props.children({
                form,
                isDirty: formikProps.dirty,
                isSubmitting: formikProps.isSubmitting,
                setFieldValue: formikProps.setFieldValue,
                setFieldError: formikProps.setFieldError,
                setFieldTouched: formikProps.setFieldTouched,
                handleSubmit: formikProps.handleSubmit,
                handleCancel: formikProps.resetForm,
            });
        }}
    </Formik>

    )
}

export default SegurosDialog