import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 226,
    }
}));

/**
 * Função para setar o valor do Data e Hora no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik) => {
    formik.setFieldValue("dataHoraEmissao", event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched("dataHoraEmissao", true, true);
};


const DataHoraEmissaoField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDateTimePicker
                name='dataHoraEmissao'
                id='dataHoraEmissao'
                required
                value={formik.values.dataHoraEmissao}
                onChange={(event) => handleChange(event, formik)}
                onBlur={() => handleBlur(formik)}
                ampm={false}
                autoOk
                format="DD/MM/YYYY HH:mm"
                // handle clearing outside => pass plain array if you are not controlling value outside
                mask={value =>
                    value
                        ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, ' ', /[0-2]/, /\d/, ':', /\d/, /\d/]
                        : []
                }
                cancelLabel='Cancelar'
                clearLabel='Limpar'
                invalidDateMessage='Data inválida'
                className={classes.textField}
                label="Data e Hora da Emissão"
                error={Boolean(
                    formik.touched.dataHoraEmissao && formik.errors.dataHoraEmissao
                )}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={formik.errors.dataHoraEmissao &&
                    formik.touched.dataHoraEmissao && (
                        formik.errors.dataHoraEmissao
                    )}
                FormHelperTextProps={{ error: true }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DataHoraEmissaoField;
