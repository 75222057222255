import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 135,
        display: 'flex',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

function MaskCNPJ(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        />
    );
}
MaskCNPJ.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function MaskCPF(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        />
    );
}
MaskCPF.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const handleBlur = (formik, campo) => {
    // this is going to call setFieldTouched and manually update touched
    formik.setFieldTouched(campo, true, true);
};

const handleChange = (event, formik, campo) => {
    formik.setFieldValue(campo, event.target.value);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const DocumentoField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, tipoPessoa, label } = props;

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    //Altera a máscara dependendo do Tipo de Pessoa
    
    let inputComponent = Input.inputComponent;

    if(tipoPessoa === 1)
        inputComponent = MaskCPF;
    if(tipoPessoa === 2)
        inputComponent = MaskCNPJ;

    return (
        <React.Fragment>
            <FormControl margin="normal" className={classes.formControl}>
            <InputLabel htmlFor={nomeCampo}>{label}</InputLabel>
            <Input
                inputComponent={inputComponent}
                disabled={formik.isSubmitting || disabled}
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                onBlur={() => handleBlur(formik, nomeCampo)}
                id={nomeCampo}
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
        </React.Fragment>
    )
}

export default DocumentoField;
