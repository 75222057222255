import { ApolloLink, HttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import { buildAxiosFetch } from 'axios-fetch';

import axios from './axios-api';
import { Policies } from './store/cache'
import { errorLink } from './shared/apollo';

export class ApolloClientProvider {
    /**
     * @constructor - Construtor do ApolloClient
     * @param {object} defaults - Default store do clientState
     * @param {object} resolvers - Resolvers do clientState
     */
    constructor() {

        this.cache = new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        ...Policies
                    }
                }
            }
        });

        const httpLink = new HttpLink({
            uri: '/graphql',
            credentials: 'include',
            fetch: buildAxiosFetch(axios),
        });

        this.link = ApolloLink.from([
            errorLink,
            httpLink
        ]);

        this.client = new ApolloClient({
            link: this.link,
            cache: this.cache
        });
    }
}

const apolloClientProvider = new ApolloClientProvider();

export default apolloClientProvider;