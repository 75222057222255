import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
//import * as Sentry from '@sentry/browser';

import apolloClient from './apolloClientProvider';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// Sentry.init({
//     dsn: 'https://46213bddbb474e228bc7512ac712b4ad@sentry.io/1393470',
// });

const theme = createTheme({
    palette: {
        primary: {
            main: '#21486d'
        }
    }
});

const app = (
    <ApolloProvider client={apolloClient.client}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter >
    </ApolloProvider >
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
