import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchFromObject } from '../../../../../../shared/utilities'


const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
    }
}));

const handleChange = (formik, event, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event.target.checked);
    formik.setFieldValue('ufDescarregamento', formik.values.ufInicio);
}

const CarregamentoPosterior = props => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const nomeCampo = "carregamentoPosterior"
    const label = "Carregamento Posterior"

    return (
        <React.Fragment>
            <div className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            size="small"
                            checked={fetchFromObject(formik.values, nomeCampo)}
                            onChange={event => handleChange(formik, event, nomeCampo)}
                            value={fetchFromObject(formik.values, nomeCampo)}
                            disabled={formik.isSubmitting || disabled}
                            id={nomeCampo}
                        />
                    }
                    label={label}
                    labelPlacement="end"
                />
            </div>
        </ React.Fragment>
    )
}

export default CarregamentoPosterior;