import { onError } from "@apollo/client/link/error";
import * as actions from '../store/actions'

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    let errors;

    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
        errors = graphQLErrors.reduce((prev, current) => {
            const { message, locations, path } = current;
            return prev.concat(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, '\n')
        }, '');
    }

    if (networkError) {
        if (networkError.result)
            errors = networkError.result.errors.reduce((res, err) => [...res, err.message], []);
        else {
            if (networkError.message === 'Network Error')
                errors = 'Problemas de comunicação com o servidor'
            else
                errors = networkError;
        }
        console.log(`[Network error]: ${networkError}`);
    }

    actions.OPEN_SNACKBAR(`Erro: ${errors}`, true, true);
});