import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client';
import { getIn } from 'formik'

import SelectWrapped from '../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import * as actions from '../../../../../../store/actions';
import Spinner from '../../../../../../components/UI/Spinner/FullWidthSpinner';
import { ErrorPage } from '../../../../../../components/ErrorBoundary/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
}))

const ReboqueField = (props) => {
    const classes = useStyles()
    const { loading, graphQlError, data } = useQuery(actions.GET_REBOQUES, { fetchPolicy: "network-only" });
    const { formik, handleChange, selected, label, disabled } = props;

    const nomeCampo = "reboques"

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    if (loading)
        return <Spinner />;

    if (graphQlError)
        return <ErrorPage />

    const { reboques } = data;
    const opcoes = reboques && reboques.node.map(r => ({ value: r.id, label: r.placa }))

    return (
        <FormControl margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                value={selected}
                handleChange={handleChange}
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    )
};

export default ReboqueField;


