import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ConfirmacaoForm from './ConfirmacaoForm';
import axios from '../../../axios-api';
import * as actions from '../../../store/actions';

const styles = {
    avatar: {
        width: 240,
        height: 240,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    },
    container: {
    },
};

const initialValues = {
    nome: '',
    cargo: '',
    departamento: '',
    email: '',
    senha: '',
    confirmacaoSenha: '',
    foto: ''
};

class PureConfirmacao extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        var parsed = queryString.parse(this.props.location.search);
        initialValues.email = parsed.email;
        this.resetForm();
    }

    handleSubmit = (values) => {
        var parsed = queryString.parse(this.props.location.search);
        const postData = {
            ...values,
            uid: parsed.uid,
            token: parsed.token
        }

        axios.post('/auth/confirm', postData)
            .then(response => {
                if (response.status === 302) {
                    actions.OPEN_SNACKBAR('Conta confirmada!')
                    this.props.history.push('/auth/confirmado');
                }
            })
            .catch(error => {
                this.setState({ error: error.response.data });
            })
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <ConfirmacaoForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </ConfirmacaoForm>
                {this.state.errors}
            </div >
        );
    };
};

export default withStyles(styles)(withRouter(PureConfirmacao));