import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchFromObject } from '../../../shared/utilities';


const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-end',
        width: '100%',
        display: 'flex',
    }
}));

const handleChange = (formik, event, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event.target.checked);
}

const CommonCheckFilterField = props =>{
    const classes = useStyles();
    const { formik, clickCheck } = props;

    const nomeCampo = "inativo";
    const label = "Mostrar Inativos";

    return (
        <React.Fragment>
            <div className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            size="small"
                            checked={fetchFromObject(formik.values, nomeCampo)}
                            onChange={ event => handleChange(formik, event, nomeCampo) }
                            onClick ={ event => clickCheck(event)}
                            value={fetchFromObject(formik.values, nomeCampo)}
                            id={nomeCampo}
                        />
                    }
                    label={label}
                    labelPlacement="end"
                />
            </div>
        </ React.Fragment>
    )
}

export default CommonCheckFilterField;