import React from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { tiposDocumentos } from '../../../../../../../shared/mdfe';
import SelectWrapped from '../../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import { fetchFromObject } from '../../../../../../../shared/utilities'

const useStyles = makeStyles(theme => ({
    formControl: {
        width: 118
    },
}))

const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const TipoField = (props) => {
    const classes = useStyles();
    
    const { formik, disabled, nomeCampo } = props;
    const label = "Tipo"

    const opcoes = tiposDocumentos;
    
    return (
        <FormControl margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                required
                value={fetchFromObject(formik.values, nomeCampo)}
                handleChange={(event) => handleChange(event, formik, nomeCampo)}
            />
        </FormControl>
    )
};

export default TipoField;