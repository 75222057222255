import React from 'react'
import { useQuery } from '@apollo/client'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as actions from '../../../store/actions'
import FullWidthSpinner from '../../../components/UI/Spinner/FullWidthSpinner'
import { ErrorPage } from '../../../components/ErrorBoundary/ErrorBoundary'

const useStyles = makeStyles(theme => ({
    message: {
        margin: theme.spacing(2)
    },
}))

const NaoEncerrados = () => {
    const classes = useStyles()
    const { loading, graphQlError, data } = useQuery(actions.CONSULTA_NAO_ENCERRADOS)

    if (loading) return <FullWidthSpinner />
    if (graphQlError || !data) return <ErrorPage />

    const { consultarNaoEncerrados } = data;

    if (!consultarNaoEncerrados)
        return <ErrorPage />

    //Código 112 - Não possui MDFes a encerrar
    if (consultarNaoEncerrados.cstat === 112)
        return <Typography className={classes.message} variant="h6">
            {consultarNaoEncerrados.xmotivo}
        </Typography>

    //Código 111 - Possui MDFes a encerrar
    if (consultarNaoEncerrados.cstat === 111) {
        let texto;
        if (consultarNaoEncerrados.infmdfe.length === 1) {
            texto = "Foi encontrado o seguinte MDF-e não encerrado:"
        }
        else {
            texto = "Foram encontrados os seguintes MDF-e não encerrados:"
        }
        return <React.Fragment>
            <Typography className={classes.message} variant="h6">
                {texto}
            </Typography>
            <List>
                {consultarNaoEncerrados.infmdfe.map(element => {
                    return <ListItem key={element.chave}>
                        <ListItemText
                            primary={`Chave: ${element.chave}`}
                            secondary={`Protocolo de Autorização: ${element.nprot}`}
                        />
                    </ListItem>
                })}
            </List>

        </React.Fragment>
    }
}

export default NaoEncerrados;