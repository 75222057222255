import gql from 'graphql-tag';

export const GET_VEICULOS = gql`
query veiculos($busca: String, $offset: Int, $limit: Int, $inativo: Boolean) {
    veiculos (busca: $busca, offset: $offset, limit: $limit, inativo: $inativo) {
        node {
            ... on VeiculoType {
                    id
                    placa
                    tiporodado
                    uf
                    inativo
                    podeexcluir
                }
            }
        totalcount
    }
}
`;

export const GET_VEICULO = gql`
query veiculo($id: Int){
    veiculo(veiculo: { id: $id }){
        id
        carroceria
        placa
        capacidadekg
        capacidadecubica
        renavam
        rntrc
        proprietario {
            id
            cpfcnpj
            id
            nome
            rgie
            rntrc
            tipoproprietario
            uf
        }
        tara
        tiporodado
        uf
        inativo
    }
}
`;

export const SET_VEICULO = gql`
mutation adicionarOuAtualizarVeiculo($veiculo: VeiculoInput!) {
    adicionarOuAtualizarVeiculo(veiculo: $veiculo) {
        id
        placa
    }
  }
`;

export const EXCLUIR_VEICULO = gql`
mutation excluirVeiculo($id: Int!){
    excluirVeiculo(id: $id)
}
`;