import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import VeiculosForm from './Form/ReboquesForm';
import { listaUF, somenteNumeros, padNumber } from '../../../shared/utilities';
import { listaTiposCarroceria, listaTiposProprietario, tiposDocumentoEmpresa } from '../../../shared/mdfe';

const styles = {

}

const initialValues = {
    id: '',
    renavam: '',
    tara: '',
    capacidadekg: '',
    capacidadecubica: '',
    tipoCarroceria: { value: 2, label: listaTiposCarroceria.find(t => t.value === 2).label },
    uf: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    naoProprietario: false,
    tipoProprietario: { value: 0, label: listaTiposProprietario.find(t => t.value === 0).label },
    rntrc: '',
    ufProprietario: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    tipoDocumento: tiposDocumentoEmpresa[1],
    documento: '__.___.___/___-__',
    nome: '',
    ie: '',
    placa: '',
    inativo: false
}

class PureReboquesCreateUpdate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {
            apolloClientProvider.client.query({ query: actions.GET_REBOQUE, variables: { id: id }, fetchPolicy: "network-only" })
                .then(({ data: { reboque } }) => {
                    const { proprietario } = reboque;
                    const resetData = {
                        id: reboque.id,
                        placa: reboque.placa ? reboque.placa : initialValues.placa,
                        renavam: reboque.renavam ? reboque.renavam : initialValues.renavam,
                        tara: reboque.tara ? reboque.tara : initialValues.tara,
                        capacidadekg: reboque.capacidadekg ? parseInt(reboque.capacidadekg) : initialValues.capacidadekg,
                        capacidadecubica: reboque.capacidadecubica ? parseInt(reboque.capacidadecubica) : initialValues.capacidadecubica,
                        tipoCarroceria: reboque.carroceria ?
                            {
                                value: listaTiposCarroceria.find(t => t.enumString === reboque.carroceria).value,
                                label: listaTiposCarroceria.find(t => t.enumString === reboque.carroceria).label
                            } : initialValues.tipoCarroceria,
                        uf: reboque.uf ?
                            {
                                value: listaUF.find(e => e.sigla === reboque.uf).id,
                                label: reboque.uf
                            } : initialValues.uf,
                        naoProprietario: Boolean(proprietario),
                        prorietarioId: proprietario && proprietario.id,
                        tipoProprietario: proprietario && proprietario.tipoproprietario ?
                            {
                                value: listaTiposProprietario.find(t => t.enumString === proprietario.tipoproprietario).value,
                                label: listaTiposProprietario.find(t => t.enumString === proprietario.tipoproprietario).label
                            } : initialValues.tipoProprietario,
                        rntrc: proprietario && proprietario.rntrc ? padNumber(parseInt(proprietario.rntrc, 10), 8) : initialValues.rntrc,
                        ufProprietario: proprietario && proprietario.uf ?
                            {
                                value: listaUF.find(e => e.sigla === proprietario.uf).id,
                                label: proprietario.uf
                            } : initialValues.uf,
                            tipoDocumento: proprietario && proprietario.cpfcnpj &&
                            (proprietario.cpfcnpj.length === 11) ? tiposDocumentoEmpresa[0]
                            : initialValues.tipoDocumento,
                        documento: proprietario && proprietario.cpfcnpj ?
                            proprietario.cpfcnpj
                            : initialValues.documento,
                        nome: proprietario && proprietario.nome ?
                            proprietario.nome
                            : initialValues.nome,
                        ie: proprietario && proprietario.rgie ?
                            proprietario.rgie
                            : initialValues.ie,
                        inativo: reboque.inativo
                    }
                    this.resetForm({values: resetData});
                });
        }
    }

    handleSubmit = (values, addHandlers) => {
        const id = this.props.match.params.id;

        const proprietario = values.naoProprietario ?
            {
                id: values.proprietarioId,
                nome: values.nome,
                cpfcnpj: somenteNumeros(values.documento),
                rntrc: values.rntrc.toString(),
                rgie: values.ie,
                tipoproprietario: listaTiposProprietario.find(t => t.value === values.tipoProprietario.value).enumString,
                uf: values.ufProprietario.label
            }
            : null;

        const submitData = {
            id: id ? parseInt(id) : undefined,
            placa: values.placa,
            tara: values.tara,
            proprietario,
            capacidadekg: values.capacidadekg,
            capacidadecubica: values.capacidadecubica ? values.capacidadecubica : undefined,
            carroceria: listaTiposCarroceria.find(t => t.value === values.tipoCarroceria.value).enumString,
            uf: values.uf.label,
            inativo: values.inativo,
            renavam: values.renavam ? values.renavam : undefined 
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_REBOQUE,
                    variables: { reboque: submitData },
                    refetchQueries: [{ query: actions.GET_REBOQUES }],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Reboque cadastrado!');
                    this.props.history.push('/reboques');
                })
                .catch(res => {
                    const errors = res.networkError.result.errors.reduce((res, err) => [...res, err.message], []);
                    actions.OPEN_SNACKBAR(`Erro: ${errors}`, true, true);
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <VeiculosForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </VeiculosForm>
                {this.state.errors}
            </div >
        );
    };
}

export default withStyles(styles)(withRouter(PureReboquesCreateUpdate));