import React, { Fragment, useEffect } from "react";
import { Avatar, Drawer, Collapse, Divider, List, ListItem, ListItemText, Menu, ListItemIcon, MenuItem, ListItemAvatar, Hidden } from '@material-ui/core';
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import placeholder from '../../../content/images/avatar-placeholder.png';
import iconeZeusBranco from '../../../content/images/iconeZeus-branco.svg';
import NavigationItem from "../../NavigationItem/NavigationItem";
import * as actions from '../../../store/actions';
import AccountCardDetailsIcon from '../../../content/icons/AccountCardDetailsIcon';
import TruckTrailerIcon from '../../../content/icons/TruckTrailer';
import MDFeIcon from '../../../content/icons/MDFeIcon';
import ToolsIcon from "../../../content/icons/ToolsIcon";


const useStyles = makeStyles(theme => ({
    noPadding: {
        padding: 0,
    },
    cinzaMenu: {
        color: '#f2f2f29e',
        minWidth: 40,
    },
    avatarDiv: {
        display: 'inline-block',
        width: '100%',
        verticalAlign: 'middle'
    },
    avatar: {
        display: 'inline-block',
        width: '25%',
        height: '25%'
    },
    avatar__nomeUsuario: {
        lineHeight: '60px',
        paddingRight: 20,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    drawerPaper: {
        [theme.breakpoints.up('sm')]: {
            width: 230,
            flexShrink: 0,
        },
        width: 280,
        height: '100vh',
        backgroundColor: theme.palette.primary.main,
    },
    sidedrawer__lista: {
        paddingTop: 0,
        paddingBottom: 0
    },
    sidedrawer__listaCollapsed: {
        paddingLeft: 16
    },
    contato: {
        color: '#f2f2f29e',
        textAlign: 'center',
        fontSize: 9.5,
        bottom: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 178
    },
    guttersPadding16: {
        paddingLeft: 16,
        paddingRight: 16
    },
    avatarContainer: {
        padding: '20px 10px'
    },
    active: {
        borderLeft: '2px solid #ccc',
    },
}));

const SideDrawer = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [configuracaoCollapseOpen, setConfiguracaoCollapseOpen] = React.useState(false);
    const [ferramentasCollapseOpen, setFerramentasCollapseOpen] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { loading, error, data } = useQuery(actions.GET_AUTH);
    useEffect(() => {
        const currentLocation = location.pathname;
        if(currentLocation.includes('ferramentas'))
            setFerramentasCollapseOpen(true)
        else if(currentLocation.includes('emitente'))
            setConfiguracaoCollapseOpen(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    if (loading) return null;
    if (error) return error;

    const { auth } = data;

    const { handleDrawerToggle, mobileOpen } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAlterarSenhaClicked = () => {
        setAnchorEl(null)
        history.push('/auth/alterarsenha');
    }

    const handleConfiguracaoCollapseClicked = () => {
        setConfiguracaoCollapseOpen(!configuracaoCollapseOpen);
    }

    const handleFerramentasCollapseClicked = () => {
        setFerramentasCollapseOpen(!ferramentasCollapseOpen);
    }

    const logout = () => {
        actions.LOGOUT();
    }

    const foto = auth.foto ? auth.foto : placeholder;

    const avatar = (
        <React.Fragment>
            <ListItem
                button
                className={classes.avatarContainer}
                onClick={handleClick}>
                <ListItemAvatar>
                    <Avatar
                        alt="Avatar"
                        //className={classes.avatar}
                        src={foto}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`Olá, ${auth.nome}!`}
                    classes={{ primary: classes.cinzaMenu }} />
            </ListItem>

            <Menu
                id="menu-usuario"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}                    >
                <MenuItem
                    onClick={handleAlterarSenhaClicked}>
                    <ListItemIcon><LockIcon /></ListItemIcon>
                    Alterar senha
                </MenuItem>
                <MenuItem
                    onClick={logout}>
                    <ListItemIcon><CloseIcon /></ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </React.Fragment>

    );

    const listContent = (
        <Fragment>
            <NavigationItem link="/mdfe" icon={<MDFeIcon />} texto="MDF-e" />
            <Divider />
            <NavigationItem link="/veiculos" icon={<LocalShippingIcon />} texto="Veículos" />
            <Divider />
            <NavigationItem link="/reboques" icon={<TruckTrailerIcon />} texto="Reboques" />
            <Divider />
            <NavigationItem link="/condutores" icon={<AccountCardDetailsIcon />} texto="Condutores" />
            <Divider />
            <ListItem
                button
                onClick={handleFerramentasCollapseClicked}
                classes={{ gutters: classes.guttersPadding16 }}>
                <ListItemIcon className={classes.cinzaMenu}>
                    <ToolsIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.cinzaMenu }}
                    primary="Ferramentas" />
                {ferramentasCollapseOpen ? <ExpandLessIcon className={classes.cinzaMenu} /> : <ExpandMoreIcon className={classes.cinzaMenu} />}
            </ListItem>
            <Collapse in={ferramentasCollapseOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Divider />
                    <NavigationItem
                        doublePaddingLeft
                        link="/ferramentas/consulta-nao-encerrados"
                        icon={<NewReleasesIcon />}
                        texto="Consulta Não Encerrados" />
                    <NavigationItem
                        doublePaddingLeft
                        link="/ferramentas/encerramento-por-chave"
                        icon={<VpnKeyIcon />}
                        texto="Encerramento por Chave" />
                </List>
            </Collapse>
            <Divider />
            <ListItem
                button
                onClick={handleConfiguracaoCollapseClicked}
                classes={{ gutters: classes.guttersPadding16 }}>
                <ListItemIcon className={classes.cinzaMenu}>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.cinzaMenu }}
                    primary="Configurações" />
                {configuracaoCollapseOpen ? <ExpandLessIcon className={classes.cinzaMenu} /> : <ExpandMoreIcon className={classes.cinzaMenu} />}
            </ListItem>
            <Collapse in={configuracaoCollapseOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Divider />
                    <NavigationItem doublePaddingLeft link="/emitente" icon={<BusinessIcon />} texto="Emitente" />
                </List>
            </Collapse>
        </Fragment>
    );

    const contato = (
        <div className={classes.contato}>
            <img src={iconeZeusBranco} alt="IconeZeus" />
            <p>(79) 3432-8350, 99912-8350, 99913-8350</p>
            <p>contato@zeusautomacao.com.br</p>
            <p>Av. Otoniel Dórea, 455, Itabaiana - Sergipe</p>
        </div>
    );

    return (
        <React.Fragment>
            <Hidden smUp implementation="css">
                <Drawer
                    data- test="component-side-drawer"
                    classes={{ paper: classes.drawerPaper }}
                    onClose={handleDrawerToggle}
                    variant="temporary"
                    open={mobileOpen}
                    ModalProps={{
                        keepMounted: true
                    }}>
                    <List className={classes.sidedrawer__lista}>
                        {avatar}
                        <Divider />
                        {listContent}
                    </List>
                    <Divider />
                    {contato}
                </Drawer >
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    data- test="component-side-drawer"
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open>
                    <List className={classes.sidedrawer__lista}>
                        {avatar}
                        <Divider />
                        {listContent}
                    </List>
                    <Divider />
                    {contato}
                </Drawer >
            </Hidden>
        </React.Fragment>

    )
}
export default SideDrawer;