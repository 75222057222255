import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'inline-flex',
    }
}));

const CapM3Field = (props) => {
    const classes = useStyles();
    const { formik } = props;
    return (
        <FormControlLabel
            className={classes.textField}
            control={
                <Switch
                    checked={formik.values.naoProprietario}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    value="naoProprietario"
                    id="naoProprietario"
                    color="primary"
                />
            }
            label="Veículo de terceiros?"
        />

    )
}

export default CapM3Field;



