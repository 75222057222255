import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    cinzaMenu: {
        color: '#f2f2f29e',
        minWidth: 40,
    },
    guttersPadding16: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    guttersPadding32: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2)
    },
    active: {
        borderLeft: '2px solid #ccc',
    }
}));

const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink {...itemProps} ref={ref} />
));

const NavigationItem = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { link, doublePaddingLeft, icon, texto } = props;
    return <ListItem
        button
        component={renderLink}
        selected={location.pathname === link}
        to={link}
        //activeClassName={props.classes.active}
        classes={{
            gutters: doublePaddingLeft ? classes.guttersPadding32 : classes.guttersPadding16,
            selected: classes.active
        }}>
        <ListItemIcon className={classes.cinzaMenu}>
            {icon}
        </ListItemIcon>
        <ListItemText
            classes={{ primary: classes.cinzaMenu }}
            primary={texto} />
    </ListItem>
};

export default NavigationItem;


