import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Fab, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, yupToFormErrors, validateYupSchema, isFunction } from "formik";

import InformacaoPagamentoField from './Fields/InformacaoPagamentoField';
import InformacaoResponsavelField from './Fields/InformacoesResponsavelField';
import InformacaoBancoField from './Fields/InformacaoBancoField';
import ComponenteField from './Fields/ComponenteField';
import InformacaoPrazoField from './Fields/InformacaoPrazoField';
import PagamentoSchemaDados from './Schema/PagamentoSchemaDados';


const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    dialogTitle: {
        width: '100%',
        paddingBottom: '0px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1)
    },
    dialogGroup: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',    
    },
    form: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

const PagamentoInfDialog = (props) => {
    const classes = useStyles();

    const { handleSubmit, handleClose, open, disabled, initialValues } = props

    const validateFromSchema = (validationSchema, values) => {
        return new Promise((resolve, reject) => {
            const schema = isFunction(validationSchema)
                ? validationSchema()
                : validationSchema
            validateYupSchema(values, schema).then(
                () => {
                    resolve({});
                },
                (err) => {
                    reject(yupToFormErrors(err));
                }
            );
        });
    }

    const validate = (values) => {
        return validateFromSchema(PagamentoSchemaDados, values)
            .then((data) => {
                return data
            })
            .catch((err) => {
                return err;
            })
    };

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values) =>{ 
            values.show = true;
            return handleSubmit(values)
        }}
        validate={ validate }>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const infParcelas = formikProps.values.indpag.value === 1;

            const form = <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth = {'md'}>
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}>Adicionar/Alterar Pagamento do Frete</DialogTitle>
                <Form className={classes.form}>
                    <DialogContent className={classes.dialogContent}>
                        <Typography className={classes.title} variant="subtitle2" color="inherit">Informações do Frete</Typography>
                        <InformacaoResponsavelField formik={formikProps} disabled={disabled}/>
                        <div className={classes.dialogGroup}>
                            <InformacaoPagamentoField formik={formikProps} disabled={disabled}/>
                            <InformacaoBancoField formik={formikProps} disabled={disabled}/>
                        </div>
                    
                        <div className={classes.dialogGroup}>
                            <ComponenteField formik={formikProps} disabled={disabled} />
                            {
                            infParcelas ? 
                                <div className={classes.dialogGroup}>
                                    <InformacaoPrazoField formik={formikProps} disabled={disabled} />
                                </div>
                            : null
                            }
                        </div>
                        
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleClose}
                                color="primary"
                                aria-label="Fechar">
                                <CloseIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Confirmar" aria-label="Confirmar" placement="top">
                            <Fab
                                className={classes.buttonConfirm}
                                onClick={handleSubmit}
                                color="primary"
                                aria-label="Confirmar">
                                <CheckIcon />
                            </Fab>
                        </Tooltip>
                    </DialogActions>
                </Form>
            </Dialog>
            return props.children({
                form,
                isDirty: formikProps.dirty,
                isSubmitting: formikProps.isSubmitting,
                setFieldValue: formikProps.setFieldValue,
                setFieldError: formikProps.setFieldError,
                setFieldTouched: formikProps.setFieldTouched,
                handleSubmit: formikProps.handleSubmit,
                handleCancel: formikProps.resetForm,
            });
        }}
    </Formik>
    );

}

export default PagamentoInfDialog;