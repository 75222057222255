import { makeVar } from '@apollo/client';

export const snackbar = makeVar({
    open: false,
    message: null,
    error: false,
    noAutoClose: false,
    __typename: 'Snackbar',
});

export const setSnackbar = (value) => snackbar(value);

export const snackbarPolicy = {
    snackbar: {
        read() {
            return snackbar();
        }
    }
}