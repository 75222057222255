import gql from 'graphql-tag';

export const GET_ESTADOS = gql`
{
  clientes {
      id
      nome
      tipopessoa
      nomefantasia
      telefones{
          ddi
          numero
      }
  }
}
`;

export const GET_MUNICIPIOS = gql`
query municipios($id: Int){
    municipios(estado: { id: $id }){
        id
        nome
    }
}
`;

