import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import { tiposDocumentos } from '../../../../shared/mdfe';
import SelectWrapped from '../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';


const useStyles = makeStyles(theme => ({
    formControl: {
        width: 135,
        marginTop: theme.spacing(1),
        marginRight: 0,
    }
}));

const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
}

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm());
};


const TipoField = (props) => {
    const classes = useStyles();

    const { formik } = props;

    const label = "Tipo Documento"
    const nomeCampo = 'tipoDocumento';

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error);

    const opcoes = tiposDocumentos;

    return (
        <FormControl margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting}
                label={label}
                id={nomeCampo}
                placeholder=""
                handleChange={(event) => handleChange(event, formik, nomeCampo)}
                handleBlur={() => handleBlur(formik, nomeCampo)}
                options={opcoes}
                required
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    )
};

export default TipoField;