import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 250,
        display: 'flex',
    }
}));

const NomeSeguradoraField = (props) => {
    const classes = useStyles();

    const { formik, nomeCampo, disabled, label } = props;

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return <TextField
        className={classes.textField}
        id={nomeCampo}
        label={label}
        value={fetchFromObject(formik.values, nomeCampo)}
        error={Boolean(touched && error)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={formik.isSubmitting || disabled}
        margin="normal"
        helperText={error && touched && (
            error
        )}
        FormHelperTextProps={{ error: true }}
    />
}

export default NomeSeguradoraField;
