import Yup from '../../../../../yup';

const MDFeSchemaDados = Yup.object().shape({
    modelo: Yup
        .number()
        .required(),
    serie: Yup
        .number()
        .min(0)
        .max(999),
    numero: Yup
        .number()
        .min(0)
        .max(999999999),
    dataHoraEmissao: Yup
        .date()
        .required(),
    dataHoraInicioViagem: Yup
        .date()
        .notRequired()
        .nullable(),
    modal: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }),
    ufInicio: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }),
    ufDescarregamento: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }),
    veiculo: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required()
    }).required(),
    tipoEmitente: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
        enumString: Yup.string()
    }).required(),
    ufsPercurso: Yup.array()
        .of(Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        }))
        .max(25),
    municipiosCarregamento: Yup.array()
        .of(Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        }))
        .min(1)
        .max(50)
        .required(),
    reboques: Yup.array()
        .of(Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        }))
        .max(3),
    condutores: Yup.array()
        .of(Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        }))
        .min(1)
        .max(10)
        .required(),
    chave: Yup.string()
        .min(44)
        .max(44),
    ncm: Yup.string()
            .min(8)
            .max(8)
            .when('tipoEmitente.value', {
                is: 2,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required(),
            }),
    xProd: Yup.string()
            .min(2)
            .max(120)
            .when('tipoEmitente.value', {
                is: 2,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required(),
            }),
    cEAN: Yup.string()
            .min(14)
            .max(14)
            .when('tipoEmitente.value', {
                is: 2,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required(),
            }), 
    infLocal: Yup.bool(),
    cepCarregamento: Yup
        .string()
        .when('infLocal', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        })
        .min(8)
        .max(8),        
    cepDescarregamento: Yup
        .string()
        .when('infLocal', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        })
        .min(8)
        .max(8),
});

export default MDFeSchemaDados;