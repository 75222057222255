import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 65,
    }
}));

const SeriemdfeField = (props) => {
    const classes = useStyles();
    const { formik } = props;

    const nomeCampo = 'seriemdfe';
    const label = 'Série';

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id={nomeCampo}
                label={label}
                type='number'
                required
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />

        </React.Fragment>
    )
}

export default SeriemdfeField;
