import * as Yup from 'yup';
import { validateCpfCnpj, somenteNumeros, validateIdEstrangeiro, validateValorMonetario } from './shared/utilities';

Yup.setLocale({
  mixed: {
    notType: 'O valor ${originalValue} deve ser do tipo ${type}', // eslint-disable-line
    default: 'Não é válido',
    required: 'Requerido',
    notOneOf: 'Valor ${values} inválido', // eslint-disable-line
  },
  string: {
    email: 'E-mail inválido',
    min: 'Valor muito curto (mínimo ${min} caracteres)', // eslint-disable-line
    max: 'Valor muito longo (máximo ${max} caracteres)', // eslint-disable-line
    matches: '${path} está em formato inválido', // eslint-disable-line
  },
  number: {
    min: 'Valor muito baixo (mínimo ${min})', // eslint-disable-line
    max: 'Valor muito alto (máximo ${max})', // eslint-disable-line
    integer: 'O valor deve ser um número inteiro',
    positive: 'O valor deve ser positivo'
  },
  array: {
    min: 'Quantidade de itens insuficiente (mínimo ${min})', // eslint-disable-line
    max: 'Quantidade de itens excedida (máximo ${max})', // eslint-disable-line
  },
});

// function localeNumber(message = "Número inválido") {
//   return this.test({
//     message,
//     name: 'LocaleNumber',
//     exclusive: true,
//     test: function (value) {
//       value = value.replace(/\./g, '').replace(',', '.');
//       return !isNaN(parseFloat(value)) && isFinite(value);
//     }
//   })
// }

// Yup.addMethod(Yup.number, 'LocaleNumber', localeNumber);

function cpf(message = "CPF inválido") {
  return this.test({
    message,
    name: 'Cpf',
    exclusive: true,
    test: function (value) { return (value == null || value === "" || somenteNumeros(value) === "" || validateCpfCnpj(value)); }
  })
}

Yup.addMethod(Yup.string, 'cpf', cpf);

function cpfCnpj(message = "CPF/CNPJ inválido") {
  return this.test({
    message,
    name: 'CpfCnpj',
    exclusive: true,
    test: function (value) { return (value == null || value === "" || somenteNumeros(value) === "" || validateCpfCnpj(value)); }
  })
}

Yup.addMethod(Yup.string, 'CpfCnpj', cpfCnpj);

function idEstrangeiro(message = "ID Estrangeiro inválido") {
  return this.test({
    message,
    name: 'IdEstrangeiro',
    exclusive: true,
    test: function (value) { return validateIdEstrangeiro(value); }
  })
}

Yup.addMethod(Yup.string, 'IdEstrangeiro', idEstrangeiro);

function valorMonetario(message = "Valor inválido") {
  return this.test({
    message,
    name: 'ValorMonetario',
    exclusive: true,
    test: function (value) { return validateValorMonetario(value); }
  })
}

Yup.addMethod(Yup.string, 'ValorMonetario', valorMonetario);


export default Yup;