import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    icon: {
        colorPrimary: 'inherit',
        fontSize: 'inherit',
        width: 24,
        height: 24,
    },
})

function MdfeIcon(props) {
    const classes = useStyles();
    return (
        <SvgIcon className={classes.icon}>
            <path d="m2.7972 10.236a0.90998 0.90998 0 0 0 -0.90992 0.90999v7.2798c0 0.50049 0.40493 0.90997 0.90992 0.90997h5.4599a0.90998 0.90998 0 0 0 0.90999 -0.90997v-5.4599l-2.7299-2.73zm3.1849 0.68249 2.5024 2.5024h-2.5024zm-5.9148 1.1375v8.1898a0.90998 0.90998 0 0 0 0.90992 0.90998h7.2799v-0.90998h-7.2799v-8.1898zm3.6417 2.634h3.64v0.90998h-3.64zm0 1.82h2.275v0.90998h-2.275zm4.5744-13.665a1.7539 1.7539 0 0 0 -1.7547 1.7533h-3.2885l0.75395 1.3157h6.4371l0.5265 1.3157h-6.8408l0.65841 1.3157h4.2972l0.52513 1.3157h-2.6104l2.7018 2.7019v1.2033a1.3154 1.3154 0 0 1 1.2242 -0.83617 1.3154 1.3154 0 0 1 1.3156 1.3157 1.3154 1.3154 0 0 1 -1.3156 1.3144 1.3154 1.3154 0 0 1 -1.2242 -0.83355v1.8448a2.6308 2.6308 0 0 0 1.2242 0.30442 2.6308 2.6308 0 0 0 2.6313 -2.63h3.508a2.6308 2.6308 0 0 0 2.63 2.63 2.6308 2.6308 0 0 0 2.6313 -2.63h1.7533v-4.3847l-2.63-3.508h-2.6313v-3.508zm10.523 4.8237h2.1923l1.7194 2.1923h-3.9117zm0.8767 5.2613a1.3154 1.3154 0 0 1 1.3156 1.3157 1.3154 1.3154 0 0 1 -1.3156 1.3144 1.3154 1.3154 0 0 1 -1.3156 -1.3144 1.3154 1.3154 0 0 1 1.3156 -1.3157z"/>
        </SvgIcon>
    );
}

export default MdfeIcon;