import React, { useState } from 'react';
import { Divider, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray } from 'formik';

import * as cores from '../../../../../../shared/cores';
import { fetchFromObject } from '../../../../../../shared/utilities';
import PagamentoInfDialog from './PagamentoInfDialog';
import { tiposDocumentoContratantes, tipoPagamento } from '../../../../../../shared/mdfe';


const useStyles = makeStyles(theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        flexDirection: 'column'
    },
    table: {
        width: '100%',
        margin: 8,
    },
    tableContentRow: {
        verticalAlign: 'center'
    },
    removerCell: {
        verticalAlign: 'middle',
    },
    divider: {
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    title: {
        color: cores.titulo,
        marginLeft: theme.spacing(1),
    },
    hover: {
        cursor: 'pointer'
    }
}))


const PagamentoInfPanel = (props) => {
    const [pagamentoDialogOpen, setPagamentoDialogOpen] = useState(false);
    const classes = useStyles();

    //TODO: Adicionar paginação?
    const { formik, disabled } = props;

    const tableData = formik.values.mdfeInfPag;

    let resetForm = null;

    const initialDialogValues = {
        tpdocumento: tiposDocumentoContratantes[0],
        xnome: '',
        cpfCnpjId: '',
        vcontrato: '',
        indpag: tipoPagamento[0],
        codbanco: '',
        codagencia: '',
        comp: [],
        infPrazo:[]
    };

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    const handleDialogOpen = () => {
        setPagamentoDialogOpen(true);
    }

    const handleDialogClose = () => {
        setPagamentoDialogOpen(false);
    }

    const handleDialogSubmit = (values, formikProps, arrayHelpers) => {
        if (values.index !== undefined) {
            Object.keys(values).forEach(key => formikProps.setFieldValue(`mdfeInfPag[${values.index}].${key}`, values[key]))
        }
        else {
            arrayHelpers.push(values);
        }
        setPagamentoDialogOpen(false);
    }

    const handleAdd = (index, formikProps) => {
        handleDialogOpen();
        if (index !== undefined) {
            const infPag = fetchFromObject(formikProps.values, `mdfeInfPag.${index}`);
            const resetData = { ...infPag, index }
            resetForm({values: resetData});
        }
        else {
            resetForm({values: initialDialogValues});
        }
    }

    return (
        <React.Fragment>
            <div className={classes.divider}>
                <Divider />
            </div>

            <div className={classes.title}>
                <Typography variant="h6" color="inherit">Pagamento do Frete</Typography>
            </div>

            <FieldArray
                name='mdfeInfPag'
                render={
                    arrayHelpers => (
                        <React.Fragment>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Responsável
                                                        </TableCell>
                                        <TableCell>
                                            Documento do Responsável
                                                        </TableCell>
                                        <TableCell>
                                            Tipo do Pagamento
                                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .map((n, index) => (
                                            <TableRow
                                                hover
                                                className={classes.tableContentRow}
                                                tabIndex={-1}
                                                key={index}>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    { n.xnome !== '' ? n.xnome : '-'}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    { n.cpfCnpjId }
                                                </TableCell>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    { n.indpag.label }
                                                </TableCell>
                                                <TableCell
                                                    className={classes.removerCell}
                                                    component="th"
                                                    scope="row">
                                                    <IconButton
                                                        disabled={formik.isSubmitting || disabled}
                                                        aria-label="Delete" onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    <PagamentoInfDialog
                                        open={pagamentoDialogOpen}
                                        initialValues={initialDialogValues}
                                        handleSubmit={(values) => handleDialogSubmit(values, formik, arrayHelpers)}
                                        handleClose={handleDialogClose}
                                        disabled={disabled}>
                                        {({ form, handleCancel }) => (
                                            <React.Fragment>
                                                {resetForm = handleCancel}
                                                {form}
                                            </React.Fragment>
                                        )}
                                    </PagamentoInfDialog>
                                </TableBody>
                            </Table>

                            {!formik.isSubmitting && (
                                <IconButton
                                    disabled={formik.isSubmitting || disabled}
                                    className={classes.buttonAdd}
                                    aria-label="Adicionar"
                                    onClick={() => handleAdd(undefined, formik)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        </React.Fragment>
                    )
                }
            />
        </React.Fragment>
    );
}


export default PagamentoInfPanel;