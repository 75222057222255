import React, { useState } from 'react';
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, Tooltip, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import apolloClientProvider from '../../../../apolloClientProvider';
import * as actions from '../../../../store/actions';

const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: 8
    },
    textField: {
        minWidth: 400,
    },
    actionButtonProgress: {
        position: 'absolute',
        top: 5,
        left: 5,
        zIndex: 1,
    },
    actionButtonContainer: {
        display: 'inline-block',
        position: 'relative',
    },
    dialogActions: {
        margin: 20
    }
}))

const ExcluirDialog = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const classes = useStyles();

    const handleSubmit = (id, close) => {
        setIsSubmitting(true);
        apolloClientProvider.client.mutate(
            {
                mutation: actions.EXCLUIR_MOTORISTA,
                variables: { id },
                refetchQueries: [{ query: actions.GET_CONDUTORES }],
            })
            .then(({ data }) => {
                actions.OPEN_SNACKBAR('Condutor excluído!', false, true);
                setIsSubmitting(false);
                close();
            })
            .catch(res => {
                setIsSubmitting(false);
            })
    }
    
    if (!props.condutor) return null;

    return <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Excluir Condutor</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {`Você deseja excluir o Condutor: ${props.condutor.nome} - ${props.condutor.cpfcnpj}?` }
            </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
            <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                <Fab
                    className={classes.buttonConfirm}
                    onClick={props.handleClose}
                    color="primary"
                    aria-label="Fechar">
                    <CloseIcon />
                </Fab>
            </Tooltip>
            <div className={classes.actionButtonContainer}>
                <Tooltip title="Confirmar exclusão" aria-label="Confirmar exclusão" placement="top">
                    <Fab
                        onClick={() => handleSubmit(props.condutor.id, props.handleClose)}
                        disabled={isSubmitting}
                        className={classes.buttonConfirm}
                        color="primary"
                        aria-label="Enviar">
                        <CheckIcon />
                    </Fab>
                </Tooltip>
                {isSubmitting && <CircularProgress size={62} className={classes.actionButtonProgress} />}
            </div>
        </DialogActions>
    </Dialog >
}

export default ExcluirDialog;