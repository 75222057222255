import React, { useState } from 'react';
import { Divider, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { FieldArray } from 'formik';
import { tiposResponsavelSeguro, tiposDocumentoEmpresa } from '../../../../../../shared/mdfe';
import ResponsavelField from './Fields/ResponsavelField';
import DocumentoSeguradoraField from './Fields/DocumentoSeguradoraField';
import NomeSeguradoraField from './Fields/NomeSeguradoraField';
import NumeroApoliceField from './Fields/NumeroApoliceField';
import * as cores from '../../../../../../shared/cores';
import SegurosDialog from './SegurosDialog';
import { fetchFromObject } from '../../../../../../shared/utilities';


const useStyles = makeStyles(theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        flexDirection: 'column'
    },
    table: {
        width: '100%',
        margin: 8,
    },
    tableContentRow: {
        verticalAlign: 'top'
    },
    removerCell: {
        verticalAlign: 'middle',
    },
    divider: {
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    title: {
        color: cores.titulo,
        marginLeft: theme.spacing(1),
    },
    hover: {
        cursor: 'pointer !important'
    }
}))

const SegurosPanel = (props) => {
    const [arquivoDialogOpen, setArquivoDialogOpen] = useState(false);
    const classes = useStyles();
    //const [dialogInitialValues, setDialogInitialValues] = useState(null);

    //TODO: Adicionar paginação?
    const { formik, disabled } = props;

    const tableData = formik.values.seguros;

    let resetForm = null;
    let setDialogFieldTouched = null;

    const initialDialogValues = {
        responsavel: tiposResponsavelSeguro[0],
        cpfcnpjresponsavel: '',
        nomeseguradora: '',
        cnpjseguradora: '',
        numeroapolice: '',
        numerosdasaverbacoes: '',
        tipoDocumento: tiposDocumentoEmpresa.find(t => t.label === 'CNPJ'),
    };

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    const handleDialogOpen = () => {
        setArquivoDialogOpen(true);
    }

    const handleDialogClose = () => {
        setArquivoDialogOpen(false);
    }

    const handleDialogSubmit = (values, formikProps, arrayHelpers) => {
        //Object.keys(values).forEach(key => formikProps.setFieldValue(key, values[key]))
        if (values.index !== undefined) {
            Object.keys(values).forEach(key => formikProps.setFieldValue(`seguros[${values.index}].${key}`, values[key]))
        }
        else {
            arrayHelpers.push(values);
        }
        setArquivoDialogOpen(false);
    }

    const handleAdd = (index, formikProps) => {
        handleDialogOpen();
        // const lastPosition = arrayHelpers.form.values.seguros.length;
        // arrayHelpers.form.setFieldTouched(`seguros[${lastPosition}].cpfcnpjresponsavel`, false, false);
        // arrayHelpers.form.setFieldTouched(`seguros[${lastPosition}].nomeseguradora`, false, false);
        // arrayHelpers.form.setFieldTouched(`seguros[${lastPosition}].cnpjseguradora`, false, false);
        // arrayHelpers.form.setFieldTouched(`seguros[${lastPosition}].numeroapolice`, false, false);
        // arrayHelpers.form.setFieldTouched(`seguros[${lastPosition}].numerosdasaverbacoes`, false, false);
        if (index !== undefined) {
            const seguro = fetchFromObject(formikProps.values, `seguros.${index}`);
            const resetData = { ...seguro, index }
            resetForm({values: resetData});
            setDialogFieldTouched('cpfcnpjresponsavel', true, true)
            setDialogFieldTouched('cnpjseguradora', true, true)
        }
        else {
            resetForm({values: initialDialogValues});
            setDialogFieldTouched('cpfcnpjresponsavel', true, true)
            setDialogFieldTouched('cnpjseguradora', true, true)
        }
    }

    // const segurosError = getIn(formik.errors, 'seguros')
    // const segurosTouched = Boolean(getIn(formik.touched, 'seguros'))

    // console.log('segurosError',segurosError)
    // console.log('segurosTouched',segurosTouched)

    return (
        <React.Fragment>
            <div className={classes.divider}>
                <Divider />
            </div>

            <div className={classes.title}>
                <Typography variant="h6" color="inherit">Seguros</Typography>
            </div>

            <FieldArray
                name='seguros'
                render={
                    arrayHelpers => (
                        <React.Fragment>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Responsável
                                                        </TableCell>
                                        <TableCell>
                                            CNPJ da Seguradora
                                                        </TableCell>
                                        <TableCell>
                                            Nome da Seguradora
                                                        </TableCell>
                                        <TableCell>
                                            Número da Apólice
                                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .map((n, index) => (
                                            <TableRow
                                                hover
                                                className={classes.tableContentRow}
                                                tabIndex={-1}
                                                key={index}>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    <ResponsavelField
                                                        disabled
                                                        formik={formik}
                                                        nomeCampo={`seguros.${index}.responsavel`}
                                                        opcoes={tiposResponsavelSeguro} />
                                                </TableCell>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    <DocumentoSeguradoraField
                                                        disabled
                                                        formik={formik}
                                                        index={index}
                                                        nomeCampo={`seguros.${index}.cnpjseguradora`} />
                                                </TableCell>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    <NomeSeguradoraField
                                                        disabled
                                                        formik={formik}
                                                        index={index}
                                                        nomeCampo={`seguros.${index}.nomeseguradora`} />
                                                </TableCell>
                                                <TableCell
                                                    className={classes.hover}
                                                    onClick={() => handleAdd(index, formik)}
                                                    component="th"
                                                    scope="row">
                                                    <NumeroApoliceField
                                                        disabled
                                                        formik={formik}
                                                        nomeCampo={`seguros.${index}.numeroapolice`} />
                                                </TableCell>
                                                <TableCell
                                                    className={classes.removerCell}
                                                    component="th"
                                                    scope="row">
                                                    <IconButton
                                                        disabled={formik.isSubmitting || disabled}
                                                        aria-label="Delete" onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    <SegurosDialog
                                        open={arquivoDialogOpen}
                                        initialValues={initialDialogValues}
                                        handleSubmit={(values) => handleDialogSubmit(values, formik, arrayHelpers)}
                                        handleClose={handleDialogClose}>
                                        {({ form, handleCancel, setFieldTouched }) => (
                                            <React.Fragment>
                                                {resetForm = handleCancel}
                                                {setDialogFieldTouched = setFieldTouched}
                                                {form}
                                            </React.Fragment>
                                        )}
                                    </SegurosDialog>
                                </TableBody>
                            </Table>

                            {!formik.isSubmitting && (
                                <IconButton
                                    disabled={formik.isSubmitting || disabled}
                                    className={classes.buttonAdd}
                                    aria-label="Adicionar"
                                    onClick={() => handleAdd(undefined, formik)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        </React.Fragment>
                    )
                }
            />
        </React.Fragment>
    );
}


export default SegurosPanel;