import { makeVar } from '@apollo/client';

export const auth = makeVar(null);

export const setAuth = (user) => {
    if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
    }
    auth(user);
}


export const authPolicy = {
    auth: {
        read() {
            return auth();
        }
    }
}