import React from 'react';
import { IconButton, TextField, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import { Formik, Form } from 'formik';

import TipoComponenteField from './TipoComponenteField';
import { fetchFromObject } from '../../../../../../../shared/utilities';
import ValorField from './ValorField';
import ComponenteSchemaDados from '../Schema/ComponenteSchemaDados';


const useStyles = makeStyles(theme => ({
    buttonAdd: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(3),
    },
    itemGroup: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginLeft: theme.spacing(1)
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: '100%'
    },
    mensagem: {
        color: 'red',
        textAlign: 'center',
        marginTop: '0px',
        width: '100%'
    }
}))

const ComponenteAddForm = props => {
    const classes = useStyles();

    const { handleSubmit, disabled, initialValues, error, showError } = props
    const valorField = `vcomp`;
    const descricaoField = `xcomp`;
    const tpComponente = `tpcomp`;

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={ ComponenteSchemaDados }>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const isOutros = formikProps.values.tpcomp.enumString === 'OUTROS';

            const form =
                <Form className={classes.form}>
                    <div className={classes.itemGroup}>
                        <TipoComponenteField 
                            formik={formikProps}
                            disabled={disabled}
                            nomeCampo={tpComponente}
                            label={`Tipo do Componente`}
                        />  
                         <ValorField 
                                formik={formikProps}
                                disabled={disabled}
                                nomeCampo={valorField}
                                label={`Valor`}
                        />
                        {!formikProps.isSubmitting && (
                            <IconButton
                                disabled={formikProps.isSubmitting || disabled}
                                className={classes.buttonAdd}
                                aria-label="Adicionar"
                                onClick={() => handleSubmit(formikProps.values)}>
                                <AddIcon />
                            </IconButton>
                        )}
                    </div>
                    {
                        isOutros ? <TextField
                                    className={classes.textField}
                                    id={descricaoField}
                                    value={fetchFromObject(formikProps.values, descricaoField)}
                                    label={`Descrição do Componente`}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    disabled={formikProps.isSubmitting || disabled}
                                    FormHelperTextProps={{ error: true }}
                                /> 
                            : null
                    }
                    {
                        error && showError  ? <FormHelperText
                                                className={classes.mensagem}
                                                error={Boolean(error)}>
                                                    {error && (
                                                        error
                                                    )}
                                            </FormHelperText> 
                            : null
                    }
                </Form>
            return props.children({
                form,
                isDirty: formikProps.dirty,
                isSubmitting: formikProps.isSubmitting,
                setFieldValue: formikProps.setFieldValue,
                setFieldError: formikProps.setFieldError,
                setFieldTouched: formikProps.setFieldTouched,
                handleSubmit: formikProps.handleSubmit,
                handleCancel: formikProps.resetForm,
            });
        }}
    </Formik>
    )
}

export default ComponenteAddForm;