import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';

import Layout from './hoc/Layout/Layout';
import Login from './components/Auth/Login/Login';
import Confirmacao from './components/Auth/Confirmacao/Confirmacao'
import Confirmado from './components/Auth/Confirmado/Confirmado'
import * as actions from './store/actions';
import AlterarSenha from './components/Auth/AlterarSenha/AlterarSenha';
import CustomSnackbar from './components/UI/CustomSnackbar/CustomSnackbar';
import FullWidthSpinner from './components/UI/Spinner/FullWidthSpinner';
import EmitenteCreateUpdate from './containers/Emitente/EmitenteCreateUpdate';
import Mdfe from './containers/Mdfe/Mdfe';
import Veiculos from './containers/Veiculos/Veiculos';
import Condutores from './containers/Condutores/Condutores';
import Usuarios from './containers/Usuarios/Usuarios';
import CondutoresCreateUpdate from './containers/Condutores/CreateUpdate/CondutoresCreateUpdate';
import VeiculosCreateUpdate from './containers/Veiculos/CreateUpdate/VeiculosCreateUpdate';
import MdfeCreateUpdate from './containers/Mdfe/CreateUpdate/MdfeCreateUpdate';
import EscolhaEmpresa from './components/Auth/EscolhaEmpresa/EscolhaEmpresa';
import Reboques from './containers/Reboques/Reboques';
import ReboquesCreateUpdate from './containers/Reboques/CreateUpdate/ReboquesCreateUpdate';
import NaoEncerrados from './containers/Ferramentas/NaoEncerrados/NaoEncerrados';
import EncerrarPorChave from './containers/Ferramentas/EncerrarPorChave/EncerrarPorChave';
import InfoSnackbar from './components/UI/CustomSnackbar/InfoSnackbar';
import { setStartingPage } from './store/cache';

const useStyles = makeStyles({
  app: {
    height: '100%',
    margin: 0,
  },
  layout: {
    height: '100%',
  }
})

function Routes(props) {
  const { loading, error, data} = useQuery(actions.GET_AUTH);
  const classes = useStyles();
  const { currentLocation } = props;

  if (loading)
    return <FullWidthSpinner />;

  if (error)
    return error;

  const {auth} = data;

  if (auth) {
    if (auth.empresaId) {
      return (
        <Layout className={classes.layout}>
          <CustomSnackbar />
          <InfoSnackbar />
          <Switch>
            <Route path='/auth/alterarsenha' component={AlterarSenha} />
            <Route path='/mdfe/alterar/:id?' component={MdfeCreateUpdate} />
            <Route path='/mdfe' component={Mdfe} />
            <Route path='/veiculos/alterar/:id?' component={VeiculosCreateUpdate} />
            <Route path='/veiculos' component={Veiculos} />
            <Route path='/reboques/alterar/:id?' component={ReboquesCreateUpdate} />
            <Route path='/reboques' component={Reboques} />
            <Route path='/condutores/alterar/:id?' component={CondutoresCreateUpdate} />
            <Route path='/condutores' component={Condutores} />
            <Route path='/emitente' component={EmitenteCreateUpdate} />
            <Route path='/usuarios' component={Usuarios} />
            <Route path='/ferramentas/consulta-nao-encerrados' component={NaoEncerrados} />
            <Route path='/ferramentas/encerramento-por-chave' component={EncerrarPorChave} />
            <Redirect to={defaultLoggedPage} />
          </Switch>
        </Layout >
      );
    }
    else {
      return <React.Fragment>
        <CustomSnackbar />
        <EscolhaEmpresa />
      </React.Fragment>
    }
  }
  else {
    return (
      <React.Fragment>
        <CustomSnackbar />
        <Switch>
          <Route path='/auth/login' component={Login} />
          <Route path='/auth/registro' component={Confirmacao} />
          <Route path='/auth/confirmado' component={Confirmado} />
          <Redirect to={{
            pathname: '/auth/login',
            state: { from: currentLocation }
          }} />
        </Switch>
      </React.Fragment>
    );
  }
}

const defaultLoggedPage = '/mdfe';

export function UnroutedApp() {
  const location = useLocation();
  useEffect(() => {
    const currentLocation = location.pathname;
    actions.CHECK_AUTH(currentLocation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const { loading, error, data } = useQuery(actions.GET_STARTING_PAGE);

  if (loading)
    return <FullWidthSpinner />;

  if (error)
    return error;

  let { startingPage } = data;

  const currentLocation = location.pathname;

  if (startingPage) {
    setStartingPage(false);
    if (startingPage === '/'){
      startingPage = defaultLoggedPage;
    }
    else if (startingPage === '/auth/login'){
      startingPage = defaultLoggedPage;
    }      
    if (startingPage !== currentLocation){
      return <Redirect to={startingPage} />
    }
  }

  return (
    <div data-test='component-app' className={classes.app}>
      <Routes currentLocation={currentLocation} />
    </div>
  );
}

export default UnroutedApp;