import React from 'react';
import { FormControl, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TipoPagamentoField from './TipoPagamentoField';
import ValorField from './ValorField';

const useStyles = makeStyles(theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: '4px'
    },
    panel: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const InformacaoPagamentoField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;
    
    return(
        <React.Fragment>
            <Paper className={classes.panel}>
                <Typography className={classes.title} variant="subtitle2" color="inherit">Informações do Pagamento</Typography>
                <FormControl className={classes.formControl}>
                    <TipoPagamentoField 
                            formik={formik}
                            disabled={disabled} 
                            nomeCampo={`indpag`}
                            label={`Forma de Pagamento`}
                        />
                        <ValorField 
                            formik={formik}
                            disabled={disabled}
                            nomeCampo={`vcontrato`}
                            label={`Valor do Contrato`}
                        />
                </FormControl>
            </Paper>
        </React.Fragment>
    );

}

export default InformacaoPagamentoField;