import gql from 'graphql-tag';

export const GET_REBOQUES = gql`
query reboques($busca: String, $offset: Int, $limit: Int, $inativo: Boolean) {
  reboques (busca: $busca, offset: $offset, limit: $limit, inativo: $inativo) {
      node {
        ... on ReboqueType {
            id
            placa
            carroceria
            uf
            inativo
            podeexcluir
        }
      }
      totalcount
  }
}
`;

export const GET_REBOQUE = gql`
query reboque($id: Int){
    reboque(reboque: { id: $id }){
        id
        capacidadekg
        capacidadecubica
        renavam
        carroceria
        placa
        proprietario {
            id
            cpfcnpj
            id
            nome
            rgie
            rntrc
            tipoproprietario
            uf
        }
        tara
        uf
        inativo
    }
}
`;

export const SET_REBOQUE = gql`
mutation adicionarOuAtualizarReboque($reboque: ReboqueInput!) {
    adicionarOuAtualizarReboque(reboque: $reboque) {
        id
        placa
    }
  }
`;

export const EXCLUIR_REBOQUE = gql`
mutation excluirReboque($id: Int!){
    excluirReboque(id: $id)
}
`;