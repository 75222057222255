import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Toolbar, Typography, Tooltip, IconButton, Input, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

import * as cores from '../../../shared/cores';

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.primary.main, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'inline-flex'
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo
    },
    input: {
        minWidth: 160,
        padding: '6px 0 0px'
    }
}));


const CommonTableToolbar = props => {
    const classes = useStyles();
    const { noSearch, title, filterDialog, formikProps, hideSearchInput, onSearchButtonClick } = props;

    const filter = filterDialog
        ? (<Fragment>
            <Tooltip title="Filtrar lista">
                <IconButton aria-label="Filtrar lista" className={classes.actionButton}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            {filterDialog}
        </Fragment>)
        : null;

    return (
        <Toolbar
            className={classNames(classes.root)}>

            <div className={classes.title}>
                <Hidden smUp implementation="css">
                    <Typography color="inherit" variant="subtitle1" id="tableTitle">
                        {title}
                    </Typography>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Typography color="inherit" variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                </Hidden>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {!(hideSearchInput || noSearch) ?
                    <form onSubmit={formikProps.handleSubmit}>
                        <Input
                            id="busca"
                            name="busca"
                            placeholder="Digite aqui"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.busca}
                            className={classes.input}
                            inputProps={{
                                'aria-label': 'Busca',
                            }}
                        />
                    </form>
                    : null
                }
                {!noSearch ?
                    <Tooltip title="Buscar">
                        <IconButton
                            onClick={onSearchButtonClick}
                            aria-label="Buscar"
                            className={classes.actionButton}>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip> : null}
                {filter}
            </div>
        </Toolbar>
    );
}

CommonTableToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    filterDialog: PropTypes.element
};

export default CommonTableToolbar;