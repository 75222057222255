import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import { SUPPORTED_CERTIFICATE_FORMATS } from '../../../shared/mdfe';
import { getBase64 } from '../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
    }
}));

/**
 * Função para setar o valor no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
async function handleChange(event, formik, campo) {
    if (event.target.files[0]) {
        try {
            formik.setFieldValue(campo, event.target.files[0]);
            formik.setFieldValue(campo + 'Name', event.value);
            const arquivo = await getBase64(event.target.files[0])
            formik.setFieldValue(campo + 'base64', arquivo);
            //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
            formik.validateForm().then(() => formik.validateForm())
        }
        catch (error) {
            console.log('Erro na conversão do arquivo', error)
        }
    }
    else {
        formik.setFieldValue(campo, null);
        formik.setFieldValue(campo + 'Name', null);
        formik.setFieldValue(campo + 'base64', null);
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    }
}

const ArquivoField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;

    const nomeCampo = 'arquivo';
    const label = 'Arquivo'

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id={nomeCampo}
                label={label}
                inputProps={{
                    accept: SUPPORTED_CERTIFICATE_FORMATS.join(','),
                    multiple: false,
                }}
                type='file'
                value={formik.values[nomeCampo + 'Name']}
                error={Boolean(touched && error)}
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={touched && error && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />

        </React.Fragment>
    )
}

export default ArquivoField;
