import React from 'react';
import {  Paper, Divider,  List, IconButton, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';

import ComponenteAddForm from './ComponenteAddForm';
import { tipoComponente } from '../../../../../../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    
    buttonAdd: {
        margin: theme.spacing(1)
    },
    panel: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    formControl: {
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    list: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: '65px',
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    listItem: {
        width: '250px'
    },
    listDisabled: {
        position: 'relative',
        overflow: 'auto',
        color: theme.palette.text.disabled,
        maxHeight: '65px',
        paddingTop: '0px',
        paddingBottom: '0px'
    },
}));

const ComponenteField = (props) =>{
    const classes = useStyles();
    const { formik, disabled } = props;
    let items = formik.values.comp;
    
    let resetForm = null;
    const initialValues = {
        vcomp: '',
        xcomp: '',
        tpcomp: tipoComponente[0]
    }

    const handleFormAdd = (values, arrayHelpers, formik) => {
        arrayHelpers.push(values);
        resetForm({
            values: {
                tpcomp: values.tpcomp,
                vcomp: initialValues.vcomp,
                xcomp: initialValues.xcomp
            }
        })
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    }

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
    };

    return(
        <React.Fragment>
            <FieldArray 
                name= 'comp'
                render={
                    arrayHelpers =>(
                            <Paper className={classes.panel}>
                                <div>
                                    <Typography className={classes.title} variant="subtitle2" color="inherit">Informações do Componente</Typography>
                                    <ComponenteAddForm
                                        initialValues={initialValues}
                                        handleSubmit={(values) => handleFormAdd(values, arrayHelpers, formik)}
                                        error={formik.errors.comp }
                                        showError ={formik.submitCount !== 0}
                                        disabled={formik.isSubmitting || disabled}>
                                        {({ form, handleCancel }) => (
                                            <React.Fragment>
                                                {resetForm = handleCancel}
                                                {form}
                                            </React.Fragment>
                                        )}
                                    </ComponenteAddForm>
                                </div>
                                <Divider />
                                <List className={!disabled ? classes.list : classes.listDisabled}>
                                    {items && items.map((item, index) => (
                                        <ListItem key={index}>
                                            <ListItemText className={classes.listItem} primary={item.tpcomp.label} />
                                            <ListItemText primary={item.vcomp} />
                                                {!formik.isSubmitting && (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        disabled={formik.isSubmitting || disabled}
                                                        aria-label="Delete"
                                                        onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                                )}
                                        </ListItem>
                                    ))}
                                </List>   
                            </Paper>
                    )
                }
            />
            
        </React.Fragment>
    );

}

export default ComponenteField;