import React, { Fragment } from 'react';
import { AppBar, Toolbar, Typography, Button, makeStyles, IconButton } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import MenuIcon from '@material-ui/icons/Menu';
import * as actions from '../../store/actions';
import apolloClientProvider from '../../apolloClientProvider';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import FullWidthSpinner from '../../components/UI/Spinner/FullWidthSpinner';
import { ErrorPage } from '../../components/ErrorBoundary/ErrorBoundary';
import { setAuth } from '../../store/cache';

const useStyles = makeStyles(theme => ({
    Content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 230,
        },
    },
    AppBar: {
        backgroundColor: '#2a3f53ff',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    Toolbar: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    appBar__zmdfe: {
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            fontSize: theme.typography.button.fontSize,
            width: 66,
        },
    },
    appBar__text: {
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    menuButton: {
        marginRight: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    botaoTrocarEmitente: {
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0),
        },
    },
}));

const Layout = (props) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    const { loading, error, data } = useQuery(actions.GET_AUTH);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClick = () => {
        apolloClientProvider.client
            .query({ query: actions.GET_AUTH })
            .then(({ data }) => {
                setAuth({...data.auth, empresaId: null, empresaNome: null})
                actions.SET_EMPRESA_HEADER('');
            });
    }

    let nomeEmpresa;
    if (loading)
        nomeEmpresa = <FullWidthSpinner />

    if (error)
        return <ErrorPage />

    const { auth } = data;
    if (auth) {
        nomeEmpresa = <Typography variant="button" color="inherit" className={classes.appBar__text}>
            {auth.empresaNome}
        </Typography>
    }

    return <Fragment>
        <SideDrawer
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen} />
        <main className={classes.Content}>
            <AppBar className={classes.AppBar} position="static">
                <Toolbar className={classes.Toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.appBar__zmdfe}>
                        ZMDF-e
                        </Typography>
                    {nomeEmpresa}
                    <Button onClick={handleClick} className={classes.botaoTrocarEmitente} color="inherit">Trocar emitente</Button>
                </Toolbar>
            </AppBar>
            {props.children}
        </main>
    </Fragment>
};

export default Layout;