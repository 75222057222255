import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { makeStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        minWidth: 250,
    }
}));

/**
 * Função para setar o valor do Data e Hora no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik, campo) => {
    formik.setFieldValue(campo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik, campo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(campo, true, true);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
};

const DataHoraInicioViagemField = (props) => {
    const classes = useStyles();
    const { formik, disabled, campo } = props;

    const value = formik.values.dvenc !== undefined 
            ? formik.values.dvenc 
            : null;
            
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDatePicker
                name={campo}
                id={campo}
                value={value}
                onChange={(event) => handleChange(event, formik, campo)}
                onBlur={() => handleBlur(formik, campo)}
                ampm={false}
                autoOk
                clearable
                required
                format="DD/MM/YYYY"
                cancelLabel='Cancelar'
                clearLabel='Limpar'
                invalidDateMessage='Data inválida'
                className={classes.textField}
                label="Vencimento da Parcela"
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                FormHelperTextProps={{ error: true }}
                helperText={formik.errors.dvenc &&
                     ( formik.errors.dvenc )}
                error={Boolean( formik.errors.dvenc )}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DataHoraInicioViagemField;
