import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 200,
    }
}));

const moedaRealMask = createNumberMask({
    prefix: 'R$',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 13,
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
});

function ValorMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={moedaRealMask}
            guide={false}

        />
    );
}
ValorMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const ValorField = (props) => {
    const classes = useStyles();
    
    const { formik, disabled } = props;

    const error = getIn(formik.errors, `valor`);
    const touched = getIn(formik.touched, `valor`);

    return (
        <React.Fragment>
            <TextField
                InputProps={{
                    inputComponent: ValorMask,
                }}
                className={classes.textField}
                id="valor"
                label="Valor"
                required
                value={formik.values.valor}
                error={Boolean(
                    touched && error
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={touched && error && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />
        </React.Fragment>
    )
}

export default ValorField;
