import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: '100%'
    }
}));

const ResponsavelField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, label } = props;

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                margin="normal"
                FormHelperTextProps={{error: true}}
            />
        </React.Fragment>
    )
}

export default ResponsavelField;
