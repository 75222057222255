import React from 'react';
import { Paper, List, IconButton, ListItem, ListItemText, ListItemSecondaryAction, Typography, Divider } from '@material-ui/core';
import { FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import InformacaoPrazoAddForm from './InformacaoPrazoAddForm';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    buttonAdd: {
        margin: theme.spacing(1)
    },
    list: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: '65px',
        paddingTop: '0px',
        paddingBottom: '0px' 
    },
    listDisabled: {
        position: 'relative',
        overflow: 'auto',
        color: theme.palette.text.disabled,
        maxHeight: '65px',
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    panel: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

const InformacaoPrazoField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;
    let items = formik.values.infPrazo;
    
    let resetForm = null;
    const initialValues = {
        vparcela: '',
        dvenci: null
    }

    const handleFormAdd = (values, arrayHelpers, formik) => {
        arrayHelpers.push(values);
        resetForm({
            values: {
                ...initialValues
            }
        })
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    }

    const onDeleteHandler = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
    };

    const convertDate = (date) => {
        const data = date.split('-');
        return `${data[2]}/${data[1]}/${data[0]}`;
    }

    return(
        <React.Fragment>
            <FieldArray 
                name = 'infPrazo'
                render ={
                    arrayHelpers => (
                        <Paper className={classes.panel}>
                            <Typography className={classes.title} variant="subtitle2" color="inherit">Parcelas</Typography>
                            <InformacaoPrazoAddForm
                                initialValues={initialValues}
                                error={formik.errors.infPrazo }
                                showError={formik.submitCount !== 0}
                                handleSubmit={(values) => handleFormAdd(values, arrayHelpers, formik)}
                                disabled={formik.isSubmitting || disabled}>
                                    {({ form, handleCancel }) => (
                                        <React.Fragment>
                                            {resetForm = handleCancel}
                                            {form}
                                        </React.Fragment>
                                    )}
                            </InformacaoPrazoAddForm>
                            <Divider />
                            <List className={!disabled ? classes.list : classes.listDisabled}>
                                {items && items.map((item, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={ typeof(item.dvenc) === 'string' ? 
                                            convertDate(item.dvenc): item.dvenc.format('DD/MM/YYYY') } />
                                        <ListItemText primary={item.vparcela} />
                                        {!formik.isSubmitting && (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        disabled={formik.isSubmitting || disabled}
                                                        aria-label="Delete"
                                                        onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                ))}
                            </List>  
                        </Paper>
                    )
                }
            />
        </React.Fragment>
    );

}

export default InformacaoPrazoField;