import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: 'auto',
        width: 550,
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    alterarButton: {
        display: 'flex',
        margin: 'auto',
        marginTop: 25,
    }
}));

const alterarSenhaSchema = Yup.object().shape({
    OldPassword: Yup.string()
        .required("Requerido")
        .min(6, "Mínimo de 6 caracteres"),
    NewPassword: Yup.string()
        .required('Requerido')
        .min(6, "Mínimo de 6 caracteres"),
    ConfirmPassword: Yup.string()
        .required('Requerido')
        .oneOf([Yup.ref('NewPassword')], 'A senha e confirmação devem ser iguais')
})

const AlterarSenhaForm = (props) => {
    const classes = useStyles();

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={alterarSenhaSchema}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return props.onSubmit(values, addHandlers);
            }}>
            {
                ({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    dirty,
                    resetForm,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                }) => {
                    const form = <Form>
                        <TextField
                            className={classes.textField}
                            type="password"
                            required
                            id="OldPassword"
                            label="Senha atual"
                            autoComplete="senha-atual"
                            value={values.OldPassword}
                            error={Boolean(
                                touched.OldPassword && errors.OldPassword
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            margin="normal"
                            helperText={errors.OldPassword &&
                                touched.OldPassword && (
                                    errors.OldPassword
                                )}
                            FormHelperTextProps={{ error: true }}
                        />
                        <TextField
                            className={classes.textField}
                            type="password"
                            required
                            id="NewPassword"
                            label="Nova senha"
                            autoComplete="nova-senha"
                            value={values.NewPassword}
                            error={Boolean(
                                touched.NewPassword && errors.NewPassword
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            margin="normal"
                            helperText={errors.NewPassword &&
                                touched.NewPassword && (
                                    errors.NewPassword
                                )}
                            FormHelperTextProps={{ error: true }}
                        />
                        <TextField
                            className={classes.textField}
                            type="password"
                            required
                            id="ConfirmPassword"
                            label="Confirme a nova senha"
                            autoComplete="senha-confirmacao"
                            value={values.ConfirmPassword}
                            error={Boolean(
                                touched.ConfirmPassword && errors.ConfirmPassword
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            margin="normal"
                            helperText={errors.ConfirmPassword &&
                                touched.ConfirmPassword && (
                                    errors.ConfirmPassword
                                )}
                            FormHelperTextProps={{ error: true }}
                        />
                        <Button
                            className={classes.alterarButton}
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit">
                            Alterar senha
                    </Button>
                    </Form>;
                    return props.children({
                        form,
                        isDirty: dirty,
                        isSubmitting: isSubmitting,
                        handleSubmit: handleSubmit,
                        handleCancel: resetForm,
                    });
                }}
        </Formik>
    )
};

export default AlterarSenhaForm;