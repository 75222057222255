import React from 'react';
import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 105,
    },
    endAdornmentButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'unset',
        }
    }
}));

const NumeroField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id="numero"
                label="Número"
                type='number'
                value={formik.values.numero || ''}
                error={Boolean(
                    formik.touched.numero && formik.errors.numero
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={formik.errors.numero &&
                    formik.touched.numero && (
                        formik.errors.numero
                    )}
                FormHelperTextProps={{ error: true }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title="Manter vazio para numeração automática" aria-label="Manter vazio para numeração automática">
                                <IconButton className={classes.endAdornmentButton}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                }}
            />

        </React.Fragment>
    )
}

export default NumeroField;
