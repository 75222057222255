import React from 'react';
import { FormControl, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TipoDocumentoField from './TipoDocumentoField';
import DocumentoField from './DocumentoField';
import ResponsavelField from './ResponsavelField';


const useStyles = makeStyles(theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: '4px'
    },
    panel: {
        margin: theme.spacing(1)
    }
}));

const InformacaoResponsavelField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;
    
    return(
        <React.Fragment>
            <Paper className={classes.panel}>
                <FormControl className={classes.formControl}>
                    <TipoDocumentoField 
                        formik={formik}
                        disabled={disabled}
                        nomeCampo={'tpdocumento'}
                        label={`Tipo do Documento`}
                    />
                    <DocumentoField 
                        formik={formik}
                        disabled={disabled}
                        tipoPessoa={formik.initialValues.tpdocumento.value}
                        nomeCampo={`cpfCnpjId`}
                        label={`Documento do Responsável`}
                    />
                    <ResponsavelField 
                        formik={formik}
                        disabled={disabled}
                        nomeCampo={`xnome`}
                        label={`Nome do Responsável`}
                    />
                </FormControl>
            </Paper>
        </React.Fragment>
    );

}

export default InformacaoResponsavelField;