import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    withStyles
} from "@material-ui/core";
import {
    SortableHandle,
    SortableContainer,
    SortableElement
} from "react-sortable-hoc";
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const styles = theme => ({
    listItem: {
        listStyleType: 'none'
    },
    listItemDisabled: {
        listStyleType: 'none',
        color: theme.palette.text.disabled
    }
})

const SortableItem = withStyles(styles)(SortableElement(({ sortIndex, value, handleDelete, classes, isDisabled }) => {
    return (
        <ListItem ContainerProps={{ className: !isDisabled ? classes.listItem : classes.listItemDisabled }}>
            <ListItemIcon style={{ color: 'inherit' }}>
                <DragHandle />
            </ListItemIcon>
            <ListItemText primary={value} />
            {!isDisabled &&
                (<ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Delete" onClick={() => handleDelete(sortIndex)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>)}
        </ListItem>
    );
}));

const SortableList = SortableContainer(({ items, onDeleteHandler, listContainerClasses, disabled }) => {
    return (
        <List component="nav" className={listContainerClasses} style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit' }}>
            {items && items.map((value, index) => (
                <SortableItem
                    disabled={disabled}
                    isDisabled={disabled}
                    key={`item-${index}`}
                    handleDelete={onDeleteHandler}
                    index={index}
                    sortIndex={index}
                    value={value.label}
                />
            ))}
        </List>
    );
});

export default SortableList;