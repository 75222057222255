import React, { Component } from "react";
import { Formik, Form, yupToFormErrors, validateYupSchema, isFunction } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withStyles, IconButton, Typography, Stepper, Step, StepButton, StepLabel, Fab, Divider, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

import * as cores from '../../../../shared/cores'
import ModeloField from "./Fields/ModeloField";
import SerieField from "./Fields/SerieField";
import NumeroField from "./Fields/NumeroField";
import DataHoraEmissaoField from "./Fields/DataHoraEmissaoField";
import DataHoraInicioViagem from "./Fields/DataHoraInicioViagem";
import ModalField from "./Fields/ModalField";
import VeiculoField from "./Fields/VeiculoField";
import UfPersursoPanel from "./Panels/UfPersursoPanel";
import MunicipiosCarregamentoPanel from "./Panels/MunicipiosCarregamentoPanel";
import MDFeSchemaDados from "./Schemas/MDFeSchemaDados";
import ReboquesPanel from "./Panels/ReboquesPanel";
import CondutoresPanel from "./Panels/CondutoresPanel";
import MDFeSchemaDocumentos from "./Schemas/MDFeSchemaDocumentos";
import DocumentosPanel from "./Panels/Documentos/DocumentosPanel";
import ValorField from "./Panels/Fields/ValorField";
import MDFeSchemaTotalizadores from "./Schemas/MDFeSchemaTotalizadores";
import UnidadeField from "./Panels/Fields/UnidadeField";
import PesoBrutoField from "./Panels/Fields/PesoBrutoField";
import InformacoesFisco from "./Panels/Fields/InformacoesFisco";
import InformacoesContribuinte from "./Panels/Fields/InformacoesContribuinte";
import ChaveField from "./Fields/ChaveField";
import UfInicio from "./Fields/UfInicio";
import TipoEmitenteField from "./Fields/TipoEmitenteField";
import SegurosPanel from "./Panels/Seguros/SegurosPanel";
import ContratantesPanel from "./Panels/Contratantes/ContratantesPanel";
import CiotsPanel from "./Panels/Ciots/CiotsPanel";
import ProdutoPredominantePanel from "./Panels/ProdutoPredominante/ProdutoPredominantePanel";
import PagamentoInfPanel from './Panels/PagamentoFrete/PagamentoInfPanel';


const styles = theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    buttonConfirmContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        margin: '23px 20px',
    },
    buttonClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stepper: {
        width: '100%',
        padding: 16,
    },
    fieldsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    tablesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    panelsContainer: {
        margin: theme.spacing(1),
    },
    bottomRowContainer: {
        margin: theme.spacing(2),
    },
    condutoresPanelContainer: {
        paddingBottom: 1
    },
    // verticalPanelsContainer: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     flexDirection: 'column',
    // },
    flexDirectionRow: {
        flexDirection: 'row'
    },
    fullWidthDivider: {
        width: '100%',
        paddingBottom: theme.spacing(3),
    },
    gridContainer: {
        width: '100%',
        marginTop: theme.spacing(1)
    }
});

const pages = (formikProps = null, classes = {}) => {
    const editavel = formikProps && formikProps.values.editavel;
    const showExtraDocumentsPanels = formikProps !== null && formikProps.values.tipoEmitente &&
        (formikProps.values.tipoEmitente.enumString !== 'TRANSPORTADOR_CARGA_PROPRIA');

    const showInfPagamentoPanel = formikProps !== null && formikProps.values.mdfeInfPag !== null
        && formikProps.values.mdfeInfPag.length !== 0;
    return [
        {
            label: 'Dados',
            page: <React.Fragment>
                <div className={classes.fieldsContainer}>
                    <ModalField formik={formikProps} disabled />
                    <ModeloField formik={formikProps} disabled />
                    <TipoEmitenteField formik={formikProps} disabled={!editavel} />
                    <SerieField formik={formikProps} disabled={!editavel} />
                    <NumeroField formik={formikProps} disabled={!editavel} />
                    <DataHoraEmissaoField formik={formikProps} disabled={!editavel} />
                    <DataHoraInicioViagem formik={formikProps} disabled={!editavel} />
                    <UfInicio formik={formikProps} disabled={!editavel} />
                    <VeiculoField formik={formikProps} disabled={!editavel} />
                    <ChaveField formik={formikProps} disabled />
                </div>
                <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item xs>
                        <MunicipiosCarregamentoPanel formik={formikProps} disabled={!editavel} showExtraDocumentsPanels={(Boolean(showExtraDocumentsPanels))} />
                        <ProdutoPredominantePanel formik={formikProps} disabled={!editavel} />
                    </Grid>
                    <Grid item xs={3}>
                        <UfPersursoPanel formik={formikProps} disabled={!editavel} />
                    </Grid>
                    <Grid container item direction="column" spacing={1} xs>
                        <Grid item xs>
                            <ReboquesPanel formik={formikProps} disabled={!editavel} />
                        </Grid>
                        <Grid item xs className={classes.condutoresPanelContainer}>
                            <CondutoresPanel formik={formikProps} disabled={!editavel} />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>,
            schema: MDFeSchemaDados,
        },
        {
            label: 'Documentos',
            page: <React.Fragment>
                <DocumentosPanel formik={formikProps} disabled={!editavel} />
                <div className={classes.fullWidthDivider}><Divider /></div>
                {<ContratantesPanel formik={formikProps} disabled={!editavel} />}
                {<CiotsPanel formik={formikProps} disabled={!editavel} />}
            </React.Fragment>,
            schema: MDFeSchemaDocumentos
        },
        {
            label: 'Totalizadores e Seguros',
            page: <React.Fragment>
                <div className={classes.fieldsContainer}>
                    <ValorField formik={formikProps} disabled={!editavel} />
                    <PesoBrutoField formik={formikProps} disabled={!editavel} />
                    <UnidadeField formik={formikProps} disabled={!editavel} />
                    <InformacoesFisco formik={formikProps} disabled={!editavel} />
                    <InformacoesContribuinte formik={formikProps} disabled={!editavel} />
                </div>
                <SegurosPanel formik={formikProps} disabled={!editavel} />
                {showInfPagamentoPanel ? <PagamentoInfPanel formik={formikProps} disabled={!editavel} /> : null}
            </React.Fragment>
            ,
            schema: MDFeSchemaTotalizadores
        },
    ]
};

export class MDFeForm extends Component {
    state = {
        page: 0,
        completed: [],
        errors: {},
        values: this.props.initialValues,
        selectedMunicipioDescarregamento: { value: null, label: null }
    };

    totalPages = () => pages().length;

    isLastPage = () => this.state.page === this.totalPages() - 1;

    isLastStep = () => this.completedSteps() >= (this.totalPages() - 1);

    handleNext = (values) => {
        let page;

        if (this.isLastPage() && !this.allStepsCompleted()) {
            // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            const steps = pages();
            page = steps.findIndex((step, i) => !(i in this.state.completed));
        } else {
            page = Math.min(this.state.page + 1, this.totalPages() - 1);
        }
        this.setState({
            page,
            values,
        });
    };

    handleBack = () => {
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
        }));
    };

    handleStep = (step, values) => () => {
        this.validate(values)
            //.then(() => this.handleCompleted(this.state.page))
            .catch((err) => {
                this.handleErrors(this.state.page);
                throw err;
            })
            .finally(() => this.setState(
                { page: step, },
                () => this.validate(values))
            )
    };

    completedSteps() {
        return this.state.completed.reduce((acc, val) => val && acc + 1, 0);
    }

    allStepsCompleted() {
        return this.completedSteps() === this.totalPages();
    }

    handleCompleted(page) {
        const { completed, errors } = this.state;
        completed[page] = true;
        errors[page] = false;
        this.setState({
            completed,
            errors
        });
    }

    handleErrors(page) {
        const { completed, errors } = this.state;
        completed[page] = false;
        errors[page] = true;
        this.setState({
            completed,
            errors,
        });
    }

    handleReset = () => {
        this.setState({
            page: 0,
            completed: [],
            errors: {},
            values: this.props.initialValues,
        });
    };

    //     /**
    //    * Run validation against a Yup schema and optionally run a function if successful
    //    */
    //   runValidationSchema = (values: FormikValues) => {
    //     return new Promise(resolve => {
    //       const { validationSchema } = this.props;
    //       const schema = isFunction(validationSchema)
    //         ? validationSchema()
    //         : validationSchema;
    //       validateYupSchema(values, schema).then(
    //         () => {
    //           resolve({});
    //         },
    //         (err: any) => {
    //           resolve(yupToFormErrors(err));
    //         }
    //       );
    //     });
    //   };

    validateFromSchema = (validationSchema, values) => {
        return new Promise((resolve, reject) => {
            const schema = isFunction(validationSchema)
                ? validationSchema()
                : validationSchema
            validateYupSchema(values, schema).then(
                () => {
                    resolve({});
                },
                (err) => {
                    reject(yupToFormErrors(err));
                }
            );
        });
    }

    validate = (values, validateAll = false) => {
        let schema;
        if (this.isLastPage() || validateAll) {
            const schemas = pages().map(p => p.schema);
            schema = schemas.reduce((result, current) => result.concat(current), Yup.object())
        }
        else {
            schema = pages()[this.state.page].schema;
        }
        return this.validateFromSchema(schema, values)
            .then((data) => {
                return this.handleCompleted(this.state.page)
            })
            .catch((err) => {
                this.handleErrors(this.state.page)
                return err;
            })
    };

    render() {
        const { classes } = this.props;
        const { page } = this.state;

        const activePage = (formikProps) => pages(formikProps, classes)[page].page;

        const steps = pages().map(p => p.label);

        return (
            <Formik
                initialValues={this.props.initialValues}
                enableReinitialize={true}
                validate={this.validate}
                onSubmit={(values, formikBag) => {
                    const addHandlers = promise =>
                        promise.then(
                            result => {
                                formikBag.resetForm();
                                formikBag.setSubmitting(false);

                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        );

                    if (this.isLastStep()) {
                        return this.props.onSubmit(values, addHandlers);
                    }
                }}>
                {formikProps => {
                    const editavel = formikProps.values.editavel;
                    const form = (
                        <React.Fragment>
                            <div className={classes.title}>
                                <Typography color="inherit" variant="h6">
                                    Cadastro de MDF-e
                                    </Typography>
                                <IconButton
                                    className={classes.buttonClose}
                                    aria-label="Cancelar"
                                    onClick={this.props.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Form className={classes.form}>
                                <div className={classes.fieldsContainer}>
                                    <Stepper
                                        nonLinear
                                        activeStep={page}
                                        className={classes.stepper}>
                                        {steps.map((label, index) => (
                                            <Step key={label}>
                                                <StepButton
                                                    onClick={this.handleStep(index, formikProps.values)}
                                                    completed={this.state.completed[index]}>
                                                    <StepLabel
                                                        error={this.state.errors[index]}>
                                                        {label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        )
                                        )}
                                    </Stepper>
                                    <div className={classes.fieldsContainer}>
                                        {activePage(formikProps, classes)}
                                    </div>
                                </div>

                                <Grid container>
                                    <Grid item container xs
                                        className={classes.bottomRowContainer}
                                        direction={this.isLastPage() && !(this.isLastStep() && this.isLastPage() && editavel) ? "row" : "row-reverse"}
                                        justify="space-between">
                                        {this.isLastStep() && this.isLastPage() && editavel && (
                                            <Fab
                                                type="submit"
                                                color="primary"
                                                aria-label="Enviar">
                                                <CheckIcon />
                                            </Fab>
                                        )}
                                        {!this.isLastPage() && (
                                            <Fab
                                                onClick={this.handleStep(this.state.page + 1, formikProps.values)}
                                                color="primary"
                                                aria-label="Próximo">
                                                <NavigateNextIcon />
                                            </Fab>
                                        )}
                                        {page > 0 && (
                                            <Fab
                                                className="navigate-before"
                                                onClick={this.handleStep(this.state.page - 1, formikProps.values)}
                                                color="primary"
                                                aria-label="Anterior">
                                                <NavigateBeforeIcon />
                                            </Fab>
                                        )}
                                    </Grid>
                                </Grid>
                                {/* <div
                                        style={{
                                            ...styles(theme).buttonConfirmContainer,
                                            ...(this.isLastPage() && !(this.isLastStep() && this.isLastPage() && editavel) ? styles(theme).flexDirectionRow : {})
                                        }}>
                                        {this.isLastStep() && this.isLastPage() && editavel && (
                                            <Fab
                                                type="submit"
                                                color="primary"
                                                aria-label="Enviar">
                                                <CheckIcon />
                                            </Fab>
                                        )}
                                        {!this.isLastPage() && (
                                            <Fab
                                                onClick={this.handleStep(this.state.page + 1, formikProps.values)}
                                                color="primary"
                                                aria-label="Próximo">
                                                <NavigateNextIcon />
                                            </Fab>
                                        )}
                                        {page > 0 && (
                                            <Fab
                                                className="navigate-before"
                                                onClick={this.handleStep(this.state.page - 1, formikProps.values)}
                                                color="primary"
                                                aria-label="Anterior">
                                                <NavigateBeforeIcon />
                                            </Fab>
                                        )}
                                    </div> */}
                            </Form>
                        </React.Fragment>
                    );
                    return this.props.children({
                        form,
                        isDirty: formikProps.dirty,
                        isSubmitting: formikProps.isSubmitting,
                        setFieldValue: formikProps.setFieldValue,
                        setFieldError: formikProps.setFieldError,
                        setFieldTouched: formikProps.setFieldTouched,
                        handleSubmit: formikProps.handleSubmit,
                        handleCancel: formikProps.resetForm,
                    });

                }
                }
            </Formik>
        );
    }
}

MDFeForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default withStyles(styles)(MDFeForm);