import React from "react";
import { Formik, Form } from "formik";

import PropTypes from 'prop-types';
import { IconButton, Typography, Fab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import * as cores from '../../../../shared/cores';
import UfField from './Fields/UfField';
import MunicipioField from './Fields/MunicipioField';
import ChaveField from './Fields/ChaveField';
import EncerrarPorChaveDados from "./Schemas/EncerrarPorChaveSchema";

const useStyles = makeStyles(theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    buttonConfirmContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        margin: '23px 20px',
    },
    buttonClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stepper: {
        width: '100%',
        padding: 16,
    },
    fieldsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    }
}));

const EncerrarPorChaveForm = (props) => {
    const classes = useStyles()

    return (
        <Formik
            initialValues={props.initialValues}
            enableReinitialize={true}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return props.onSubmit(values, addHandlers);
            }}
            validationSchema={EncerrarPorChaveDados}>
            {formikProps => {
                const form = (
                    <React.Fragment>
                        <div className={classes.title}>
                            <Typography color="inherit" variant="h6">
                                Encerramento por chave</Typography>
                            <IconButton
                                className={classes.buttonClose}
                                aria-label="Cancelar"
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Form className={classes.form}>
                            <div className={classes.fieldsContainer}>
                                <ChaveField formik={formikProps} />
                                <UfField formik={formikProps} />
                                <MunicipioField formik={formikProps} ufId={formikProps.values.uf.value} />
                            </div>
                            <div className={classes.buttonConfirmContainer}>
                                <Fab
                                    type="submit"
                                    color="primary"
                                    aria-label="Adicionar">
                                    <CheckIcon />
                                </Fab>
                            </div>
                        </Form>
                    </React.Fragment>
                );
                return props.children({
                    form,
                    isDirty: formikProps.dirty,
                    isSubmitting: formikProps.isSubmitting,
                    setFieldValue: formikProps.setFieldValue,
                    setFieldError: formikProps.setFieldError,
                    setFieldTouched: formikProps.setFieldTouched,
                    handleSubmit: formikProps.handleSubmit,
                    handleCancel: formikProps.resetForm,
                });

            }
            }
        </Formik>
    );
}

EncerrarPorChaveForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default EncerrarPorChaveForm;

