import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import VeiculosForm from './Form/VeiculosForm';
import { listaUF, somenteNumeros, padNumber } from '../../../shared/utilities';
import { listaTiposCarroceria, listaTiposRodado, listaTiposProprietario, tiposDocumentoEmpresa } from '../../../shared/mdfe';

const styles = {

}

const initialValues = {
    renavam: '',
    tara: '',
    capacidadekg: '',
    capacidadecubica: '',
    tipoRodado: { value: 1, label: listaTiposRodado.find(t => t.value === 1).label },
    tipoCarroceria: { value: 2, label: listaTiposCarroceria.find(t => t.value === 2).label },
    uf: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    naoProprietario: false,
    tipoProprietario: { value: 0, label: listaTiposProprietario.find(t => t.value === 0).label },
    rntrc: '',
    ufProprietario: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    tipoDocumento: tiposDocumentoEmpresa[1],
    documento: '__.___.___/___-__',
    nome: '',
    ie: '',
    placa: '',
    rntrcVeiculo: '',
    inativo: false
}

class PureVeiculosCreateUpdate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {
            apolloClientProvider.client.query({ query: actions.GET_VEICULO, variables: { id: id }, fetchPolicy: "network-only" })
                .then(({ data: { veiculo } }) => {
                    const { proprietario } = veiculo;
                    const resetData = {
                        id: veiculo.id,
                        placa: veiculo.placa ? veiculo.placa : initialValues.placa,
                        renavam: veiculo.renavam ? veiculo.renavam : initialValues.renavam,
                        tara: veiculo.tara ? veiculo.tara : initialValues.rntrcVeiculo,
                        rntrcVeiculo: veiculo.rntrc ? padNumber(parseInt(veiculo.rntrc, 10), 8) : initialValues.rntrc,
                        capacidadekg: veiculo.capacidadekg ? parseInt(veiculo.capacidadekg, 10) : initialValues.capacidadekg,
                        capacidadecubica: veiculo.capacidadecubica ? parseInt(veiculo.capacidadecubica, 10) : initialValues.capacidadecubica,
                        tipoRodado: veiculo.tiporodado ?
                            {
                                value: listaTiposRodado.find(t => t.enumString === veiculo.tiporodado).value,
                                label: listaTiposRodado.find(t => t.enumString === veiculo.tiporodado).label
                            } : initialValues.tipoRodado,
                        tipoCarroceria: veiculo.carroceria ?
                            {
                                value: listaTiposCarroceria.find(t => t.enumString === veiculo.carroceria).value,
                                label: listaTiposCarroceria.find(t => t.enumString === veiculo.carroceria).label
                            } : initialValues.tipoCarroceria,
                        uf: veiculo.uf ?
                            {
                                value: listaUF.find(e => e.sigla === veiculo.uf).id,
                                label: veiculo.uf
                            } : initialValues.uf,
                        naoProprietario: Boolean(proprietario),
                        prorietarioId: proprietario && proprietario.id,
                        tipoProprietario: proprietario && proprietario.tipoproprietario ?
                            {
                                value: listaTiposProprietario.find(t => t.enumString === proprietario.tipoproprietario).value,
                                label: listaTiposProprietario.find(t => t.enumString === proprietario.tipoproprietario).label
                            } : initialValues.tipoProprietario,
                        rntrc: proprietario && proprietario.rntrc ? padNumber(parseInt(proprietario.rntrc, 10), 8) : initialValues.rntrc,
                        ufProprietario: proprietario && proprietario.uf ?
                            {
                                value: listaUF.find(e => e.sigla === proprietario.uf).id,
                                label: proprietario.uf
                            } : initialValues.uf,
                        tipoDocumento: proprietario && proprietario.cpfcnpj &&
                            (proprietario.cpfcnpj.length === 11) ? tiposDocumentoEmpresa[0]
                            : initialValues.tipoDocumento,
                        documento: proprietario && proprietario.cpfcnpj ?
                            proprietario.cpfcnpj
                            : initialValues.documento,
                        nome: proprietario && proprietario.nome ?
                            proprietario.nome
                            : initialValues.nome,
                        ie: proprietario && proprietario.rgie ?
                            proprietario.rgie
                            : initialValues.ie,
                        inativo: veiculo.inativo
                    }
                    this.resetForm({values: resetData});
                });
        }
        else {
            apolloClientProvider.client.query({ query: actions.GET_CONFIGURACAO, fetchPolicy: "network-only" })
                .then(({ data: { configuracao } }) => {
                    const rntrc = configuracao
                        && configuracao.configuracaomdfe
                        && configuracao.configuracaomdfe.rntrc;

                    const uf = configuracao.configuracaomdfe.estado && {
                        label: configuracao.configuracaomdfe.estado, value: listaUF.find(e => e.sigla === configuracao.configuracaomdfe.estado).id
                    };

                    this.resetForm({ ...initialValues, rntrcVeiculo: rntrc, uf });
                })
        }
    }

    handleSubmit = (values, addHandlers) => {
        const id = this.props.match.params.id;

        const proprietario = values.naoProprietario ?
            {
                id: values.proprietarioId,
                nome: values.nome,
                cpfcnpj: somenteNumeros(values.documento),
                rntrc: values.rntrc.toString(),
                rgie: values.ie,
                tipoproprietario: listaTiposProprietario.find(t => t.value === values.tipoProprietario.value).enumString,
                uf: values.ufProprietario.label
            }
            : null;

        const submitData = {
            id: id ? parseInt(id) : undefined,
            placa: values.placa,
            tara: values.tara,
            rntrc: values.rntrcVeiculo.toString(),
            proprietario,
            tiporodado: listaTiposRodado.find(t => t.value === values.tipoRodado.value).enumString,
            carroceria: listaTiposCarroceria.find(t => t.value === values.tipoCarroceria.value).enumString,
            uf: values.uf.label,
            inativo: values.inativo,
            renavam: values.renavam ? values.renavam : undefined,
            capacidadekg: values.capacidadekg ? values.capacidadekg : undefined,
            capacidadecubica: values.capacidadecubica ? values.capacidadecubica : undefined
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_VEICULO,
                    variables: { veiculo: submitData },
                    refetchQueries: [{ query: actions.GET_VEICULOS }],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Veículo cadastrado!');
                    this.props.history.push('/veiculos');
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <VeiculosForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </VeiculosForm>
                {this.state.errors}
            </div >
        );
    };
}

export default withStyles(styles)(withRouter(PureVeiculosCreateUpdate));