import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client';

import SelectWrapped from '../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/UI/Spinner/FullWidthSpinner'
import { getIn } from 'formik';
import { fetchFromObject } from '../../../../../shared/utilities';
import { ErrorPage } from '../../../../../components/ErrorBoundary/ErrorBoundary';


const useStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 140
    },
}))

/**
 * Função para setar o valor do veiculo no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
};

const VeiculoField = (props) => {
    const classes = useStyles()
    const { loading, graphQlError, data } = useQuery(actions.GET_VEICULOS, { fetchPolicy: "network-only" });
    const { formik, disabled } = props;

    const nomeCampo = 'veiculo';
    const label = 'Veículo';

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    if (loading)
        return <Spinner />;

    if (graphQlError)
        return <ErrorPage />

    const { veiculos } = data;

    const lista = veiculos.node.map(r => ({
        value: r.id, label: r.placa
    }));

    const opcoes = lista;

    return (
        <FormControl required margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                value={fetchFromObject(formik.values, nomeCampo)}
                handleChange={(event) => handleChange(event, formik, nomeCampo)}
                handleBlur={() => handleBlur(formik, nomeCampo)}
                required
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl >
    )
};

export default VeiculoField;


