import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell, TablePagination, Tooltip, Fab, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import AddIcon from '@material-ui/icons/Add'


import { uppercaseFirstLetterOnly, b64toBlob, getAmountOfRowsToRender, getArrayWithUniqueItems, downloadFile, ReactIsInDevelomentMode } from '../../shared/utilities';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/FullWidthSpinner'
import * as celltypes from '../../shared/celltypes'
import CommonTableToolbar from '../../components/UI/CommonTableToolbar/CommonTableToolbar';
import CommonTableHead from '../../components/UI/CommonTableHead/CommonTableHead';
import apolloClientProvider from '../../apolloClientProvider';
import CancelarDialog from './Dialogs/CancelarDialog';
import EncerrarDialog from './Dialogs/EncerrarDialog';
import { BotaoConsultar, BotaoEmitir, BotaoCancelar, BotaoEncerrar, BotaoEditar, BotaoImprimir, BotaoExcluir, BotaoIncluirCondutor, BotaoCompartilhar, BotaoIncluirDfe, BotaoIncluirPagamento } from './BotoesAcoes';
import { situacoesMdfe, getSituacaoLabel } from '../../shared/mdfe';
import IncluirCondutorDialog from './Dialogs/IncluirCondutorDialog';
import IncluirDfeDialog from './Dialogs/IncluirDfeDialog';
import ExcluirDialog from './Dialogs/ExcluirDialog';
import { Formik } from 'formik';
import { ErrorPage } from '../../components/ErrorBoundary/ErrorBoundary';
import PagamentoInfEventoDialog from './Dialogs/PagamentoInfEventoDialog';
import axios, { baseURL } from "../../axios-api";

const dadosColuna = [
    { id: 'serie', type: celltypes.DEFAULT, numeric: false, padding: 'normal', label: 'Série' },
    { id: 'numero', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Número' },
    { id: 'datahoraemissao', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Data de emissão' },
    { id: 'ufinicio', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'UF de Início' },
    { id: 'uffim', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'UF de Término' },
    { id: 'tipoemissao', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Tipo de Emissão' },
    { id: 'situacao', type: celltypes.SITUACAO, numeric: false, padding: 'none', label: 'Situação' },
    { id: 'acoes', type: celltypes.ACTIONS, numeric: false, padding: 'none', label: 'Ações' }
]

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        width: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        height: '100%'
    },
    pagination: {
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: 28,
        alignItems: 'center',
    },
    pagination__input: {
        paddingTop: 2,
    },
    row: {
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            '&:hover>th>div>button': {
                visibility: 'visible',
                opacity: 1,
            },
            '&:hover>th>div>.icon-placeholder': {
                visibility: 'hidden',
                opacity: 0,
            },
            '&$selected>th>div>button': {
                visibility: 'visible',
                opacity: 1,
            },
            '&$selected>th>div>.icon-placeholder': {
                visibility: 'hidden',
                opacity: 0,
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& th>div>.icon-placeholder': {
                visibility: 'hidden',
                opacity: 0,
            },
        },
    },
    rowSelected: {
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            '& th>div>button': {
                visibility: 'visible',
                opacity: 1,
            },
            '& th>div>.icon-placeholder': {
                visibility: 'hidden',
                opacity: 0,
            },
        },
    },
    actionButton: {
        [theme.breakpoints.up('sm')]: {
            visibility: 'hidden',
            opacity: 0,
        },
        transition: 'visibility 0s, opacity 0.367s cubic-bezier(.165,.84,.44,1)',
        padding: 4,
    },
    actionButtonContainer: {
        display: 'inline-block',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            '&:first-child>button': {
                // paddingLeft: 0,
            },
        },
    },
    actionsTableCell: {
        width: 221
    },
    actionButtonProgress: {
        position: 'absolute',
        top: -5,
        left: -5,
        zIndex: 1,
    },
}));

const getSorting = (orderBy, order) => {
    let _order;

    switch (order) {
        case 'serie': _order = 'SERIE'; break;
        case 'numero': _order = 'NUMERO'; break;
        case 'datahoraemissao': _order = 'DATA_HORA_EMISSAO'; break;
        case 'ufinicio': _order = 'UF_INICIO'; break;
        case 'uffim': _order = 'UF_FIM'; break;
        case 'tipoemissao': _order = 'TIPO_EMISSAO'; break;
        case 'situacao': _order = 'SITUACAO'; break;
        default: _order = 'DATA_HORA_EMISSAO'; break;
    }

    return _order + '_' + orderBy.toUpperCase();
}

const Mdfe = (props) => {
    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('datahoraemissao')
    //const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(getAmountOfRowsToRender())
    const [hideSearchInput, setHideSearchInput] = useState(true)
    const [searchString, setSearchString] = useState('')
    const [cancelarDialogOpen, setCancelarDialogOpen] = useState(false)
    const [encerrarDialogOpen, setEncerrarDialogOpen] = useState(false)
    const [incluirCondutorDialogOpen, setIncluirCondutorDialogOpen] = useState(false)
    const [incluirDfeDialogOpen, setIncluirDfeDialogOpen] = useState(false)
    const [incluirPagamentoDialogOpen, setIncluirPagamentoDialogOpen] = useState(false)
    const [excluirDialogOpen, setExcluirDialogOpen] = useState(false)
    const [mdfeObjectDialog, setMdfeObjectDialog] = useState(null)
    const [compartilharMenuOpen, setCompartilharMenuOpen] = useState(null)
    const [loadingAction, setLoadingAction] = useState(new Set())
    //const [selectedTableRow, setSelectedTableRow] = useState(null)
    const history = useHistory()
    const classes = useStyles()
    const { loading, error, data, fetchMore } = useQuery(actions.GET_MDFES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: rowsPerPage,
            ordem: getSorting(order, orderBy)
        }
    })
    const [emitirMdfe, { loading: emitirMutationLoading }] = useMutation(actions.EMITIR_MDFE, { awaitRefetchQueries: true, refetchQueries: [{ query: actions.GET_MDFES }] })

    const handleBotaoBuscaClick = () => {
        setHideSearchInput(!hideSearchInput)
    }

    const handleRequestSort = (event, property) => {
        let newOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            newOrder = 'asc';
        }
        setOrder(newOrder)
        setOrderBy(property)
    }

    const handleEditarClick = (id) => {
        history.push('/mdfe/alterar/' + id);
    };

    const handleEmitirClick = (emitirMdfe, id) => {
        emitirMdfe({
            mutation: actions.EMITIR_MDFE,
            variables: { id },
        }).then(({ data }) => {
            if (data.emitirMdfe.situacao === situacoesMdfe.emProcessamento.enumString)
                actions.OPEN_SNACKBAR('MDFe em processamento!', false, true);
            else
                actions.OPEN_SNACKBAR('MDFe emitido!', false, true);
            history.push('/mdfe');
        }).catch(error => {
            apolloClientProvider.client.query({
                query: actions.GET_MDFES,
                fetchPolicy: 'network-only'
            })
            throw error;
        })
    };

    const addLoadingAction = (field, id) => {
        const newLoadingAction = new Set(loadingAction.values());
        newLoadingAction.add(field + id);
        setLoadingAction(newLoadingAction);
    }

    const removeLoadingAction = (field, id) => {
        const newLoadingAction = new Set(loadingAction.values());
        newLoadingAction.delete(field + id);
        setLoadingAction(newLoadingAction);
    }

    const isLoadingAction = (field, id) => {
        return loadingAction.has(field + id);
    }

    const handleConsultarClick = (chave) => {
        addLoadingAction('consultar', chave);
        apolloClientProvider.client.query({
            query: actions.CONSULTAR_MDFE,
            variables: { chave },
            fetchPolicy: "network-only",
        })
            .then(({ data }) => {
                removeLoadingAction('consultar', chave)
                actions.OPEN_SNACKBAR(`Código da Situação: ${data.consultarSituacao.cstat}. Mensagem: ${data.consultarSituacao.xmotivo}.`, false, true);
                apolloClientProvider.client.query({
                    query: actions.GET_MDFES,
                    fetchPolicy: "network-only",
                })
            })
            .catch(res => {
                apolloClientProvider.client.query({
                    query: actions.GET_MDFES,
                    fetchPolicy: "network-only",
                })
                removeLoadingAction('consultar', chave)
            })
    }

    const handleImprimirClick = (id) => {
        addLoadingAction('imprimir', id);
        const devMode = ReactIsInDevelomentMode();
        const url = devMode ? baseURL + 'mdfe/exportarpdf/' + id : '/mdfe/exportarpdf/' + id;
        axios.get(url)
            .then((response) => {
                const blob = b64toBlob(response.data, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);

                window.open(blobUrl, "_blank")
            })
            .catch((err) => console.log(err))
            .finally(() => {
                removeLoadingAction('imprimir', id);
            });
    }

    const handleCompartilharMenuOpen = (event, mdfe) => {
        setCompartilharMenuOpen(event.currentTarget)
        setMdfeObjectDialog(mdfe)
    }

    const handleExportarXmlClick = () => {
        const { id, chave } = mdfeObjectDialog;
        addLoadingAction('compartilhar', id);
        apolloClientProvider.client.query({
            query: actions.EXPORTAR_XML_MDFE,
            variables: { id },
            fetchPolicy: "network-only",
        })
            .then(({ data }) => {
                removeLoadingAction('compartilhar', id)
                downloadFile(data.exportarXmlMdfe, chave + '.xml', 'application/xml')
            })
            .catch(res => {
                removeLoadingAction('compartilhar', id)
                window.close();
            })
            .finally(() => {
                handleDialogClose()
            })
    }

    const handleExcluirDialogOpen = (mdfe) => {
        setExcluirDialogOpen(true)
        setMdfeObjectDialog(mdfe);
    };

    const handleCancelarDialogOpen = (mdfe) => {
        setCancelarDialogOpen(true)
        setMdfeObjectDialog(mdfe);
    }

    const handleEncerrarDialogOpen = (mdfe) => {
        setEncerrarDialogOpen(true)
        setMdfeObjectDialog(mdfe);
    }

    const handleIncluirCondutorDialogOpen = (mdfe) => {
        setIncluirCondutorDialogOpen(true)
        setMdfeObjectDialog(mdfe);
    }

    const handleIncluirDfeDialogOpen = (mdfe) => {
        setIncluirDfeDialogOpen(true);
        setMdfeObjectDialog(mdfe);
    }

    const handleIncluirPagamentoDialogOpen = (mdfe) => {
        setIncluirPagamentoDialogOpen(true);
        setMdfeObjectDialog(mdfe);
    }

    const handleDialogClose = () => {
        setIncluirCondutorDialogOpen(false)
        setEncerrarDialogOpen(false)
        setCancelarDialogOpen(false)
        setIncluirDfeDialogOpen(false)
        setIncluirPagamentoDialogOpen(false);
        setMdfeObjectDialog(null)
        setCompartilharMenuOpen(null)
    }

    const handleExcluirDialogClose = () => {
        setExcluirDialogOpen(false);
        setMdfeObjectDialog(null);

        return fetchMore({
            variables: {
                offset: rowsPerPage * page,
                limit: rowsPerPage
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, { ...fetchMoreResult });
            }
        });
    }

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };

    // const handleSearchSubmit = (values) => {
    //     console.log(values)
    // }

    const link = React.forwardRef((props, ref) => <Link to="/mdfe/alterar" {...props} ref={ref} />);

    const filterDialog = null;

    const rowsPerPageOptions = [5, 10, 15];
    if (rowsPerPageOptions.findIndex(el => el === rowsPerPage) === -1)
        rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort((a, b) => a - b);

    if (loading)
        return <Spinner />;

    if (error)
        return <ErrorPage />

    const { mdfes } = data;
    const { node, totalcount } = mdfes;

    const tableData = node.map(m => ({
        ...m,
        datahoraemissao: new Date(m.datahoraemissao).toLocaleString('pt-br'),
        tipoemissao: m.tipoemissao && uppercaseFirstLetterOnly(m.tipoemissao),
        situacao: getSituacaoLabel(m.situacao)
    })
    );

    return (
        <div data-test="component-mdfes" className={classes.paper}>
            <React.Fragment>
                <Formik
                    initialValues={{ busca: searchString }}
                    enableReinitialize={true}
                    onSubmit={(values, formikBag) => {
                        formikBag.resetForm({ values: { busca: values.busca } })
                        return fetchMore({
                            variables: {
                                busca: values.busca,
                                offset: 0,
                                limit: rowsPerPage,
                                ordem: getSorting(order, orderBy)
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                setSearchString(values.busca)
                                if (!fetchMoreResult) return prev;
                                return Object.assign({}, prev, { ...fetchMoreResult });
                            }
                        })
                    }}>
                    {formikProps => (
                        <React.Fragment>
                            <CommonTableToolbar
                                formikProps={formikProps}
                                hideSearchInput={hideSearchInput}
                                onSearchButtonClick={handleBotaoBuscaClick}
                                title={"MDF-e"}
                                filterDialog={filterDialog}
                            />
                        </React.Fragment>
                    )}
                </Formik>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <CommonTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableData.length}
                            columnData={dadosColuna}
                        />
                        <TableBody>
                            {tableData
                                //.sort(getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((n, index) => (
                                    <TableRow
                                        hover
                                        selected={mdfeObjectDialog && (mdfeObjectDialog.id === n.id)}
                                        classes={{ root: classes.row, selected: classes.rowSelected }}
                                        tabIndex={-1}
                                        key={n.datahoraemissao + n.numero + n.id}>
                                        {dadosColuna.map(coluna => {
                                            switch (coluna.type) {
                                                case (celltypes.DEFAULT):
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        onClick={() => handleEditarClick(n.id)}
                                                        component="th"
                                                        scope="row"
                                                        padding={coluna.padding}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                case (celltypes.NUMERIC):
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        onClick={() => handleEditarClick(n.id)}
                                                        padding={coluna.padding}
                                                        align="right">
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                case (celltypes.SITUACAO):
                                                    return (n.mdfeinfosefaz.cstat !== 0 ? (
                                                        <Tooltip
                                                            title={`Código: ${n.mdfeinfosefaz.cstat}. Motivo: ${n.mdfeinfosefaz.xmotivo}`}
                                                            interactive
                                                            key={'Tooltip' + n.id + n[coluna.id] + coluna.id}>
                                                            <TableCell
                                                                key={n.id + n[coluna.id] + coluna.id}
                                                                component="th"
                                                                scope="row"
                                                                onClick={() => handleEditarClick(n.id)}
                                                                padding={coluna.padding}>
                                                                {n[coluna.id]}
                                                            </TableCell>
                                                        </Tooltip>
                                                    )
                                                        : (<TableCell
                                                            key={n.id + n[coluna.id] + coluna.id}
                                                            component="th"
                                                            scope="row"
                                                            onClick={() => handleEditarClick(n.id)}
                                                            padding={coluna.padding}>
                                                            {n[coluna.id]}
                                                        </TableCell>))
                                                case (celltypes.ACTIONS):
                                                    return (<TableCell
                                                        key={n.id + index}
                                                        component="th"
                                                        scope="row"
                                                        padding={coluna.padding}
                                                        className={classes.actionsTableCell}>
                                                        {(() => {
                                                            switch (n.situacao) {
                                                                case situacoesMdfe.salvo.label:
                                                                    return <React.Fragment>
                                                                        <BotaoEmitir
                                                                            loading={emitirMutationLoading}
                                                                            classes={classes}
                                                                            onClick={() => handleEmitirClick(emitirMdfe, n.id)} />
                                                                        <BotaoEditar classes={classes} onClick={() => handleEditarClick(n.id)} />
                                                                        <BotaoExcluir classes={classes} onClick={() => handleExcluirDialogOpen(n)} />
                                                                    </React.Fragment>
                                                                case situacoesMdfe.assinado.label:
                                                                    return <React.Fragment>
                                                                        <BotaoConsultar classes={classes} onClick={() => handleConsultarClick(n.chave)} loading={isLoadingAction('consultar', n.chave)} />
                                                                        <BotaoEmitir
                                                                            loading={emitirMutationLoading}
                                                                            classes={classes}
                                                                            onClick={() => handleEmitirClick(emitirMdfe, n.id)} />
                                                                    </React.Fragment>
                                                                case situacoesMdfe.rejeitado.label:
                                                                    return <React.Fragment>
                                                                        <BotaoConsultar classes={classes} onClick={() => handleConsultarClick(n.chave)} loading={isLoadingAction('consultar', n.chave)} />
                                                                        <BotaoEmitir
                                                                            loading={emitirMutationLoading}
                                                                            classes={classes}
                                                                            onClick={() => handleEmitirClick(emitirMdfe, n.id)} />
                                                                        <BotaoEditar classes={classes} onClick={() => handleEditarClick(n.id)} />
                                                                        <BotaoExcluir classes={classes} onClick={() => handleExcluirDialogOpen(n)} />
                                                                    </React.Fragment>
                                                                case situacoesMdfe.autorizado.label:
                                                                    return <React.Fragment>
                                                                        <BotaoConsultar classes={classes} onClick={() => handleConsultarClick(n.chave)} loading={isLoadingAction('consultar', n.chave)} />
                                                                        <BotaoImprimir classes={classes} onClick={() => handleImprimirClick(n.id)} loading={isLoadingAction('imprimir', n.id)} />
                                                                        <BotaoCompartilhar classes={classes} onClick={(event) => handleCompartilharMenuOpen(event, n)} loading={isLoadingAction('compartilhar', n.id)} />
                                                                        <BotaoIncluirCondutor classes={classes} onClick={() => handleIncluirCondutorDialogOpen(n)} />
                                                                        {
                                                                            n.carregamentoPosterior ? <BotaoIncluirDfe classes={classes} onClick={() => handleIncluirDfeDialogOpen(n)} /> : null
                                                                        }
                                                                        {
                                                                            n.mdfeInfPag.length === 0 ? <BotaoIncluirPagamento classes={classes} onClick={() => handleIncluirPagamentoDialogOpen(n)} /> : null
                                                                        }
                                                                        <BotaoCancelar classes={classes} onClick={() => handleCancelarDialogOpen(n)} />
                                                                        <BotaoEncerrar classes={classes} onClick={() => handleEncerrarDialogOpen(n)} />
                                                                    </React.Fragment>
                                                                case situacoesMdfe.encerrado.label:
                                                                    return <React.Fragment>
                                                                        <BotaoConsultar classes={classes} onClick={() => handleConsultarClick(n.chave)} loading={isLoadingAction('consultar', n.chave)} />
                                                                        <BotaoImprimir classes={classes} onClick={() => handleImprimirClick(n.id)} loading={isLoadingAction('imprimir', n.id)} />
                                                                        <BotaoCompartilhar classes={classes} onClick={(event) => handleCompartilharMenuOpen(event, n)} loading={isLoadingAction('compartilhar', n.id)} />
                                                                    </React.Fragment>
                                                                case situacoesMdfe.cancelado.label:
                                                                    return <React.Fragment>
                                                                        <BotaoConsultar classes={classes} onClick={() => handleConsultarClick(n.chave)} loading={isLoadingAction('consultar', n.chave)} />
                                                                        <BotaoImprimir classes={classes} onClick={() => handleImprimirClick(n.id)} loading={isLoadingAction('imprimir', n.id)} />
                                                                        <BotaoCompartilhar classes={classes} onClick={(event) => handleCompartilharMenuOpen(event, n)} loading={isLoadingAction('compartilhar', n.id)} />
                                                                    </React.Fragment>
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}
                                                    </TableCell>)
                                                default:
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        component="th"
                                                        scope="row"
                                                        padding={coluna.padding ? 'none' : 'default'}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                            }
                                        })
                                        }
                                    </TableRow>
                                )
                                )}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.pagination}>
                    <Tooltip title="Novo">
                        <Fab
                            component={link}
                            color="primary"
                            aria-label="Adicionar">
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    <TablePagination
                        classes={{ input: classes.pagination__input }}
                        rowsPerPageOptions={rowsPerPageOptions}
                        labelRowsPerPage={"Itens por página"}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        component="div"
                        count={totalcount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Página Anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Página Seguinte',
                        }}
                        onPageChange={(event, page) =>
                            fetchMore({
                                variables: {
                                    offset: rowsPerPage * page
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev;
                                    const obj = Object.assign({}, prev, {
                                        mdfes: {
                                            node: getArrayWithUniqueItems([...prev.mdfes.node, ...fetchMoreResult.mdfes.node], 'id'),
                                            totalcount: fetchMoreResult.mdfes.totalcount,
                                            __typename: prev.mdfes.__typename
                                        }
                                    });
                                    handleChangePage(event, page);
                                    return obj
                                }
                            })
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </React.Fragment>
            <ExcluirDialog open={excluirDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleExcluirDialogClose} />
            <CancelarDialog open={cancelarDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleDialogClose} />
            <EncerrarDialog open={encerrarDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleDialogClose} />
            <IncluirCondutorDialog open={incluirCondutorDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleDialogClose} />
            <IncluirDfeDialog open={incluirDfeDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleDialogClose} />
            <PagamentoInfEventoDialog open={incluirPagamentoDialogOpen} mdfe={mdfeObjectDialog} handleClose={handleDialogClose} />
            <Menu
                id="compartilhar-menu"
                anchorEl={compartilharMenuOpen}
                open={Boolean(compartilharMenuOpen)}
                onClose={handleDialogClose}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <MenuItem onClick={handleExportarXmlClick}>Exportar XML</MenuItem>
            </Menu>
        </div>
    );
}

export default Mdfe;