import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
}));

const UsuarioEmailField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;
    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                required
                id="email"
                label="E-mail"
                value={formik.values.email}
                error={Boolean(
                    formik.touched.email && formik.errors.email
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={formik.errors.email &&
                    formik.touched.email && (
                        formik.errors.email
                    )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default UsuarioEmailField;
