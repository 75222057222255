import React from 'react';
import { TextField, Divider, InputAdornment, Button, Typography, LinearProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik';
import LockIcon from '@material-ui/icons/Lock'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import Yup from '../../../yup';
import * as cores from '../../../shared/cores';

const theme = createTheme({
    palette: {
        type: 'dark',
    },
});
const useStyles = makeStyles(theme => ({
    linearProgress: {
        position: 'absolute',
        width: 400,
    },
    textField: {
        display: 'flex',
        margin: '16px 32px',
        color: cores.titulo,
    },
    input: {
        '& input': {
            backgroundColor: 'unset !important',
        }
    },
    adornment: {
        marginRight: 4,
    },
    loginEntrar: {
        background: theme.palette.common.white,
        width: 470,
        height: 80,
        margin: 'auto',
        display: 'flex',
        textTransform: 'unset',
        borderRadius: 'unset',
        transform: 'translateX(-35px)',
    },
    form: {
        textAlign: 'center'
    },
    root: {
        color: theme.palette.common.white,
        '&$checked': {
            color: theme.palette.common.white,
        },
        padding: 8,
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    checked: {
        '&$disabled': {
            color: theme.palette.text.disabled,
        },
    },
    checkbox: {
        color: 'white'
    }
}));

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required(),
    senha: Yup.string()
        .required(),
    autoLogin: Yup.bool()
        .required()
})

const LoginForm = (props) => {
    const classes = useStyles();

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={loginSchema}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return props.onSubmit(values, addHandlers);
            }}>
                {({
                values,
                touched,
                errors,
                isSubmitting,
                dirty,
                resetForm,
                handleSubmit,
                handleChange,
                handleBlur,
                }) => {
                const form = <Form className={classes.form}>
                    <ThemeProvider theme={theme}>
                        <TextField
                            className={classes.textField}
                            required
                            id="email"
                            label="E-mail"
                            autoComplete="username email"
                            value={values.email}
                            error={Boolean(
                                touched.email && errors && errors.email
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            margin="normal"
                            helperText={touched.email &&
                                errors &&
                                errors.email &&
                                (
                                    errors.email
                                )}
                            FormHelperTextProps={{ error: true }}
                            InputProps={{
                                startAdornment: <InputAdornment position='start' className={classes.adornment}><AccountCircleIcon /></InputAdornment>
                            }}
                        />
                        <Divider />
                        <TextField
                            className={classes.textField}
                            type="password"
                            required
                            id="senha"
                            label="Senha"
                            autoComplete="current-password"
                            value={values.senha}
                            error={Boolean(
                                touched.senha && errors && errors.senha
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            margin="normal"
                            helperText={touched.senha && errors && errors.senha &&
                                (
                                    errors.senha
                                )}
                            FormHelperTextProps={{ error: true }}
                            InputProps={{
                                startAdornment: <InputAdornment position='start' className={classes.adornment}><LockIcon /></InputAdornment>
                            }}
                        />
                    </ThemeProvider>
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                            <Checkbox
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                    disabled: classes.disabled,
                                }}
                                id="autoLogin"
                                checked={values.autoLogin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={isSubmitting}
                                color="primary"
                                value="autoLogin"
                            />
                        }
                        disabled={isSubmitting}
                        label="Manter conectado"
                    />

                    <Button
                        className={classes.loginEntrar}
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit">
                        <Typography variant="h3" align="center">
                            Entrar
                        </Typography>
                    </Button>

                    {isSubmitting && <LinearProgress className={classes.linearProgress} />}
                </Form>;
                return props.children({
                    form,
                    values: values,
                    isDirty: dirty,
                    isSubmitting: isSubmitting,
                    handleSubmit: handleSubmit,
                    handleCancel: resetForm,
                });
            }}
        </Formik>
    )
};

export default LoginForm;