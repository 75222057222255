import React from "react";
import { Formik, Form } from "formik";
import PropTypes from 'prop-types';
import { IconButton, Typography, Fab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../../../yup'
import * as cores from '../../../../shared/cores'
import NomeField from "./Fields/NomeField";
import CpfField from "./Fields/CpfField";
import  AtivoCheck  from "./Fields/AtivoField";

const useStyles = makeStyles(theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%'
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    buttonConfirmContainer: {
        display: 'flex',
        margin: '23px 28px',
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    buttonClose: {
        margin: 4,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const CondutoresSchema = Yup.object().shape({
    nome: Yup.string()
        .min(2)
        .max(60)
        .required(),
    cpf: Yup.string()
        .cpf()
        .required()
});

const CondutoresForm = props => {
    const classes = useStyles();

    const {initialValues, onSubmit, handleClose} = props;

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={CondutoresSchema}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return onSubmit(values, addHandlers);
            }}>
            {formikProps => {
                const form = (
                    <React.Fragment>
                        <div className={classes.title}>
                            <Typography color="inherit" variant="h6">
                                Cadastro de Condutor
                                </Typography>
                            <IconButton
                                className={classes.buttonClose}
                                aria-label="Cancelar"
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Form className={classes.form}>
                            <div className={classes.fieldsContainer}>
                                <NomeField formik={formikProps} />
                                <CpfField formik={formikProps} />
                                <AtivoCheck formik={formikProps}/>
                            </div>
                            <div className={classes.buttonConfirmContainer}>
                                <Fab
                                    type="submit"
                                    color="primary"
                                    aria-label="Adicionar">
                                    <CheckIcon />
                                </Fab>
                            </div>
                        </Form>
                    </React.Fragment>
                );
                return props.children({
                    form,
                    isDirty: formikProps.dirty,
                    isSubmitting: formikProps.isSubmitting,
                    setFieldValue: formikProps.setFieldValue,
                    setFieldError: formikProps.setFieldError,
                    setFieldTouched: formikProps.setFieldTouched,
                    handleSubmit: formikProps.handleSubmit,
                    handleCancel: formikProps.resetForm,
                });

            }
            }
        </Formik>
    );
}

CondutoresForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default CondutoresForm;