import React, { useState } from 'react';
import { IconButton, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray } from 'formik';

import * as actions from '../../../../../../store/actions'
import Spinner from '../../../../../../components/UI/Spinner/FullWidthSpinner';
import UfDescarregamentoField from '../../Fields/UfDescarregamentoField';
import AlertField from '../Fields/AlertField';
import {  alertMessages } from '../../../../../../shared/mdfe';
import DocumentosDialog from './DocumentDialog';
import { ErrorPage } from '../../../../../../components/ErrorBoundary/ErrorBoundary';
import { fetchFromObject } from '../../../../../../shared/utilities'

const useStyles = makeStyles(theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: 8,
        flexDirection: 'row',
        width: '100%',
    },
    table: {
        width: '100%',
        margin: 8,
    },
    tableContentRow: {
        verticalAlign: 'top'
    },
    removerCell: {
        verticalAlign: 'middle',
    },
    row : {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    iconContent: {
        marginTop: 1,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 15,
        color: "#21486d"
    }
}))


const onDeleteHandler = (index, arrayHelpers) => {
    arrayHelpers.remove(index);
    const lastPosition = arrayHelpers.form.values.municipiosDescarregamento.length;
    arrayHelpers.form.setFieldTouched(`municipiosDescarregamento[${lastPosition}].municipio`, false, false);
    arrayHelpers.form.setFieldTouched(`municipiosDescarregamento[${lastPosition}].chave`, false, false);
};

const DocumentosPanel = (props) => {
    const { formik, disabled } = props;
    const ufId = formik.values.ufDescarregamento.value;
    const carregamentoPosterior = formik.values.carregamentoPosterior;
    const classes = useStyles()
    const { loading, error, data } = useQuery(actions.GET_MUNICIPIOS, { variables: { id: ufId } })
    const [arquivoDialogOpen, setArquivoDialogOpen] = useState(false);
    const initialDialogValues = {
        tipo: { value: undefined, label: undefined, enumString: undefined },
        municipio: { label: undefined, value: undefined },
        chave: '',
        segcodbarra: ''
    }

    let resetForm = null;

    //TODO: Adicionar paginação?

    const tableData = formik.values.municipiosDescarregamento;

    if (error) return <ErrorPage />

    const handleDialogOpen = () => {
        setArquivoDialogOpen(true);
    }

    const handleDialogClose = () => {
        setArquivoDialogOpen(false);
    }

    const handleDialogSubmit = (values, formikProps, arrayHelpers) => {
        //Object.keys(values).forEach(key => formikProps.setFieldValue(key, values[key]))
        if (values.index !== undefined) {
            Object.keys(values).forEach(key => formikProps.setFieldValue(`municipiosDescarregamento[${values.index}].${key}`, values[key]))
        }
        else {
            arrayHelpers.push(values);
        }
        setArquivoDialogOpen(false);
    }

    const handleAdd = (index, formikProps) => {
        handleDialogOpen();
        if (index !== undefined) {
            const municipioDescarregamento = fetchFromObject(formikProps.values, `municipiosDescarregamento.${index}`);
            const resetData = { ...municipioDescarregamento, index }
            resetForm({values: resetData});
        }
        else {
            resetForm({values: initialDialogValues});
        }
    }

    /**
     * Função para setar o valor do UF no Formik
     * @method handleChange
     * @param {object} event - Evento
     * @param {object} formik - Props do formik obtidas por RenderProps
    */
    const handleUfChange = (event, formik) => {
        if (formik.values.municipiosDescarregamento.length > 0) {
            if (window.confirm("Alterar a UF de Descarregamento irá limpar a lista de municípios. Você tem certeza que deseja alterar?")) {
                formik.setFieldValue("ufDescarregamento", event);
                const municipiosDescarregamentoOld = formik.values.municipiosDescarregamento;
                const municipiosDescarregamentoNew = municipiosDescarregamentoOld.map(municipioDescarregamento => {
                    return {
                        ...municipioDescarregamento,
                        municipio: {
                            label: undefined,
                            value: undefined,
                        }
                    }
                });
                formik.setFieldValue('municipiosDescarregamento', municipiosDescarregamentoNew);
                //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
                formik.validateForm().then(() => formik.validateForm())
            }
        }
        else {
            formik.setFieldValue("ufDescarregamento", event);
            formik.setFieldValue('municipiosDescarregamento', []);
            //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
            formik.validateForm().then(() => formik.validateForm())
        }
    }

    return (
        <React.Fragment>
            <div className={classes.addUfContainer}>
                <UfDescarregamentoField
                    disabled={disabled || carregamentoPosterior}
                    handleChange={handleUfChange}
                    formik={formik} />
                {
                    carregamentoPosterior ? <AlertField classes={classes} mensagem={alertMessages.ALERT_LINKED_DOCUMENTS} /> : null
                }
            </div>
            <FieldArray
                name='municipiosDescarregamento'
                render={
                    arrayHelpers => {
                        if (loading)
                            return <Spinner />;

                        const { municipios } = data;

                        const menu = municipios.map(({ id, nome }) => (
                            {
                                value: id,
                                label: nome
                            }
                        ));
                        return (
                            <React.Fragment>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Tipo
                                                            </TableCell>
                                            <TableCell>
                                                Município
                                                            </TableCell>
                                            <TableCell align="center">
                                                Chave
                                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData
                                            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((n, index) => (
                                                <TableRow
                                                    className={classes.row}
                                                    hover
                                                    tabIndex={-1}
                                                    key={index}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={() => handleAdd(index, formik)}>
                                                        <Typography variant="body1">
                                                            {n.tipo.label ?? initialDialogValues.tipo.label}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={() => handleAdd(index, formik)}>
                                                        <Typography variant="body1">
                                                            {n.municipio.label ?? initialDialogValues.municipio.label}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        onClick={() => handleAdd(index, formik)}>
                                                        <Typography variant="body1">
                                                            {n.chave ?? initialDialogValues.chave}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.removerCell}
                                                        component="th"
                                                        scope="row">
                                                        <IconButton
                                                            disabled={formik.isSubmitting || disabled || carregamentoPosterior}
                                                            aria-label="Delete" onClick={() => onDeleteHandler(index, arrayHelpers)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            )}
                                            <DocumentosDialog
                                                open={arquivoDialogOpen}
                                                initialValues={initialDialogValues}
                                                handleSubmit={(values) => handleDialogSubmit(values, formik, arrayHelpers)}
                                                handleClose={handleDialogClose}
                                                disabled={disabled}
                                                menu={menu}>
                                                {({ form, handleCancel }) => (
                                                    <React.Fragment>
                                                        {resetForm = handleCancel}
                                                        {form}
                                                    </React.Fragment>
                                                )}
                                            </DocumentosDialog>
                                    </TableBody>
                                </Table>
                                {!formik.isSubmitting && (
                                <IconButton
                                    disabled={formik.isSubmitting || disabled}
                                    className={classes.buttonAdd}
                                    aria-label="Adicionar"
                                    onClick={() => handleAdd(undefined, formik)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                            </React.Fragment>
                        );
                    }
                }
            />
        </React.Fragment >
    );
}

export default DocumentosPanel;