import React, { Component } from 'react';
import { Paper, Divider, IconButton, withStyles, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { arrayMove } from 'react-sortable-hoc';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { getArrayWithUniqueItems } from '../../../../../shared/utilities';
import ReboqueSelectField from './Fields/ReboqueSelectField';

const styles = theme => ({
    buttonAdd: {
        margin: theme.spacing(1)
    },
    addUfContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    list: {
        height: 'calc(100% - 69px)',
        position: 'relative',
        overflow: 'auto',
    },
    listDisabled: {
        height: 'calc(100% - 69px)',
        position: 'relative',
        overflow: 'auto',
        color: theme.palette.text.disabled
    },
    select: {
        marginBottom: 28
    },
    panel: {
        height: '100%',
        minHeight: '244px',
        minWidth: 185
    },
    listItem: {
        color: 'inherit',
    },
    listItemDisabled: {
        color: theme.palette.text.disabled,
    },
})

class reboquePanel extends Component {
    state = {
        selected: { value: null, label: null }
    };

    onSortEnd = ({ oldIndex, newIndex }, items, formik) => {
        formik.setFieldValue(
            "reboques",
            arrayMove(items, oldIndex, newIndex));
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    onDeleteHandler = (index, items, formik) => {
        items.splice(index, 1);
        formik.setFieldValue(
            "reboques",
            items);
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
        formik.validateForm().then(() => formik.validateForm())
    };

    handleChange = (event) => {
        this.setState({ selected: event })
    }

    handleAdd = (items, formik) => {
        if (this.state.selected.value) {
            formik.setFieldTouched("reboques", true, true);
            formik.setFieldValue(
                "reboques",
                getArrayWithUniqueItems([...items, this.state.selected]));
            //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
            formik.validateForm().then(() => formik.validateForm())
        }
    }

    render() {
        const { classes, formik, disabled } = this.props;



        const items = formik.values.reboques;

        return (
            <Paper className={classes.panel} elevation={1}>
                <div className={classes.addUfContainer}>
                    <ReboqueSelectField
                        disabled={disabled}
                        handleChange={this.handleChange}
                        selected={this.state.selected}
                        formik={formik}
                        label="Reboques" />
                    {!formik.isSubmitting && (
                        <IconButton
                            disabled={formik.isSubmitting || disabled}
                            className={classes.buttonAdd}
                            aria-label="Adicionar"
                            onClick={() => this.handleAdd(items, formik)}>
                            <AddIcon />
                        </IconButton>
                    )}
                </div>

                <Divider />
                <List disablePadding className={!disabled ? classes.list : classes.listDisabled}>
                    {items.map((item, index) => (
                        <ListItem ContainerProps={{ className: !(formik.isSubmitting || disabled) ? classes.listItem : classes.listItemDisabled }} key={item.value + item.label}>
                            <ListItemText primary={item.label} />
                            {!formik.isSubmitting && (
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        disabled={formik.isSubmitting || disabled}
                                        aria-label="Delete"
                                        onClick={() => this.onDeleteHandler(index, items, formik)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    )
                    )}
                </List>
            </Paper>

        );
    }
}

export default withStyles(styles)(reboquePanel);