import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchFromObject } from '../../../../../shared/utilities';


const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(3),
        width: 180,
        display: 'flex',
    }
}));

const handleChange = (formik, event, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event.target.checked);
}


const AtivoCheck = props =>{
    const classes = useStyles();
    const { formik } = props;

    const nomeCampo = "inativo";
    const label = "Inativo";

    return (
        <React.Fragment>
            <div className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            size="small"
                            checked={fetchFromObject(formik.values, nomeCampo)}
                            onChange={event => handleChange(formik, event, nomeCampo)}
                            value={fetchFromObject(formik.values, nomeCampo)}
                            id={nomeCampo}
                        />
                    }
                    label={label}
                    labelPlacement="end"
                />
            </div>
        </ React.Fragment>
    )
}

export default AtivoCheck;