import React, { Component } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import blue from '@material-ui/core/colors/blue';
import InfoIcon from '@material-ui/icons/Info';

import apolloClientProvider from '../../../apolloClientProvider';
import * as graphQLActions from '../../../store/actions';
import { certificadoAlerts } from '../../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    info: {
        backgroundColor: blue[600]
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
        alignContent: 'center'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));


class InfoSnackbar extends Component  {
    state = {
        open: false,
        mensagem: ''
    }
   

    handleClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        apolloClientProvider.client.query({ query: graphQLActions.GET_INFO_CERTIFICADO, fetchPolicy: "network-only" })
        .then(({ data: { configuracao } }) => {
            const validade = new Date(configuracao.configuracaocertificado.validade);
            const diffDays = Math.ceil((validade - Date.now()) / (1000 * 60 * 60 * 24));

            let mensagem = certificadoAlerts.find(m => m.value === 3).message.replace("#", diffDays);

            if(diffDays < 0)
                mensagem = certificadoAlerts.find(m => m.value === 0).message;
            if(diffDays === 0)
                mensagem = certificadoAlerts.find(m => m.value === 1).message;
            if(diffDays === 1)
                mensagem = certificadoAlerts.find(m => m.value === 2).message;
            
            if(diffDays <= 20)
                this.setState({ mensagem: mensagem, open: true});
        });
    };

    render(){
        const classes = this.props.classes;

        return <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    onClose={this.handleClose}>
                        <SnackbarContent
                            className = {classes.info}
                            message={
                                <span id="message-id" className={classes.message}>
                                    <InfoIcon className={classes.icon}/>
                                    {this.state.mensagem}
                                </span>
                            }
                            action={
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                </Snackbar>
    }  

}

const InfoSnackbarHolder = () => {
    const classes = useStyles();
    return (
        <InfoSnackbar classes={classes} />
    )
}

export default InfoSnackbarHolder