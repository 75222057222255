import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell, TablePagination, Tooltip, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { getSorting, mascaraCpf, getAmountOfRowsToRender, getArrayWithUniqueItems } from '../../shared/utilities';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/FullWidthSpinner'
import * as celltypes from '../../shared/celltypes'
import CommonTableToolbar from '../../components/UI/CommonTableToolbar/CommonTableToolbar';
import CommonCheckFilterField from '../../components/UI/CommonCheckFilterField/CommonCheckFilterField';
import CommonTableHead from '../../components/UI/CommonTableHead/CommonTableHead';
import { ErrorPage } from '../../components/ErrorBoundary/ErrorBoundary';
import ExcluirDialog from './CreateUpdate/Dialog/ExcluirDialog';
import { Formik } from 'formik';

const dadosColuna = [
    { id: 'nome', type: celltypes.DEFAULT, numeric: false, padding: 'normal', label: 'Nome' },
    { id: 'cpfcnpj', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'CPF' },
    { id: 'podeexcluir', type: celltypes.ACTIONS, numeric: false, padding: 'none', label: '' }
]

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        width: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        height: '100%'
    },
    pagination: {
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: 28,
        alignItems: 'center',
    },
    pagination__input: {
        paddingTop: 2,
    },
    row: {
        cursor: 'pointer'
    },
    inactive: {
        cursor: 'pointer',
        color: '#a6a6a6'
    }
}));

const Condutores = () => {
    const classes = useStyles()
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('nome')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(getAmountOfRowsToRender())
    const [hideSearchInput, setHideSearchInput] = useState(true)
    const [searchCheck, setSearchCheck] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [excluirDialogOpen, setExcluirDialogOpen] = useState(false)
    const [condutorObjetDialog, setCondutorObjetDialog] = useState(null)
    const history = useHistory()
    const { loading, error, data, fetchMore } = useQuery(actions.GET_CONDUTORES, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: rowsPerPage
        }
    })

    const handleRequestSort = (event, property) => {
        let newOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            newOrder = 'asc';
        }
        setOrder(newOrder)
        setOrderBy(property)
    }

    const handleBotaoBuscaClick = () => {
        setHideSearchInput(!hideSearchInput)
    }

    const handleClick = (event, id) => {
        history.push('/condutores/alterar/' + id);
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };

    const handleExcluirDialogOpen = (condutor) => {
        setExcluirDialogOpen(true);
        setCondutorObjetDialog(condutor); 
    };

    const handleDialogClose = () => {
        setExcluirDialogOpen(false);
        setCondutorObjetDialog(null);
        return fetchMore({
            variables: {
                offset: rowsPerPage * page,
                limit: rowsPerPage
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, { ...fetchMoreResult });
            }
        }); 
    }

    const handleCheck = (event) => {
        const inativo = event.target.checked;
        fetchMore({
            variables: {
                busca: searchString,
                inativo: inativo,
                offset: rowsPerPage * page,
                limit: rowsPerPage
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                setSearchCheck(inativo);
                if (!fetchMoreResult) return prev;
                const obj = Object.assign({}, prev, {
                    motoristas: {
                        node: page === 0 ? getArrayWithUniqueItems([...fetchMoreResult.motoristas.node], 'id'):
                         getArrayWithUniqueItems([...prev.motoristas.node, ...fetchMoreResult.motoristas.node], 'id'),
                        totalcount: fetchMoreResult.motoristas.totalcount,
                        __typename: prev.motoristas.__typename
                    }
                });
                return obj
            }
        })
    };

    const link = React.forwardRef((props, ref) => <Link to="/condutores/alterar" {...props} ref={ref} />);

    const filterDialog = null;

    const rowsPerPageOptions = [5, 10, 15]
    rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort((a, b) => a - b);

    if (loading)
        return <Spinner />;

    if (error)
        return <ErrorPage />

    const { motoristas } = data;
    const { node, totalcount } = motoristas;

    const tableData = node.map(val => ({
        ...val,
        cpfcnpj: mascaraCpf(val.cpfcnpj)
    }));

    return (
        <div data-test="component-condutores" className={classes.paper}>
            <React.Fragment>
                <Formik
                    initialValues={{ busca: searchString, inativo: searchCheck }}
                    enableReinitialize={true}
                    onSubmit={(values, formikBag) => {
                        formikBag.resetForm({ values: { busca: values.busca, inativo: values.inativo } })
                        return fetchMore({
                            variables: {
                                busca: values.busca,
                                inativo: values.inativo,
                                offset: 0,
                                limit: rowsPerPage
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                setSearchString(values.busca);
                                setSearchCheck(values.inativo);
                                if (!fetchMoreResult) return prev;
                                return Object.assign({}, prev, { ...fetchMoreResult });
                            }
                        })
                    }}>
                    {formikProps => (
                        <React.Fragment>
                            <CommonTableToolbar
                                formikProps={formikProps}
                                hideSearchInput={hideSearchInput}
                                onSearchButtonClick={handleBotaoBuscaClick}
                                title={"Condutores"}
                                filterDialog={filterDialog}
                            />
                            <CommonCheckFilterField formik={formikProps} clickCheck={ handleCheck } />
                        </React.Fragment>
                    )}
                </Formik>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <CommonTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableData.length}
                            columnData={dadosColuna}
                        />
                    <TableBody>
                        {tableData
                            .sort(getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(n => (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={n.id}
                                >
                                    {
                                        dadosColuna.map(coluna => {
                                            switch (coluna.type) {
                                                case (celltypes.DEFAULT):
                                                    return (<TableCell
                                                        className={n.inativo ? classes.inactive : classes.row}
                                                        key={n.id + n[coluna.id]}
                                                        onClick={event => handleClick(event, n.id)}
                                                        component="th"
                                                        scope="row"
                                                        padding={coluna.padding}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                case (celltypes.NUMERIC):
                                                    return (<TableCell
                                                        className={n.inativo ? classes.inactive : classes.row}
                                                        onClick={event => handleClick(event, n.id)}
                                                        key={n.id + n[coluna.id]}
                                                        padding={coluna.padding}
                                                        align="right">
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                case (celltypes.ACTIONS):
                                                    return (<TableCell
                                                        className={n.inativo ? classes.inactive : classes.row}
                                                        key={n.id + n[coluna.id]}
                                                        padding={coluna.padding}
                                                        align="center">
                                                        {
                                                            n[coluna.id] ?
                                                            <Tooltip title="Exluir" aria-label="Exluir">
                                                                <IconButton
                                                                    onClick={() => handleExcluirDialogOpen(n)}
                                                                    children={<DeleteIcon fontSize="small" />}
                                                                />
                                                            </Tooltip> :
                                                            <IconButton
                                                                disabled={true}
                                                                children={<DeleteIcon fontSize="small" />}
                                                            />
                                                        }
                                                    </TableCell>)
                                                default:
                                                    return (<TableCell
                                                        className={n.inativo ? classes.inactive : classes.row}
                                                        key={n.id + n[coluna.id]}
                                                        component="th"
                                                        scope="row"
                                                        padding={coluna.padding}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                            }
                                        })
                                    }
                                </TableRow>
                            )
                            )}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.pagination}>
                <Tooltip title="Novo">
                    <Fab
                        component={link}
                        color="primary"
                        aria-label="Adicionar">
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <TablePagination
                    classes={{ input: classes.pagination__input }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={"Itens por página"}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    component="div"
                    count={totalcount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Página Seguinte',
                    }}
                    onPageChange={(event, page) =>
                        fetchMore({
                            variables: {
                                offset: rowsPerPage * page,
                                inativo: searchCheck
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                const obj = Object.assign({}, prev, {
                                    motoristas: {
                                        node: getArrayWithUniqueItems([...prev.motoristas.node, ...fetchMoreResult.motoristas.node], 'id'),
                                        totalcount: fetchMoreResult.motoristas.totalcount,
                                        __typename: prev.motoristas.__typename
                                    }
                                });
                                handleChangePage(event, page);
                                return obj
                            }
                        })
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ExcluirDialog open={excluirDialogOpen} condutor={condutorObjetDialog} handleClose={handleDialogClose} />
            </div>

            </React.Fragment>
        </div >
    );
}
export default Condutores;