import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    icon: {
        colorPrimary: 'inherit',
    },
}));

function TruckTrailer() {
    const classes = useStyles();
    return (
        <SvgIcon className={classes.icon}>
            <path d="M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z" />
        </SvgIcon>
    );
}

export default TruckTrailer;