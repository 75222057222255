import gql from 'graphql-tag';

import { snackbar, setSnackbar } from '../cache'

export const GET_SNACKBAR = gql`
{
  snackbar @client {
        open
        message
        error
        noAutoClose
    }
}
`;

export const SET_SNACKBAR = setSnackbar;

/**
 * Abre o Snackbar com a mensagem informada
 * @function OPEN_SNACKBAR
 * @param {string} message Mensagem a ser exibida
 * @param {bool} error Mostrar como erro
 * @param {bool} noAutoClose Não fechar automaticamente
 */
export const OPEN_SNACKBAR = (message, error = false, noAutoClose = false) => {
    SET_SNACKBAR({
        ...snackbar(),
        open: true,
        message,
        error,
        noAutoClose
    })
}

export const CLOSE_SNACKBAR = () => {
    SET_SNACKBAR({
        ...snackbar(),
        open: false
    })
}