import Yup from '../../../../../../../yup';

const InformacaoPrazoSchemaDados = Yup.object().shape({
    vparcela: Yup.string()
            .ValorMonetario()
            .required(),
    dvenc: Yup
        .date()
        .required('Valor Inválido')
});

export default InformacaoPrazoSchemaDados;