import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import CondutoresForm from './Form/CondutoresForm';
import { somenteNumeros } from '../../../shared/utilities';

const styles = {

}

const initialValues = {
    nome: '',
    cpf: '___.___.___-__',
    inativo: false
}

class PureCondutoresCreateUpdate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {
            apolloClientProvider.client.query({ query: actions.GET_CONDUTOR, variables: { id: id }, fetchPolicy: "network-only" })
                .then(({ data: { motorista } }) => {
                    const resetData = {
                        id: motorista.id,
                        nome: motorista.nome ? motorista.nome : initialValues.nome,
                        cpf: motorista.cpfcnpj ? motorista.cpfcnpj : initialValues.cargo,
                        inativo: motorista.inativo
                    }
                    this.resetForm({values: resetData});
                });
        }
    }

    handleSubmit = (values, addHandlers) => {
        const id = this.props.match.params.id;

        const submitData = {
            id: id ? parseInt(id) : undefined,
            nome: values.nome,
            cpfcnpj: somenteNumeros(values.cpf),
            inativo: values.inativo
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_CONDUTOR,
                    variables: { motorista: submitData },
                    refetchQueries: [{query: actions.GET_CONDUTORES}],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Condutor cadastrado!');
                    this.props.history.push('/condutores');
                })
                .catch(res => {
                    const errors = res.networkError.result.errors.reduce((res, err) => [...res, err.message],[]);
                    actions.OPEN_SNACKBAR(`Erro: ${errors}`, true, true);
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <CondutoresForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </CondutoresForm>
                {this.state.errors}
            </div>
        );
    };
}

export default withStyles(styles)(withRouter(PureCondutoresCreateUpdate));