import React from 'react';
import { TextField, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import InfoIcon from '@material-ui/icons/Info'

import { fetchFromObject } from '../../../../../../../shared/utilities'
import { alertMessages } from '../../../../../../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    textField: {
        width: 395,
    },
    tooltip: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(3)
    },
    endAdornmentButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'unset',
        },
        marginTop: '4px'
    }
}));

const emptyString = '';

const mask = createNumberMask({
    prefix: emptyString,
    includeThousandsSeparator: false,
    integerLimit: 36,
});

function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const CodigoBarrasField = (props) => {
    const classes = useStyles();
    const { formik,  disabled, nomeCampo } = props;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);
    const label = "Segundo Codigo de Barras"

    return <React.Fragment>
            <TextField
            InputProps={{ inputComponent: MaskNumbersOnly }}
            className={classes.textField}
            id={nomeCampo}
            label={label}
            value={fetchFromObject(formik.values, nomeCampo)}
            error={Boolean(
                touched && error
            )}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting || disabled}
            margin="normal"
            helperText={touched && error && (
                error
            )}
            FormHelperTextProps={{ error: true }}
        />
        <div className={classes.tooltip}>
            <Tooltip title={alertMessages.INFO_CODBARRAS} 
            aria-label={alertMessages.INFO_CODBARRAS}>
                <IconButton className={classes.endAdornmentButton}>
                    <InfoIcon fontSize="small" />
                </IconButton>    
            </Tooltip>
        </div>
    </React.Fragment>
        
    
}

export default CodigoBarrasField;
