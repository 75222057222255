import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { makeStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        minWidth: 275,
    }
}));

/**
 * Função para setar o valor do Data e Hora no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik) => {
    formik.setFieldValue("dataHoraInicioViagem", event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched("dataHoraInicioViagem", true, true);
};

const DataHoraInicioViagemField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;
    
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDateTimePicker
                name='dataHoraInicioViagem'
                id='dataHoraInicioViagem'
                value={formik.values.dataHoraInicioViagem}
                onChange={(event) => handleChange(event, formik)}
                onBlur={() => handleBlur(formik)}
                ampm={false}
                autoOk
                clearable
                format="DD/MM/YYYY HH:mm"
                // handle clearing outside => pass plain array if you are not controlling value outside
                // mask={value =>
                //     value
                //         ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, ' ', /[0-2]/, /\d/, ':', /\d/, /\d/]
                //         : []
                // }
                cancelLabel='Cancelar'
                clearLabel='Limpar'
                invalidDateMessage='Data inválida'
                className={classes.textField}
                label="Data e Hora do Início da Viagem"
                error={Boolean(
                    formik.touched.dataHoraInicioViagem && formik.errors.dataHoraInicioViagem
                )}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={formik.errors.dataHoraInicioViagem &&
                    formik.touched.dataHoraInicioViagem && (
                        formik.errors.dataHoraInicioViagem
                    )}
                FormHelperTextProps={{ error: true }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DataHoraInicioViagemField;
