import Yup from '../../../../../../../yup';

const DocumentoDados = Yup.object().shape({
    tipo: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string()
    }),
    municipio: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string()
    }),
    chave: Yup.string()
        .min(44)
        .max(44)
        .required(),
    segcodbarra: Yup.string()
        .when('tipo.value', (value, schema) => {
            return value > 2 ? schema.required() : schema.notRequired();
        })
        .min(36)
        .max(36) 
});

export default DocumentoDados;