import React, { Component } from "react";
import { Formik, Form } from "formik"
import PropTypes from 'prop-types';
import { withStyles, IconButton, Typography, Fab, Paper, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../yup';
import * as cores from '../../shared/cores'
import SerieMdfeField from "./Fields/SerieMdfeField";
import NumeroMdfeField from "./Fields/NumeroMdfeField";
import TipoAmbienteField from "./Fields/TipoAmbienteField";
import VersaoServicoField from "./Fields/VersaoServicoField";
import { SUPPORTED_CERTIFICATE_FORMATS } from "../../shared/mdfe";
import TimeoutField from "./Fields/TimeoutField";
import EstadoField from "./Fields/EstadoField";
import SerialField from "./Fields/SerialField";
import EmitenteArquivoDialog from "./EmitenteArquivoDialog";
import ValidadeField from "./Fields/ValidadeField";
import TipoTransportadorField from "./Fields/TipoTransportadorField";
import TipoEmitenteField from "./Fields/TipoEmitenteField";
import RntrcField from "./Fields/RntrcField";

const styles = theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
    },
    buttonConfirmContainer: {
        display: 'flex',
        margin: 20,
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    buttonClose: {
        margin: 4,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    panel: {
        margin: 20,
        paddingBottom: 4,
        display: 'flex',
        flexWrap: 'wrap',
    },
    subtitulo: {
        color: cores.titulo,
        width: '100%',
        margin: 8,
        marginBottom: 0,
    },
    buttonEdit: {
        height: 48,
        alignSelf: 'flex-end',
        width: 48
    }
});

const EmitenteSchema = Yup.object().shape({
    serial: Yup
        .string(),
    seriemdfe: Yup
        .number()
        .min(0)
        .max(999)
        .required(),
    numeromdfe: Yup
        .number()
        .min(0)
        .max(999999999)
        .required(),
    timeout: Yup
        .number()
        .min(0)
        .max(120),
    rntrc: Yup
        .string()
        .min(8)
        .max(8),
    tipoAmbiente: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
        enumString: Yup.string()
    }).required(),
    versaoServico: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
        enumString: Yup.string()
    }).required(),
    tipoTransportador: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
        enumString: Yup.string()
    }),
    tipoEmitente: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
        enumString: Yup.string()
    }).required(),
    estado: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required(),
    arquivo: Yup
        .mixed()
        .test(
            "formatoArquivo",
            "Formato não suportado",
            value => value ? SUPPORTED_CERTIFICATE_FORMATS.includes(value.type) : true
        ),
    senha: Yup.string()
        .when('arquivo', {
            is: value => value,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
    confirmacaoSenha: Yup.string()
        .when('senha', {
            is: value => value && (value.length !== 0),
            then: Yup.string().required().oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
            otherwise: Yup.string().notRequired().oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
        }),
});

export class EmitenteForm extends Component {
    state = {
        arquivoDialogOpen: false,
    }

    handleDialogOpen = () => {
        this.setState({ arquivoDialogOpen: true })
    }

    handleDialogClose = () => {
        this.setState({ arquivoDialogOpen: false })
    }

    handleDialogSubmit = (values, formikProps) => {
        Object.keys(values).forEach(key => formikProps.setFieldValue(key, values[key]))
        this.setState({ arquivoDialogOpen: false })
    }

    render() {
        const { classes } = this.props;

        return (
            <Formik
                initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={EmitenteSchema}
                onSubmit={(values, formikBag) => {
                    const addHandlers = promise =>
                        promise.then(
                            result => {
                                formikBag.resetForm();
                                formikBag.setSubmitting(false);

                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        );
                    return this.props.onSubmit(values, addHandlers);
                }}>
                {formikProps => {
                    const form = (
                        <React.Fragment>
                            <div className={classes.title}>
                                <Typography color="inherit" variant="h6">
                                    Configurações de Emitente
                                </Typography>
                                <IconButton
                                    className={classes.buttonClose}
                                    aria-label="Cancelar"
                                    onClick={this.props.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Form className={classes.form}>
                                <div className={classes.fieldsContainer}>
                                    <Paper className={classes.panel} elevation={1}>
                                        <Typography color="inherit" variant="subtitle1" className={classes.subtitulo}>
                                            MDF-e
                                        </Typography>
                                        <SerieMdfeField formik={formikProps} />
                                        <NumeroMdfeField formik={formikProps} />
                                        <RntrcField formik={formikProps} />
                                        <TimeoutField formik={formikProps} />
                                        <EstadoField formik={formikProps} />
                                        <TipoAmbienteField formik={formikProps} />
                                        <TipoTransportadorField formik={formikProps} />
                                        <TipoEmitenteField formik={formikProps} />
                                        <VersaoServicoField formik={formikProps} disabled />
                                    </Paper>
                                    <Paper className={classes.panel} elevation={1}>
                                        <Typography color="inherit" variant="subtitle1" className={classes.subtitulo}>
                                            Certificado Digital
                                        </Typography>
                                        <SerialField formik={formikProps} disabled />
                                        <ValidadeField formik={formikProps} disabled />
                                        <EmitenteArquivoDialog
                                            open={this.state.arquivoDialogOpen}
                                            handleSubmit={(values) => this.handleDialogSubmit(values, formikProps)}
                                            handleClose={this.handleDialogClose} />
                                        <Tooltip title="Alterar Certificado Digital" aria-label="Alterar Certificado Digital">
                                            <IconButton
                                                className={classes.buttonEdit}
                                                aria-label="Alterar Certificado Digital"
                                                onClick={this.handleDialogOpen}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Paper>
                                </div>
                                <div className={classes.buttonConfirmContainer}>
                                    <Fab
                                        type="submit"
                                        color="primary"
                                        aria-label="Adicionar">
                                        <CheckIcon />
                                    </Fab>
                                </div>
                            </Form>
                        </React.Fragment>
                    );
                    return this.props.children({
                        form,
                        isDirty: formikProps.dirty,
                        isSubmitting: formikProps.isSubmitting,
                        setFieldValue: formikProps.setFieldValue,
                        setFieldError: formikProps.setFieldError,
                        setFieldTouched: formikProps.setFieldTouched,
                        handleSubmit: formikProps.handleSubmit,
                        handleCancel: formikProps.resetForm,
                    });

                }
                }
            </Formik>
        );
    }
}

EmitenteForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default withStyles(styles)(EmitenteForm);