import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
}));

const DescricaoField = (props) => {
    const classes = useStyles();
    const { formik, disabled, multiline, required } = props;

    const campo = `xProd`;
    const label = 'Descrição do Produto';

    const error = getIn(formik.errors, campo);
    const touched = getIn(formik.touched, campo);

    return (
        <React.Fragment>
                <TextField
                    className={classes.textField}
                    id={campo}
                    label={label}
                    multiline={multiline}
                    required={required}
                    value={fetchFromObject(formik.values, campo) ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                        touched && error
                    )}
                    disabled={formik.isSubmitting || disabled}
                    helperText={touched && error && (
                        error
                    )}
                    FormHelperTextProps={{ error: true }}
                />
        </React.Fragment>
    )
}

export default DescricaoField;
