import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
}));

const UsuarioNomeField = (props) => {
    const classes = useStyles();
    const { formik } = props;
    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                required
                id="nome"
                label="Nome"
                value={formik.values.nome}
                error={Boolean(
                    formik.touched.nome && formik.errors.nome
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={formik.errors.nome &&
                    formik.touched.nome && (
                        formik.errors.nome
                    )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default UsuarioNomeField;
