import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
}));

const UsuarioDepartamentoField = (props) => {
    const classes = useStyles();

    const { formik } = props;

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id="departamento"
                label="Departamento"
                value={formik.values.departamento}
                error={Boolean(
                    formik.touched.departamento && formik.errors.departamento
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={formik.errors.departamento &&
                    formik.touched.departamento && (
                        formik.errors.departamento
                    )}
                FormHelperTextProps={{ error: true }}
            />

        </React.Fragment>
    )
}

export default UsuarioDepartamentoField;



