import Yup from '../../../../../yup';

const MDFeSchemaDocumentos = Yup.object().shape({
    carregamentoPosterior: Yup.boolean(),
    municipiosDescarregamento: Yup.array()
    .when('carregamentoPosterior', {
        is: false,
        then: Yup.array()
        .of(Yup.object().shape({
            municipio: Yup.object().shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
            })
                .required(),
            tipo: Yup.object().shape({
                value: Yup.number(),
                label: Yup.string(),
            })
                .required(),
            chave: Yup.string()
                .min(44)
                .max(44)
                .required()
        }))
        .min(1)
        .max(8000)
        .required()
    }),
    contratantes: Yup.array()
        .of(Yup.object())
});

export default MDFeSchemaDocumentos;