import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { makeStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { getIn } from 'formik';

import { fetchFromObject } from '../../../shared/utilities';

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        minWidth: 235,
    }
}));

/**
 * Função para setar o valor do campo no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 * @param {string} field - Nome do campo
 */
const handleChange = (event, formik, field) => {
    formik.setFieldValue(field, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik, field) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(field, true, true);
};

const ValidadeField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const nomeCampo = `validade`;
    const label = `Validade`;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDateTimePicker
                name={nomeCampo}
                id={nomeCampo}
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                onBlur={() => handleBlur(formik, nomeCampo)}
                ampm={false}
                autoOk
                clearable
                format="DD/MM/YYYY HH:mm"
                // handle clearing outside => pass plain array if you are not controlling value outside
                // mask={value =>
                //     value
                //         ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, ' ', /[0-2]/, /\d/, ':', /\d/, /\d/]
                //         : []
                // }
                cancelLabel='Cancelar'
                clearLabel='Limpar'
                invalidDateMessage='Data inválida'
                className={classes.textField}
                label={label}
                error={Boolean(touched && error)}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default ValidadeField;
