import gql from 'graphql-tag';

export const GET_MDFES = gql`
query mdfes($busca: String, $dhemissaoinicial: DateTime, $dhemissaofinal: DateTime, $situacao: [SituacaoMdfeEnum], $offset: Int, $limit: Int, $ordem: MdfeOrderEnum){
    mdfes (busca: $busca, dhemissaoinicial: $dhemissaoinicial, dhemissaofinal: $dhemissaofinal, situacao: $situacao, offset: $offset, limit: $limit, ordem: $ordem){
        node {
            ... on MdfeType {
                id
                serie
                numero
                chave
                datahoraemissao
                ufinicio
                uffim
                tipoemissao
                situacao
                mdfeinfosefaz {
                    nrec
                    cstat
                    xmotivo
                }
                carregamentoPosterior
                mdfeInfPag {
                    id
                }
            }
        }
        totalcount
    }
}
`;

export const GET_MDFE = gql`
query mdfe($id: Int!){
    mdfe( id: $id ){
        id
        infadfisco
        infcomplementar
        chave
        datahoraemissao
        datahorainicioviagem
        municipiodocs {
            id
            municipioid
            nomemunicipio
            documentos{
                chave
                tipodocumento
                segcodbarra
            }
        }
        mdfetotalizador {
            id
            pesototalcarga
            unidademedida
            valorcarga
        }
        mdfetransporterodoviario{
            id
            contratantes
            condutores {
                id
                motorista {
                    cpfcnpj
                    id
                    nome
                }
            }
            reboques {
                id
                reboque {
                    id
                    placa
                }
            }
            veiculo {
                id
                placa
            }
            ciots{
                cpfcnpj
                numerociot
            }
        }
        modelo
        municipioscarregamento {
            id
            municipioid
            nome
        }
        numero
        serie
        tipoemissao
        ufspercurso
        uffim
        ufinicio
        versao
        editavel
        tipoemitente
        seguros {
            id
            cnpjseguradora
            cpfcnpjresponsavel
            nomeseguradora
            numeroapolice
            numerosdasaverbacoes
            responsavel
        }
        carregamentoPosterior
        mdfeProdPred {
            id
            tpcarga
            xProd
            cEAN
            ncm
            cepCarregamento
            cepDescarregamento
        }
        mdfeInfPag {
            id
            xnome
            cpfCnpjId
            vcontrato
            indpag
            tpdocumento
            codbanco
            codagencia
            comp {
                id
                tpcomp
                vcomp
                xcomp
            }
            infPrazo {
                id
                nparcela
                vparcela
                dvenc
            }
        }
    }
}
`;



export const SET_MDFE = gql`
mutation adicionarOuAtualizarMdfe($mdfe: MdfeInput!) {
    adicionarOuAtualizarMdfe(mdfe: $mdfe) {
        id
        chave
    }
  }
`;

export const EMITIR_MDFE = gql`
mutation emitirMdfe($id: Int!){
    emitirMdfe(id: $id) {
        situacao
        mdfeinfosefaz{
            cstat
            nprot
            nrec
            tipoambiente
            xml
            xmlenvio
            xmotivo
        }
    }
}
`;

// const infeventoFragment = gql`
// fragment infeventoFrag on MDFeRetEventoMDFeType {
//     cstat
//     dhregevento
//     nprot
//     xevento
//     xmotivo
// }
// `;

export const ENCERRAR_MDFE = gql`
mutation encerrarMdfe($id: Int!, $cUf: EstadoEnum!, $cMun: Int!){
    encerrarMdfe(id: $id, cUf: $cUf, cMun: $cMun){
        cstat
        xmotivo
    }
}
`;

export const ENCERRAR_MDFE_CHAVE = gql`
mutation($chave: String!, $cuf: EstadoEnum!, $cmun: Int!){
    encerrarMdfePorChave(chave: $chave, cUf: $cuf, cMun: $cmun){
        versao
        cstat
        protmdfe{
            infprot{
                dhrecbto
            }
        }
    }
}
`;

export const EXCLUIR_MDFE = gql`
mutation excluirMdfe($id: Int!){
    excluirMdfe(id: $id)
}
`;

export const INCLUIR_CONDUTOR = gql`
mutation incluirCondutor($id: Int!, $idMotorista: Int!){
    incluirCondutor(id: $id, idMotorista: $idMotorista) {
        infevento{
            cstat
            dhregevento
            nprot
            xevento
            xmotivo
        }
    }
}
`;


export const INCLUIR_DFE = gql`
mutation($id: Int!, $infDoc: [MdfeInfDocInfInput]!){
    incluirDfe(id: $id, infDocs: $infDoc){
      infevento{
        nprot
        cstat
      }
    }
  }
`;

export const INCLUIR_PAGAMENTO = gql`
mutation($id: Int!, $qtdViagens: String!, $nroViagem: String!, $infPags: [InfPagInput]!){
    incluirPagamento(id: $id, qtdViagens: $qtdViagens, nroViagem: $nroViagem, infPags: $infPags){
      infevento{
        nprot
        cstat
      }
    }
  }
`;

export const CANCELAR_MDFE = gql`
mutation cancelarMdfe($id: Int!, $justificativa: String!){
    cancelarMdfe(id: $id, justificativa: $justificativa){
        xmotivo
        xmotivo
    }
}
`;

export const CONSULTAR_MDFE = gql`
query consultarSituacao($chave: String!){
    consultarSituacao(chave: $chave){
        cstat
        xmotivo
    }
}
`;

export const IMPRIMIR_MDFE = gql`
query imprimirMdfe($id: Int!){
    imprimirMdfe(id: $id)
}
`;

export const EXPORTAR_XML_MDFE = gql`
query exportarXmlMdfe($id: Int!){
    exportarXmlMdfe(id: $id)
}
`;