import { makeVar } from '@apollo/client';

export const startingPage = makeVar(false);

export const setStartingPage = (value) => startingPage(value);

export const startingPagePolicy = {
    startingPage: {
        read() {
            return startingPage();
        }
    }
}