import React, { useState } from 'react';
import { Table, TableBody, TableRow, TableCell, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import CommonTableToolbar from '../../UI/CommonTableToolbar/CommonTableToolbar';
import CommonTableHead from '../../UI/CommonTableHead/CommonTableHead';
import * as celltypes from '../../../shared/celltypes';
import { getSorting, mascaraCpf, mascaraCnpj, getAmountOfRowsToRender } from '../../../shared/utilities';
import LoginBackground from '../../UI/LoginBackground/LoginBackground';
import FullWidthSpinner from '../../UI/Spinner/FullWidthSpinner';
import { setAuth } from '../../../store/cache'

const dadosColuna = [
    { id: 'nome', type: celltypes.DEFAULT, numeric: false, padding: 'normal', label: 'Razão Social' },
    { id: 'nomefantasia', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Nome' },
    { id: 'cpfcnpj', type: celltypes.DEFAULT, numeric: false, padding: 'normal', label: 'Documento' },
]

const useStyles = makeStyles(theme => ({
    escolhaEmpresa: {
        [theme.breakpoints.up('sm')]: {
            height: '100vh',
            width: '100vw',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    tableWrapper: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100vh',
        },
        width: '70%',
        background: 'white',
    },
    row: {
        cursor: 'pointer',
    },
}));

const EscolhaEmpresa = (props) => {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('nome')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(getAmountOfRowsToRender(93, 108))
    const classes = useStyles()
    const { loading, error, data } = useQuery(actions.GET_LOGGED_USER);

    const handleRequestSort = (event, property) => {
        let newOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            newOrder = 'asc';
        }
        setOrder(newOrder)
        setOrderBy(property)
    }

    const handleClick = (empresaId, empresaNome) => {
        actions.SET_EMPRESA_HEADER(empresaId);
        apolloClientProvider.client
            .query({ query: actions.GET_AUTH })
            .then(({ data }) => {
                setAuth({...data.auth, empresaId, empresaNome});
                actions.SET_EMPRESA_HEADER(empresaId);
            });
    };

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };

    const filterDialog = null;

    const rowsPerPageOptions = [5, 10, 15]
    rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort((a, b) => a - b);


    let table;

    if (loading) {
        table = <FullWidthSpinner />
    }

    if (!loading && !error) {
        const { usuarioLogado } = data;

        if (!usuarioLogado.clienteusuarios)
            return <div data-test='component-escolha-empresa' className={classes.escolhaEmpresa}>
                <LoginBackground />
                <div className={classes.tableWrapper}>
                    <CommonTableToolbar
                        noSearch
                        title={"Não há empresas disponíveis para realizar o login"}
                        filterDialog={filterDialog} />
                </div>
            </div>

        const tableData = usuarioLogado.clienteusuarios && usuarioLogado.clienteusuarios.map(clienteusuario => {
            return {
                id: clienteusuario.cliente.id,
                nome: clienteusuario.cliente.nome,
                nomefantasia: clienteusuario.cliente.nomefantasia,
                cpfcnpj: clienteusuario.cliente.cpfcnpj.length === 11
                    ? mascaraCpf(clienteusuario.cliente.cpfcnpj)
                    : mascaraCnpj(clienteusuario.cliente.cpfcnpj),
            }
        });

        table = <React.Fragment>
            <Table className={classes.table} aria-labelledby="tableTitle">
                <CommonTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={tableData.length}
                    columnData={dadosColuna}
                />
                <TableBody>
                    {loading
                        ? <FullWidthSpinner />
                        : tableData
                            .sort(getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(n => (
                                <TableRow
                                    hover
                                    className={classes.row}
                                    onClick={() => handleClick(n.id, n.nome)}
                                    tabIndex={-1}
                                    key={n.id}>
                                    {
                                        dadosColuna.map(coluna => {
                                            switch (coluna.type) {
                                                case (celltypes.DEFAULT):
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        component="th"
                                                        scope="row"
                                                        size="small"
                                                        padding={coluna.padding}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                case (celltypes.NUMERIC):
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        padding={coluna.padding}
                                                        size="small"
                                                        align="right">
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                                default:
                                                    return (<TableCell
                                                        key={n.id + n[coluna.id] + coluna.id}
                                                        component="th"
                                                        size="small"
                                                        scope="row"
                                                        padding={coluna.padding}>
                                                        {n[coluna.id]}
                                                    </TableCell>)
                                            }
                                        })
                                    }
                                </TableRow>
                            )
                            )
                    }
                </TableBody>
            </Table>
            <div className={classes.pagination}>
                <TablePagination
                    classes={{ input: classes.pagination__input }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={"Itens por página"}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Página Seguinte',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </React.Fragment>
    }

    return (
        <div data-test='component-escolha-empresa' className={classes.escolhaEmpresa}>
            <LoginBackground />
            <div className={classes.tableWrapper}>
                <CommonTableToolbar
                    noSearch
                    title={"Selecione uma empresa para continuar"}
                    filterDialog={filterDialog} />
                {table}
            </div>
        </div >
    )
}
export default EscolhaEmpresa;