import React from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SelectWrapped from '../../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import { fetchFromObject } from '../../../../../../../shared/utilities';
import { mdfeTipoCarga} from '../../../../../../../shared/mdfe';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 155,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

const handleChange = (event, formik, campo) => {
    formik.setFieldValue(campo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const TipoCargaField = (props) =>{
    const classes = useStyles();

    const { formik, disabled } = props;

    const nomeCampo = 'tpcarga';

    const opcoes = mdfeTipoCarga;
    const label= 'Tipo da Carga';
    const value = fetchFromObject(formik.values, nomeCampo);

    return (
        <FormControl margin="normal" className={classes.formControl}>
                <SelectWrapped
                    disabled={formik.isSubmitting || disabled}
                    id={nomeCampo}
                    label={label}
                    options={opcoes}
                    value={value}
                    handleChange={(event) => handleChange(event, formik, nomeCampo)}
                />
        </FormControl>
    );
}

export default TipoCargaField;
