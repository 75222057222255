import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Background from '../../../content/images/login_background.jpg'

const useStyles = makeStyles(theme => ({
    background: {
        background: `url(${Background}) center/cover`,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: -1,
    },
}));

const LoginBackground = (props) => {
    const classes = useStyles();
    return <div className={classes.background} />
};
 
export default LoginBackground;