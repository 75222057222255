import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import SelectWrapped from '../../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 245
    },
}))

const handleBlur = (formik, nomeCampo) => {
    formik.setFieldTouched(nomeCampo, true, true);
}

const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const MunicipioField = (props) => {
    const classes = useStyles();

    const { formik, opcoes, disabled, nomeCampo } = props;
    const label = "Município"

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <FormControl required margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                value={fetchFromObject(formik.values,nomeCampo)}
                handleChange={(event) => handleChange(event, formik, nomeCampo)}
                handleBlur={() => handleBlur(formik, nomeCampo)}
                required
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    )
};

export default MunicipioField;