import Yup from '../../../../../yup';

const EncerrarPorChaveDados = Yup.object().shape({
    uf: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string()
    }),
    municipio: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string()
    }),
    chave: Yup.string()
        .min(44)
        .max(44)
        .required()
});

export default EncerrarPorChaveDados;