import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        width: '100%',
        textAlign: 'center',
        color: 'white'
    },
}));

const FullWidthSpinner = (props) => {
    const classes = useStyles();
    const { containerProps, size } = props;
    return <div className={classes.loading} {...containerProps}>
        <CircularProgress
            size={size ? size : 36}
            className={classes.progress} />
    </div>
}

export default FullWidthSpinner;