import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { getIn } from 'formik';

import Spinner from '../../../../../../components/UI/Spinner/FullWidthSpinner';
import * as actions from '../../../../../../store/actions'
import SelectWrapped from '../../../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import { ErrorPage } from '../../../../../../components/ErrorBoundary/ErrorBoundary';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 286,
        width: '100%'
    },
}))

const MunicipiosField = props => {
    const classes = useStyles();
    const {
        handleChange,
        selected,
        formik,
        ufId,
        label,
        propName,
        required,
        disabled
    } = props;
    const { loading, graphQlError, data } = useQuery(actions.GET_MUNICIPIOS, { variables: { id: ufId } });

    const error = getIn(formik.errors, propName);
    const touched = getIn(formik.touched, propName);

    const nomeCampo = propName;

    if (loading)
        return <Spinner />;

    if (graphQlError)
        return <ErrorPage />
        
    const { municipios } = data;
    const opcoes = municipios.map(({ id, nome }) => (
        {
            value: id,
            label: nome
        }
    ));

    return (
        <FormControl required={required} margin="normal" className={classes.formControl}>
            <SelectWrapped
                disabled={formik.isSubmitting || disabled}
                id={nomeCampo}
                label={label}
                placeholder={label}
                options={opcoes}
                value={selected}
                handleChange={handleChange}
                required={required}
                error={Boolean(touched && error)}
            />
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    );
};

export default MunicipiosField;


