import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: '100%',
        display: 'flex',
    }
}));

const moedaRealMask = createNumberMask({
    prefix: 'R$',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 13,
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
});

function ValorMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={moedaRealMask}
            guide={false}

        />
    );
}
ValorMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const ValorField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, label } = props;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <React.Fragment>
            <TextField
                InputProps={{
                    inputComponent: ValorMask,
                }}
                className={classes.textField}
                required
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                margin="normal"
                error={Boolean(
                    touched && error
                )}
                helperText={touched && error && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />
        </React.Fragment>
    )
}

export default ValorField;
