import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, TextField, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, getIn } from 'formik';

import Yup from '../../../yup';
import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions';

const useStyles = makeStyles(theme => ({
    buttonConfirm: {
        margin: theme.spacing(1)
    },
    textField: {
        minWidth: 400,
    },
    actionButtonProgress: {
        position: 'absolute',
        top: 5,
        left: 5,
        zIndex: 1,
    },
    actionButtonContainer: {
        display: 'inline-block',
        position: 'relative',
    },
    dialogActions: {
        margin: 12
    }
}))

const handleSubmit = (values, id, close) => {
    return apolloClientProvider.client.mutate(
        {
            mutation: actions.CANCELAR_MDFE,
            variables: { id, justificativa: values.justificativa },
            refetchQueries: [{ query: actions.GET_MDFES }],
        })
        .then(({ data }) => {
            close();
            actions.OPEN_SNACKBAR('MDFe cancelado!', false, true);
        })
}

const CancelarDialog = (props) => {
    const classes = useStyles();

    const { mdfe, open, handleClose } = props

    if (!mdfe) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Cancelamento de MDF-e</DialogTitle>
            <Formik
                initialValues={{ justificativa: '' }}
                onSubmit={(values, formikBag) => {
                    handleSubmit(values, mdfe.id, handleClose)
                        .then(
                            result => {
                                formikBag.setSubmitting(false);
                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        )
                }}
                validationSchema={Yup.object().shape({
                    justificativa: Yup.string()
                        .min(15)
                        .max(255)
                        .required()
                })}>
                {formikProps => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    } = formikProps;

                    const nomeCampo = `justificativa`;

                    const error = getIn(errors, nomeCampo);
                    const touch = getIn(touched, nomeCampo);

                    return <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <DialogContentText>
                                Você deseja cancelar o MDF-e de chave '{mdfe.chave}'?
                                <br /><br />
                                Justificativa de Cancelamento:
                            </DialogContentText>
                            <TextField
                                fullWidth
                                multiline
                                className={classes.textField}
                                id={nomeCampo}
                                label={null}
                                value={values[nomeCampo]}
                                error={Boolean(
                                    touch && error
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={isSubmitting}
                                margin="normal"
                                helperText={touch && error && (
                                    error
                                )}
                                FormHelperTextProps={{ error: true }}
                            />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Tooltip title="Fechar" aria-label="Fechar" placement="top">
                                <Fab
                                    className={classes.buttonConfirm}
                                    onClick={handleClose}
                                    color="primary"
                                    aria-label="Fechar">
                                    <CloseIcon />
                                </Fab>
                            </Tooltip>
                            <div className={classes.actionButtonContainer}>
                                <Tooltip title="Confirmar cancelamento" aria-label="Confirmar cancelamento" placement="top">
                                    <Fab
                                        disabled={formikProps.isSubmitting}
                                        className={classes.buttonConfirm}
                                        type="submit"
                                        color="primary"
                                        aria-label="Enviar">
                                        <CheckIcon />
                                    </Fab>
                                </Tooltip>
                                {formikProps.isSubmitting && <CircularProgress size={62} className={classes.actionButtonProgress} />}
                            </div>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    )
};

export default CancelarDialog;