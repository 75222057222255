import Yup from '../../../../../../../yup';

const ComponenteSchemaDados = Yup.object().shape({
    xcomp: Yup.string(),
    tpcomp: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
    }).required(),
    vcomp: Yup
        .string()
        .required()
});

export default ComponenteSchemaDados;