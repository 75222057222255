import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'
import { Formik, Form } from 'formik';

import Yup from '../../../../../../yup';
import DocumentoField from './Fields/DocumentoField';
import TipoDocumentoField from './Fields/TipoDocumentoField';
import CiotField from './Fields/CiotField';

const useStyles = makeStyles(theme => ({
    buttonAdd: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
    form: {
        display: 'flex',
    },
}))

const CiotsAddForm = props => {
    const classes = useStyles();

    const { handleSubmit, disabled, initialValues } = props

    return (<Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={Yup.object().shape({
            documento: Yup.string()
                .CpfCnpj()
                .required(),
            tipoDocumento: Yup.object().shape({
                value: Yup.bool(),
                label: Yup.string(),
            }),
            ciot: Yup.string()
                .min(12)
                .max(12)
                .required()
        })}>
        {formikProps => {
            const {
                handleSubmit,
            } = formikProps;

            const form =
                <Form className={classes.form}>
                    <TipoDocumentoField
                        disabled={disabled}
                        formik={formikProps}
                        label={`Tipo de Documento`}
                        nomeCampo={`tipoDocumento`} />
                    <DocumentoField
                        disabled={disabled}
                        formik={formikProps}
                        label={`Documento`}
                        tipoPessoa={formikProps.values.tipoDocumento.value}
                        nomeCampo={`documento`} />
                    <CiotField
                        formik={formikProps} 
                        disabled={disabled}
                        nomeCampo={`ciot`} 
                        label={`Ciot`} 
                        />
                    {!formikProps.isSubmitting && (
                        <IconButton
                            disabled={formikProps.isSubmitting || disabled}
                            className={classes.buttonAdd}
                            aria-label="Adicionar"
                            onClick={() => handleSubmit(formikProps.values)}>
                            <AddIcon />
                        </IconButton>
                    )}
                </Form>
            return props.children({
                form,
                isDirty: formikProps.dirty,
                isSubmitting: formikProps.isSubmitting,
                setFieldValue: formikProps.setFieldValue,
                setFieldError: formikProps.setFieldError,
                setFieldTouched: formikProps.setFieldTouched,
                handleSubmit: formikProps.handleSubmit,
                handleCancel: formikProps.resetForm,
            });
        }}
    </Formik>
    )
}

export default CiotsAddForm;