import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const emptyString = '';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: '100%',
    }
}));

const mask = createNumberMask({
    prefix: emptyString,
    includeThousandsSeparator: false,
    integerLimit: 8,
    allowLeadingZeroes: true
});

function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const NcmField = (props) => {
    const classes = useStyles();
    const { formik, disabled, multiline, required } = props;

    const campo = `ncm`;
    const label = 'NCM';

    const error = getIn(formik.errors, campo);
    const touched = getIn(formik.touched, campo);

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                InputProps={{ inputComponent: MaskNumbersOnly }}
                id={campo}
                label={label}
                required={required}
                multiline = {multiline}
                value={fetchFromObject(formik.values, campo) ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                    touched && error
                )}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={touched && error && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />
        </React.Fragment>
    )
}

export default NcmField;
