import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 66,
    }
}));

const ModeloField = (props) => {
    const classes = useStyles();
    const { formik } = props;
    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                id="modelo"
                label="Modelo"
                type='number'
                required
                value={formik.values.modelo}
                error={Boolean(
                    formik.touched.modelo && formik.errors.modelo
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || props.disabled}
                margin="normal"
                helperText={formik.errors.modelo &&
                    formik.touched.modelo && (
                        formik.errors.modelo
                    )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default ModeloField;
