import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import { listaUF } from '../../../shared/utilities';
import EncerrarPorChaveForm from './Form/EncerrarPorChaveForm';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    }
})

const initialValues = {
    chave: '',
    uf: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    municipio: {}
}

const EncerrarPorChave = () => {
    const history = useHistory()
    const classes = useStyles();
    let resetForm = null;
    useEffect(() => {
        apolloClientProvider.client.query({ query: actions.GET_CONFIGURACAO, fetchPolicy: "network-only" })
            .then(({ data: { configuracao } }) => {
                const uf = configuracao.configuracaomdfe.estado && {
                    label: configuracao.configuracaomdfe.estado,
                    value: listaUF.find(e => e.sigla === configuracao.configuracaomdfe.estado).id
                };

                resetForm({ values: { ...initialValues, uf } });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertDate = (date) => {
        let day = '' + date.getDay();
        day = day.length === 1 ? '0' + day : day;

        let month = '' + date.getMonth() + 1;
        month = month.length === 1 ? '0' + month : month;

        const year = date.getFullYear();

        var dat = `${date.getHours()}:${date.getMinutes()} `;
        dat += `${day}/${month}/${year}`

        return dat;
    }

    const handleSubmit = (values, addHandlers) => {
        const submitData = {
            chave: values.chave,
            mun: values.municipio.value,
            uf: values.uf.label
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.ENCERRAR_MDFE_CHAVE,
                    variables: {
                        chave: submitData.chave,
                        cuf: submitData.uf,
                        cmun: submitData.mun
                    }
                })
                .then(({ data }) => {
                    if (data.encerrarMdfePorChave.cstat === 132) {
                        const date = new Date(data.encerrarMdfePorChave.protmdfe.infprot.dhrecbto);
                        const dat = convertDate(date);
                        actions.OPEN_SNACKBAR(`MDFe Encerrada às  ${dat}!`, false, true);
                    }
                }).catch(res => {
                    actions.OPEN_SNACKBAR(`Erro: ${res}`, true, true);
                })
        )
    }

    const handleClose = () => {
        history.goBack();
    }

    return (
        <div className={classes.container}>
            <EncerrarPorChaveForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                handleClose={handleClose}>
                {({ form, isDirty, isSubmitting, handleCancel }) => (
                    <React.Fragment>
                        {resetForm = handleCancel}
                        {form}
                    </React.Fragment>
                )}
            </EncerrarPorChaveForm>
        </div >
    );
};


export default EncerrarPorChave;