import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 140,
        display: 'flex',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

const emptyString = '';

const mask = createNumberMask({
    prefix: emptyString,
    includeThousandsSeparator: false,
    integerLimit: 12,
    allowLeadingZeroes: true,
});


function MaskNumbersOnly(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
        />
    );
}
MaskNumbersOnly.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const CiotField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, label } = props;

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    //Máscara para aceitar apenas números como entrada
    const inputComponent = MaskNumbersOnly;

    return (
        <React.Fragment>
            <FormControl margin="normal" className={classes.formControl}>
                <InputLabel htmlFor={nomeCampo}>{label}</InputLabel>
                <Input
                    inputComponent={inputComponent}
                    disabled={formik.isSubmitting || disabled}
                    value={fetchFromObject(formik.values, nomeCampo)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id={nomeCampo}
                    error={Boolean(touched && error)}
                    required
                    FormHelperTextProps={{ error: true }}
                />
                <FormHelperText
                    className={classes.mensagemErro}
                    error={Boolean(touched && error)}>
                    {error && touched && (
                        error
                    )}
                </FormHelperText>
            </FormControl>
        </React.Fragment>
    )
}

export default CiotField;
