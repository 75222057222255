import Yup from '../../../../../../../yup';

const PagamentoSchemaDados = Yup.object().shape({
    xnome: Yup.string()
        .min(2)
        .max(60),
    tipoDocumento: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
    }),
    cpfCnpjId: Yup.string()
    .when('tipoDocumento', (tipoDocumento, schema) => tipoDocumento.value > 2 ? 
                schema.IdEstrangeiro() 
                : schema.CpfCnpj())
                .required(),
    vcontrato: Yup.string()
                .ValorMonetario()
                .required(),
    indpag: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required(),
    codbanco: Yup.string()
            .matches(/[0-9]{3,5}/, 'Valor não Permitido')
            .min(3)
            .max(5)
            .required(),
    codagencia: Yup.string()
            .matches(/[0-9]{1,10}/, 'Valor não Permitido')
            .min(1)
            .max(10)
            .required(),
    comp: Yup.array()
            .min(1)
            .required(),
    infPrazo: Yup.array()
            .when('indpag', (indpag, schema) => 
            indpag.value === 1 ?
            schema.min(1).required() : 
            schema.notRequired())   
});

export default PagamentoSchemaDados;