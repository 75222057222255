import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 160,
        display: 'flex',
    }
}));

function MaskCNPJ(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            showMask
        />
    );
}
MaskCNPJ.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function MaskCPF(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            showMask
        />
    );
}
MaskCPF.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const DocumentoField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;

    const nomeCampo = "documento";

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    //Altera a máscara dependendo do Tipo de Pessoa
    const tipoPessoa = formik.values.tipoDocumento && formik.values.tipoDocumento.value;
    const inputComponent = tipoPessoa ? MaskCNPJ: MaskCPF;
    const label = tipoPessoa ? 'CNPJ' : 'CPF';

    return (
        <React.Fragment>
            <TextField
                InputProps={{ inputComponent }}
                className={classes.textField}
                required
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default DocumentoField;
