import React from 'react';
import { FormControl, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import InfBancoField from './InfBancoField';

const useStyles = makeStyles(theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: '4px'
    },
    panel: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
}));

const InformacaoBancoField = (props) => {
    const classes = useStyles();

    const { formik, disabled } = props;
    
    const codbanco = `codbanco`;
    const codagencia = `codagencia`;
  
    return(
        <React.Fragment>
            <Paper className={classes.panel}>
                <Typography className={classes.title} variant="subtitle2" color="inherit">Informações do Banco</Typography>
                <FormControl className={classes.formControl}>
                    <InfBancoField 
                        formik={formik}
                        disabled={formik.isSubmitting || disabled}
                        nomeCampo={codbanco}
                        label={`Código do Banco`}
                    />
                    <InfBancoField 
                        formik={formik}
                        disabled={formik.isSubmitting || disabled}
                        nomeCampo={codagencia}
                        label={`Código da Agência`}
                    />
                </FormControl>
            </Paper>
        </React.Fragment>
    );

}

export default InformacaoBancoField;