import React from 'react';
import { Tooltip, Typography, Icon } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Alert = props => {
    const { mensagem } = props
    return (
        <Tooltip title={<React.Fragment>
            <Typography variant="body2">
                {mensagem}
            </Typography>
        </React.Fragment>}>
            <Icon className={props.classes.iconContent}>
                <InfoOutlinedIcon fontSize="medium" />
            </Icon>
        </Tooltip>)
}

export default Alert;