import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
        display: 'flex',
    }
}));

const InfBancoField = (props) => {
    const classes = useStyles();
    const { formik, disabled, nomeCampo, label } = props;

    const error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
        <React.Fragment>
                <TextField
                        className={classes.textField}
                        id={nomeCampo}
                        required
                        label={label}
                        value={fetchFromObject(formik.values, nomeCampo)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting || disabled}
                        FormHelperTextProps={{ error: true }}
                        error={Boolean(
                            touched && error
                        )}
                        helperText={touched && error && (
                            error
                        )}
                    />
        </React.Fragment>
    )
}

export default InfBancoField;
