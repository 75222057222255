import gql from 'graphql-tag';

import apolloClientProvider from '../../apolloClientProvider';
import axios from '../../axios-api';
import { SET_STARTING_PAGE } from './startingPage';
import { setAuth } from '../cache'

export const GET_AUTH = gql`
{
  auth @client {
    id
    username
    nome
    foto
    expires
    token
    empresaId
    empresaNome
    autoLogin
    }
}
`;

export const GET_LOGGED_USER = gql`
{
    usuarioLogado {
        clienteusuarios{
            cliente{
                id
                nome
                nomefantasia
                cpfcnpj
            }
        }
    }
}
`;

export const SET_AUTH = setAuth;

export const CHECK_AUTH = (location = false) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.autoLogin)
        LOGOUT();
    else {
        const expirationDate = new Date(user.expires);
        if (expirationDate < new Date()) {
            LOGOUT();
        }
        else {
            SET_AUTH({ ...user });
            CHECK_AUTH_TIMEOUT(user.expires);
            SET_STARTING_PAGE(location);
            SET_AUTH_HEADERS(user.token);
            SET_EMPRESA_HEADER(user.empresaId);
            if (!user.autoLogin) {
                window.onbeforeunload = function () {
                    localStorage.removeItem('user');
                    return '';
                };
            }
        }
    }
};

export const CHECK_AUTH_TIMEOUT = (expirationTime) => {
    setTimeout(() => {
        LOGOUT();
    }, (new Date(expirationTime) - new Date()));
}

export const SET_AUTH_HEADERS = (token) => {
    axios.defaults.headers.Authorization = 'Bearer ' + token;
}

export const SET_EMPRESA_HEADER = (empresaId) => {
    axios.defaults.headers.Empresa = empresaId;
}

export const LOGOUT = () => {
    axios.post('/auth/logout')
        .then(() => {
            localStorage.removeItem('user');
            apolloClientProvider.client.resetStore();
            window.location.reload();
        })
        .catch(error => {
            if(error.response.status !== 401){
                console.log(error)
            }
        })
}