import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dotIcon: {
        width: 6,
        height: 6,
        display: 'inline-block',
        position: 'absolute',
        margin: 10,
        borderRadius: '100%',
        background: 'rgba(200,200,200,1)',
    }
}))

const ActionIconPlaceholder = (props) => {
    const classes = useStyles();

    return <div className={classes.dotIcon + ' icon-placeholder'} />
};

export default ActionIconPlaceholder;