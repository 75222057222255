import React from 'react';
import { TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import InfoIcon from '@material-ui/icons/Info'

import { fetchFromObject } from '../../../../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 200,
        display: 'flex',
    }
}));

const NumerosAverbacoesField = (props) => {
    const classes = useStyles();

    const { formik, disabled, nomeCampo, label } = props;

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    return (
            <TextField
                className={classes.textField}
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title="Separar averbações por vírgula" aria-label="Separar averbações por vírgula">
                                <IconButton className={classes.endAdornmentButton}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                }}
            />
    )
}

export default NumerosAverbacoesField;