import Yup from '../../../../../yup';

const MDFeSchemaTotalizadores = Yup.object().shape({
    valor: Yup
        .string()
        .required(),
    unidade: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }),
    pesoBruto: Yup.string()
        .matches(/0|0\.[0-9]{4}|[1-9]{1}[0-9]{0,10}(\.[0-9]{4})?/)
        .required(),
    informacoesFisco: Yup
        .string()
        .max(2000),
    informacoesContribuinte: Yup
        .string()
        .max(5000),
    seguros: Yup.array()
        .of(Yup.object().shape({
            id: Yup.number(),
            responsavel: Yup.object().shape({
                value: Yup.number(),
                label: Yup.string(),
            }).required(),
            cpfcnpjresponsavel: Yup.string()
                .when('responsavel.value', {
                    is: 2,
                    then: Yup.string().required(),
                    otherwise: Yup.string().notRequired(),
                })
                .CpfCnpj(),
            nomeseguradora: Yup.string().max(30),
            cnpjseguradora: Yup.string().CpfCnpj(),
            numeroapolice: Yup.string().max(20),
            numerosdasaverbacoes: Yup.string(),
            tipoDocumento: Yup.object().shape({
                value: Yup.bool(),
                label: Yup.string(),
            }),
        })),
});

export default MDFeSchemaTotalizadores;