import React from 'react';
import { Avatar, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import avatarPlaceholder from '../../../../content/images/avatarUserCreatePlaceholder.svg'
import alterar from '../../../../content/images/avatarUserCreateAlterar.svg';

const avatarSize = 180;

const useStyles = makeStyles(theme => ({
    avatar: {
        margin: theme.spacing(1),
        transition: '.5s ease',
        backfaceVisibility: 'hidden',
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 1,
        '&:hover': {
            opacity: 0.3
        }
    },
    alterar: {
        margin: theme.spacing(1),
        transition: '.5s ease',
        opacity: 0,
        position: 'absolute',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        '&:hover': {
            opacity: 1
        }
    },
    mensagemErro: {
        margin: 8,
        textAlign: 'center',
        position: 'absolute',
        top: avatarSize + 8,
        width: '100%'
    },
    container: {
        position: 'relative',
        width: avatarSize,
        height: avatarSize,
    }
}));

const handleChange = (event, formik) => {
    const file = event.target.files[0];

    if(!file) return;

    if (file.size > 1048576) {
        formik.setFieldTouched("foto", true, false);
        formik.setFieldError("foto", "O tamanho máximo da imagem é 1MB");
        return;
    }


    const reader = new FileReader();

    reader.addEventListener("load", function () {
        formik.setFieldValue("foto", reader.result);
        //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
    }, false);
    if (file)
        reader.readAsDataURL(file)
}

const UsuarioFotoField = (props) => {
    const classes = useStyles();
    const { formik } = props;
    const avatar = formik.values.foto ? formik.values.foto : avatarPlaceholder;
    let inputRef = React.createRef();
    return (
        <React.Fragment>
            <input
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
                id="foto"
                accept="image/*"
                onChange={(event) => handleChange(event, formik)}
                disabled={formik.isSubmitting}
                onBlur={formik.handleBlur}>
            </input>
            <div className={classes.container}>
                <Avatar
                    className={classes.avatar}
                    src={avatar} />
                <Avatar
                    className={classes.alterar}
                    src={alterar}
                    onClick={() => inputRef.current.click()} />
                <FormHelperText
                    className={classes.mensagemErro}
                    error={Boolean(
                        formik.touched.foto && formik.errors.foto
                    )}>
                    {formik.errors.foto &&
                        formik.touched.foto && (
                            formik.errors.foto
                        )}
                </FormHelperText>
            </div>


            {/* <TextField
                type="file"
                className={classes.textField}
                id="foto"
                label="Foto"
                value={formik.values.foto}
                error={Boolean(
                    formik.touched.foto && formik.errors.foto
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={formik.errors.foto &&
                    formik.touched.foto && (
                        formik.errors.foto
                    )}
                FormHelperTextProps={{error: true}}
            /> */}

        </React.Fragment>
    )
}

export default UsuarioFotoField;



